import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { isMobile } from 'react-device-detect'
import { Minus, Plus } from 'react-feather'
import { BigNumber } from 'bignumber.js'

// utils
import { MyPetProps, ALLTOKENS, SWAPTOKENS, NONESWAPTOKENS } from '../../../constants/pets'
import { getPetLevel } from '../../../utils/func'
import { BIGNUMBER_FMT, CONTRACT_ADDRESSES, BNB } from '../../../constants'

// components
import Modal from '../../../components/Modal'
import { RowBetween, RowCenter, AutoRow, RowEnd } from '../../../components/Row'
import { TokenDecompose } from '../../../components/views'
import { ButtonPrimary, ButtonOutlined } from '../../../components/Button'
import EquipmentPreview from '../../../components/views/pets/EquipmentPreview'
import NumericalInput from '../../../components/NumericalInput'
import SelectToken, { TokenProps } from '../../../components/views/SelectToken'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import useRate from '../../../hooks/useRate'
import { useTokenBalance } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { useTokenContract } from '../../../hooks/useContract'
import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import { useTransactionAdder, useIsTransactionPending } from '../../../state/transactions/hooks'
import { useFeedPets } from '../../../hooks/pets'
import usePET from '../../../hooks/usePET'

const Box = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-height: 70vh;
  `}
`

const Top = styled(RowBetween)`
  background: ${({ theme }) => theme.bg8};
  box-shadow: 0px 4px 6px rgba(113, 113, 113, 0.09);
  padding: 35px 40px 35px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 12px 12px 0;
  `}
`
const EquipmentView = styled(RowCenter)`
  flex: 1;
`
const ImgWrapper = styled(RowCenter)`
  position: relative;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 10px center;
  background-size: 94px auto;
  width: 182px;
  height: 182px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-position: bottom 22px center;
    background-size: 64px auto;
    height: 150px;
  `}
`

const EquipmentDetail = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 30px;
  color: ${({ theme }) => theme.text2};
  width: 284px;
  padding: 16px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 240px;
    padding: 12px;
  `}
`
const EquipmentImg = styled.img`
  width: auto;
  height: 155px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 100px;
  `}
`
const EquipmentTitle = styled(TokenDecompose)`
  font-size: 22px;
  font-weight: 700;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text4};
    }
  }
`
const IconArrow = styled.img`
  margin: 0 5px;
`
const Update = styled(RowBetween)`
  width: auto;
`
const Bottom = styled.div`
  padding: 32px 40px 24px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 16px;
    overflow-y: auto;
  `}
`
const FoodList = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-wrap: wrap;
    width: 232px;
    margin: 0 auto;
    &>div:nth-child(n+3) {
      margin-top: 16px;
    }
  `}
`
const SelectedList = styled.div`
  position: relative;
  background: ${({theme}) => theme.bg3};
  box-shadow: 0px 2px 10px rgba(168, 121, 0, 0.08);
  border-radius: 30px;
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: 700;
  padding: 16px 40px;
  margin-top: 32px;
`
const Balance = styled.div`
  position: absolute;
  bottom: -28px;
  right: 0;
  display: flex;
`
const FoodImg = styled.img`
  margin-right: 10px;
  width: 16px;
  height: auto;
`
const TotalWrapper = styled(RowBetween)`
  border-top: 1px solid ${({ theme }) => theme.border3};
  padding-top: 10px;
`
const Total = styled(Text)`
  color: ${({ theme }) => theme.text2};
`

const ButtonGroup = styled(RowBetween)`
  margin-top: 32px;
  button:last-child {
    margin-left: 16px;
  }
`

const FoodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: 700;
  width: 94px;
`
const AmountWrapper = styled(RowCenter)`
  background: ${({ theme }) => theme.bg3};
  border: 1px solid ${({ theme }) => theme.border3};
  border-radius: 6px;
  width: 100%;
  height: 24px;
  overflow: hidden;
`
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  width: 24px;
  height: 100%;
  padding: 0;
  outline: none;

  &:hover {
    background: ${({ theme }) => theme.bg2};
  }
`
const IntInput = styled(NumericalInput)`
  border-left: 1px solid ${({ theme }) => theme.border3};
  border-right: 1px solid ${({ theme }) => theme.border3};
`
interface FruitProp {
  id: number
  name: string
  icon: string
  value: number
  amount: string
}
const MAXVAL = 100
const Fruit = ({
  fruit,
  updateAmount
}: {
  fruit: FruitProp
  updateAmount: (id: number, amount: string) => void
}) => {
  const updateValue = useCallback((d) => {
    const value = new BigNumber(d)
    const amount = value.gt(MAXVAL) ? MAXVAL.toString() : d && value.gt(0) ? value.toFixed(0, BigNumber.ROUND_FLOOR) : d
    updateAmount(fruit.id, amount)
  }, [fruit, updateAmount])

  const onMinus = useCallback(() => {
    const value = fruit.amount
    const amount = new BigNumber(value).gt(0) ? new BigNumber(value).minus(1).toString() : '0'
    updateAmount(fruit.id, amount)
  }, [fruit, updateAmount])
  const onPlus = useCallback(() => {
    const value = fruit.amount
    const amount = value ? new BigNumber(value).lt(MAXVAL) ? new BigNumber(value).plus(1).toString() : MAXVAL.toString() : '1'
    updateAmount(fruit.id, amount)
  }, [fruit, updateAmount])

  return (
    <FoodWrapper>
      <EquipmentPreview
        name={fruit.name}
        image={fruit.icon}
        imageSize={40}
      />
      <RowCenter marginTop={'8px'} marginBottom={'8px'}>
        <img src="/images/decoration/arrow-rise.svg" alt="icon"/>
        <Text marginLeft={'5px'}>{fruit.value}</Text>
      </RowCenter>
      <AmountWrapper>
        <Button onClick={onMinus}><Minus size={14} stroke={'#5B5B5B'} /></Button>
        <IntInput
          fontSize={'14px'}
          fontWeight={700}
          value={fruit.amount}
          onUserInput={updateValue}
          align="center"
          placeholder="0"
          style={{height: "22px"}}
        />
        <Button onClick={onPlus}><Plus size={14} stroke={'#5B5B5B'} /></Button>
      </AmountWrapper>
    </FoodWrapper>
  )
}

interface FeedModalProps {
  isOpen: boolean
  onDismiss: () => void
  pet?: MyPetProps,
  feedSuccess: () => void
}
const defaultFruits = [{
  id: 0,
  name: 'Apple',
  icon: '/images/pets/food/apple.svg',
  value: 100,
  amount: ''
}, {
  id: 1,
  name: 'Blueberry',
  icon: '/images/pets/food/blueberry.svg',
  value: 1000,
  amount: ''
}, {
  id: 2,
  name: 'Grape',
  icon: '/images/pets/food/grape.svg',
  value: 10000,
  amount: ''
}]
const ignore: string[] = ['usdt']
const tokenList: TokenProps[] = ALLTOKENS.filter((d) => !d.disable && !ignore.includes(d.name)).map((d, i) => {
  return {
    ...d,
    ...(i === 0 ? {defaultChecked: true} : {})
  }
})
export default function FeedModal({
  isOpen,
  onDismiss,
  pet,
  feedSuccess
}: FeedModalProps) {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [fruits, setFruits] = useState<FruitProp[]>([...defaultFruits])

  const updateAmount = useCallback((id: number, amount: string) => {
    fruits[id].amount = amount
    setFruits([...fruits])
  }, [fruits])

  const [totalIncrease, setTotalIncrease] = useState(new BigNumber(0))
  useEffect(() => {
    const total = fruits.reduce((accr, curr) => {
      const val = new BigNumber(curr.amount)
      return accr
        .plus(val.gt(0) ? val.times(curr.value) : 0)
    }, new BigNumber(0))
    setTotalIncrease(total)
  }, [fruits])

  // pet
  const [token, setToken] = useState<TokenProps>(tokenList.filter(d => d.defaultChecked)[0])
  const onSelectToken = useCallback((e) => {
    setToken(tokenList.filter(d => d.name === e.target.id)[0])
  }, [setToken])

  // Get token balance
  const balance = useTokenBalance(token.name)

  // Rate
  const [rate, setRate] = useState(new BigNumber(1))
  const tokenRate = useRate({
    baseAsset: token.name,
    quoteAsset: 'pet',
    exclude: [...SWAPTOKENS]
  })
  useEffect(() => {
    setRate(tokenRate.gt(0) ? tokenRate : new BigNumber(1))
  }, [setRate, tokenRate])

  // Allowance
  const [requestedApproval, setRequestedApproval] = useState(false)
  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[token.name][chainId] : undefined)
  const allowance = useAllowance({
    erc20,
    masterContractAddress: chainId ? CONTRACT_ADDRESSES['petNftMaster'][chainId] : undefined,
    requested: requestedApproval
  })
  const { onApprove } = useApprove(erc20, chainId ? CONTRACT_ADDRESSES['petNftMaster'][chainId] : undefined)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])
  useEffect(() => {
    setRequestedApproval(false)
  }, [token])

  // bnb/usdt/busd/fruit
  const tokenAmount = usePET({
    amount: new BigNumber(totalIncrease).times(new BigNumber(10).pow(8)).toString(),
    baseAsset: token.name,
    exclude: [...NONESWAPTOKENS],
    isExactOut: true
  })

  const [cost, setCost] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    const value = SWAPTOKENS.includes(token.name) ? tokenAmount : totalIncrease.div(rate)
    setCost(value)
  }, [totalIncrease, rate, tokenAmount, token])

  // Feed pets
  const [feedPetsHash, setFeedPetsHash] = useState('')
  const feedPetsPending = useIsTransactionPending(feedPetsHash)
  const [requestedFeedPets, setRequestedFeedPets] = useState(false)
  // reset request
  useEffect(() => {
    if (feedPetsHash && !feedPetsPending) {
      setRequestedFeedPets(false)
      setFeedPetsHash('')
      feedSuccess()
      setFruits(defaultFruits.map((d) => {
        return {
          ...d,
          amount: ''
        }
      }))
      onDismiss()
    }
  }, [feedPetsHash, feedPetsPending, onDismiss, feedSuccess])
  // Feed
  const { feedPets } = useFeedPets()
  const handleFeedPets = useCallback(async () => {
    try {
      setRequestedFeedPets(true)
      const response = await feedPets(
        token.name,
        pet ? pet.id : '0',
        cost.times(new BigNumber(10).pow(10)).integerValue(BigNumber.ROUND_CEIL).toString()
      )
      if (response) {
        addTransaction(response, {
          summary: 'Feed pets!'
        })
        setFeedPetsHash(response ? response.hash : '')
      } else {
        setRequestedFeedPets(false)
      }
    } catch (e) {
      console.log('handleBuyPets', e)
      setRequestedFeedPets(false)
    }
  }, [token, pet, cost, feedPets, addTransaction])

  const onClose = useCallback(() => {
    if (requestedApproval || requestedFeedPets) return
    setFruits(defaultFruits.map((d) => {
      return {
        ...d,
        amount: ''
      }
    }))
    onDismiss()
  }, [onDismiss, requestedApproval, requestedFeedPets])

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      maxWidth={512}
      maxHeight={100}
      borderRadius={40}
    >
      <Box>
        <Top>
        <EquipmentView>
            <ImgWrapper>
              <EquipmentImg src={pet?.petUrl} alt="pet"/>
            </ImgWrapper>
          </EquipmentView>
          <EquipmentDetail>
            <EquipmentTitle>
              <RowBetween marginTop={'10px'}>
                <Text>#{pet?.id} {pet?.name}</Text>
                <Text fontWeight={700}>Lv {pet ? getPetLevel(pet.battlePower) : '?'}</Text>
              </RowBetween>
            </EquipmentTitle>
            <TokenDecompose>
              <RowBetween marginTop={'10px'}>
                <Text>Battle power:</Text>
                <Update>
                  <Text fontWeight={700} minWidth={50} textAlign="right">{
                    pet
                      ? new BigNumber(pet.battlePower)
                        .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                  <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
                  <Text fontWeight={700} minWidth={50}>{
                    pet
                      ? totalIncrease
                        .plus(pet.battlePower)
                        .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                </Update>
              </RowBetween>
              <RowBetween marginTop={'10px'}>
                <Text>PET after abandon:</Text>
                <Update>
                  <Text fontWeight={700} minWidth={50} textAlign="right">{
                    pet
                      ? new BigNumber(pet.petToGet)
                        .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                  <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
                  <Text fontWeight={700} minWidth={50}>{
                    pet
                      ? new BigNumber(pet.petToGet)
                          .plus(
                            token.name !== 'bakery'
                              ? totalIncrease.times(0.9)
                              : 0
                          )
                          .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                </Update>
              </RowBetween>
              <RowBetween marginTop={'10px'}>
                <Text>Bake after abandon:</Text>
                <Update>
                  <Text fontWeight={700} minWidth={50} textAlign="right">{
                    pet
                      ? new BigNumber(pet.bakeToGet)
                        .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                  <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
                  <Text fontWeight={700} minWidth={50}>{
                    pet
                      ? new BigNumber(pet.bakeToGet)
                          .plus(
                            token.name === 'bakery'
                              ? totalIncrease.div(rate).times(0.9)
                              : 0
                          )
                          .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                      : 0
                  }</Text>
                </Update>
              </RowBetween>
            </TokenDecompose>
          </EquipmentDetail>
        </Top>
        <Bottom>
          <FoodList>
            {
              fruits.map((d) => {
                return <Fruit
                  key={d.id}
                  fruit={d}
                  updateAmount={updateAmount}
                />
              })
            }
          </FoodList>
          <SelectedList>
            {
              fruits.map((d) => {
                return (
                  <RowBetween key={d.id} marginBottom={'16px'}>
                    <AutoRow>
                      <FoodImg src={d.icon}alt="food"/>
                      <Text>{d.name}</Text>
                    </AutoRow>
                    <RowEnd>
                      <img src="/images/decoration/arrow-rise.svg" alt="icon"/>
                      <Text marginLeft="5px" marginRight="10px">{d.value}</Text>
                      <img src="/images/decoration/x.svg" alt="icon"/>
                      <Text marginLeft="10px">{new BigNumber(d.amount).gt(0) ? d.amount : 0}</Text>
                    </RowEnd>
                  </RowBetween>
                )
              })
            }
            <TotalWrapper>
              <RowBetween>
                <Text>Battle Power Increase:</Text>
              </RowBetween>
              <RowEnd>
                <img src="/images/decoration/arrow-rise-active.svg" alt="icon"/>
                <Total marginLeft="5px">
                  {
                    totalIncrease.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  }
                </Total>
              </RowEnd>
            </TotalWrapper>
            <RowBetween>
              <Text>Total Cost:</Text>
              <Total>{cost.toFormat(3, BigNumber.ROUND_CEIL, BIGNUMBER_FMT)} {token.symbol}</Total>
            </RowBetween>
            <Balance>
              <Text>Balance:</Text>
              <Total marginLeft={'5px'}>{balance.toFormat(3, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)} {token.symbol}</Total>
            </Balance>
          </SelectedList>
          <SelectToken
            title="Payment method"
            list={tokenList}
            onSelectToken={onSelectToken}
          />
          <ButtonGroup>
            <ButtonOutlined
              onClick={onClose}
              height={isMobile ? 40 : 48}
              padding={'0px'}
            >Cancel</ButtonOutlined>
            {
              token.name !== BNB && !allowance.toNumber() ? (
                <ButtonPrimary
                  onClick={handleApprove}
                  disabled={requestedApproval}
                  height={isMobile ? 40 : 48}
                  padding={'0px'}
                >
                  {
                    requestedApproval ? (
                      <InlinePending text="Approving" />
                    ) : 'Approve ' + token.symbol
                  }
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  onClick={handleFeedPets}
                  disabled={
                    requestedFeedPets ||
                    totalIncrease.lte(0) ||
                    balance.lt(cost) ||
                    cost.lte(0)
                  }
                  height={isMobile ? 40 : 48}
                  padding={'0px'}
                >
                  {
                    requestedFeedPets ? (
                      <InlinePending text="Pending" />
                    ) : balance.lt(cost)
                      ? 'Insufficient Balance'
                      : totalIncrease.gt(0) && cost.lte(0)
                        ? 'Unavailable'
                        : 'Confirm'
                  }
                </ButtonPrimary>
              )
            }
          </ButtonGroup>
        </Bottom>
      </Box>
    </Modal>
  )
}