import { BACKGROUND } from '../constants/Colors'
import { BigNumber } from 'bignumber.js'

// utils
import { supportPetType, PetType } from '../constants'
import { MyEggProps } from '../constants/pets'

// Randomly generate a number based on min and max
export function getRandom (min: number, max: number) {
  let r = Math.random() * (max - min)
  let re = Math.round(r + min)
  re = Math.max(Math.min(re, max), min)
  return re
}

// Ascending:asc   Descending:des
export function compare(propertyName: string, type: string = 'asc', isStr?: boolean) {
  return function(object1: any, object2: any) {
    const value1 = isStr ? object1[propertyName] : +object1[propertyName]
    const value2 = isStr ? object2[propertyName] : +object2[propertyName]
    if (value2 < value1) {
      return type === 'des' ? -1 : 1
    } else if (value2 > value1) {
      return type === 'des' ? 1 : -1
    } else {
      return 0
    }
  }
}

// Random color
export function randomColor() {
  return BACKGROUND[Math.floor(getRandom(0, BACKGROUND.length * 10000 - 1) / 10000)]
}

// get egg image url from tokenId
const featuresLen = 4
const eggTypeLimit = 3
const emotionsLimit = 5
const hatsLimit = 7
export function getEggImageUrl(egg: MyEggProps) {
  const eggId = egg.id ? Number(egg.id) : 0
  const petType = new BigNumber(supportPetType.includes(egg.petType) ? egg.petType : PetType.kitten)
    .minus(1)
    .mod(eggTypeLimit)
    .integerValue()
    .toString(2)
  const emotions = new BigNumber(eggId)
    .mod(emotionsLimit)
    .integerValue()
    .toString(2)
  const hats = new BigNumber(eggId)
    .mod(hatsLimit)
    .integerValue()
    .toString(2)
  const petTypeBinary = Array.from(Array(featuresLen - petType.length).keys()).map(() => '0').join('') + petType
  const emotionsBinary = Array.from(Array(featuresLen - emotions.length).keys()).map(() => '0').join('') + emotions
  const hatsBinary = Array.from(Array(featuresLen - hats.length).keys()).map(() => '0').join('') + hats
  const eggImageUrl = petTypeBinary + emotionsBinary + hatsBinary + '.svg'

  return eggImageUrl
}

// get pet image url
const PET_FEATURES_LEN = 4
const PET_TYPE_LIMIT = 3
const WEAPON_TYPE_LIMIT = 4
const WEAPON_LEVEL_LIMIT = 11
const HELMET_TYPE_LIMIT = 4
const HELMET_LEVEL_LIMIT = 7
interface getPetImageUrlProps {
  petType: number,
  weaponType: number | string
  weaponLevel: number | string
  helmetType: number | string
  helmetLevel: number | string
}
export function getPetImageUrl({
  petType,
  weaponType,
  weaponLevel,
  helmetType,
  helmetLevel
}: getPetImageUrlProps) {
  const petTypeStr = new BigNumber(petType)
    .minus(1)
    .mod(PET_TYPE_LIMIT)
    .integerValue()
    .toString(2)
  const weaponTypeStr = new BigNumber(weaponType)
    .mod(WEAPON_TYPE_LIMIT)
    .integerValue()
    .toString(2)
  const weaponLevelStr = new BigNumber(weaponLevel)
    .mod(WEAPON_LEVEL_LIMIT)
    .integerValue()
    .toString(2)
  const helmetTypeStr = new BigNumber(helmetType)
    .mod(HELMET_TYPE_LIMIT)
    .integerValue()
    .toString(2)
  const helmetLevelStr = new BigNumber(helmetLevel)
    .mod(HELMET_LEVEL_LIMIT)
    .integerValue()
    .toString(2)
  const petTypeBinary = Array.from(Array(PET_FEATURES_LEN - petTypeStr.length).keys()).map(() => '0').join('') + petTypeStr
  const weaponTypeBinary = Array.from(Array(PET_FEATURES_LEN - weaponTypeStr.length).keys()).map(() => '0').join('') + weaponTypeStr
  const weaponLevelBinary = Array.from(Array(PET_FEATURES_LEN - weaponLevelStr.length).keys()).map(() => '0').join('') + weaponLevelStr
  const helmetTypeBinary = Array.from(Array(PET_FEATURES_LEN - helmetTypeStr.length).keys()).map(() => '0').join('') + helmetTypeStr
  const helmetLevelBinary = Array.from(Array(PET_FEATURES_LEN - helmetLevelStr.length).keys()).map(() => '0').join('') + helmetLevelStr

  return petTypeBinary +
    weaponTypeBinary +
    weaponLevelBinary +
    helmetTypeBinary +
    helmetLevelBinary +
    '0000000000000000.svg'
}

export const getPetLevel = (battlePower: number | string) => {
  const d = new BigNumber(battlePower)
  let level = 1
  switch (true) {
    case d.lt(500):
      level = 1
      break
    case d.gte(500) && d.lt(1000):
      level = 2
      break
    case d.gte(1000) && d.lt(2000):
      level = 3
      break
    case d.gte(2000) && d.lt(5000):
      level = 4
      break
    case d.gte(5000) && d.lt(10000):
      level = 5
      break
    case d.gte(10000) && d.lt(15000):
      level = 6
      break
    case d.gte(15000) && d.lt(20000):
      level = 7
      break
    case d.gte(20000) && d.lt(30000):
      level = 8
      break
    case d.gte(30000) && d.lt(50000):
      level = 9
      break
    case d.gte(50000) && d.lt(70000):
      level = 10
      break
    case d.gte(70000) && d.lt(100000):
      level = 11
      break
    case d.gte(100000) && d.lt(150000):
      level = 12
      break
    case d.gte(150000) && d.lt(200000):
      level = 13
      break
    case d.gte(200000) && d.lt(300000):
      level = 14
      break
    case d.gte(300000) && d.lt(500000):
      level = 15
      break
    case d.gte(500000) && d.lt(700000):
      level = 16
      break
    case d.gte(700000) && d.lt(1000000):
      level = 17
      break
    case d.gte(1000000) && d.lt(1500000):
      level = 18
      break
    case d.gte(1500000) && d.lt(2000000):
      level = 19
      break
    case d.gte(2000000):
      level = 20
      break
    default:
      level = 1
      break
  }
  return level
}
