import React from 'react'
import styled from 'styled-components'

// Img
import Logo from '../../assets/svg/logo.svg'
import BattlePets from '../../assets/svg/BattlePets.svg'
import TwitterSvg from '../../assets/svg/twitter-header.svg'

// components
import Row, { RowBetween } from '../Row'
import Nav from './Nav'
import Web3Status from '../Web3Status/indexTwo'
import { ExternalLink } from '../../theme/components'

const HeaderRowBetween = styled(RowBetween)`
  background: ${({ theme }) => theme.headerBg};
  /* border-bottom: 1px solid ${({ theme }) => theme.border1}; */
  padding: 16px 48px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    padding: 16px;
  `};
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`
const HeaderElementLeft = styled(HeaderElement)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `};
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`
const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  margin-left: 8px;
`
const HeaderControls = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-self: flex-end;
  `}
`
const Twitter = styled(ExternalLink)`
  margin: 0 34px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 8px;
  color: white;

  span {
    font-weight: 800;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'Nunito', 'Inter var', sans-serif;
    color: ${({ theme }) => theme.text1};
  }
`

export default function Header () {
  return (
    <HeaderRowBetween>
      <HeaderElementLeft>
        <Title href=".">
          <img src={Logo} style={{ height: 40 }} alt="logo" />
          <TitleText>
            <img src={BattlePets} alt="title"/>
          </TitleText>
        </Title>
        <Nav />
      </HeaderElementLeft>
      <HeaderControls>
        <Twitter href={'https://twitter.com/HelloPetsLabs'}>
          <img src={TwitterSvg} alt="" />
          <span>Twitter</span>
        </Twitter>
        <Web3Status />
      </HeaderControls>
    </HeaderRowBetween>
  )
}