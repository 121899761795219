import React from 'react'
import styled from 'styled-components'

// utils
import { CaveProps } from '../../../constants/zootopia'

const CaveItem = styled.div<CaveProps>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-image: url(/images/zootopia/cave/cave${({ cave }) => cave}.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transform: translateY(5px);

  &:hover, &:focus, &:after {
    background-image: url(/images/zootopia/cave_highlight/cave${({ cave }) => cave}.png);
    top: ${({ y }) => y - 12}px;
    left: ${({ x }) => x - 12}px;
    width: ${({ width }) => width + 24}px;
    height: ${({ height }) => height + 24}px;
  }

  &:after {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    overflow: hidden;
  }
`
export default function Cave ({
  cave,
  x,
  y,
  width,
  height,
  onSelect = () => {}
}: CaveProps) {
  return (
    <CaveItem
      onClick={onSelect}
      cave={cave}
      x={x}
      y={y}
      width={width}
      height={height}
    ></CaveItem>
  )
}
