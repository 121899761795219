import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

interface progressColorProps {
  [key: string]: {
    [key: string]: string
  }
}

const progressColor: progressColorProps = {
  yellow: {
    background: '#FFF7E2',
    theme: '#EBA900',
    gradient: '#FFDF34'
  },
  green: {
    background: '#FBFFE0',
    theme: '#AFC71C',
    gradient: '#E4F429'
  },
  blue: {
    background: '#F1F5FF',
    theme: '#95A7E0',
    gradient: '#8BC0FF'
  }
}

const Wrapper = styled.div<{color: string, height?: number, marginTop?: number, marginBottom?: number}>`
  position: relative;
  background: ${({ color }) => progressColor[color].background};
  border: 1px solid ${({ color }) => progressColor[color].theme};
  border-radius: ${({ height }) => (height ? height : 20) / 2}px;
  width: 100%;
  height: ${({ height }) => height ? height : 20}px;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : 0}px;
`
const ProgressWrapper = styled.div<{height?: number}>`
  position: absolute;
  top: -1px;
  left: 0;
  border-radius: ${({ height }) => (height ? height : 20) / 2}px;
  width: 100%;
  height: ${({ height }) => height ? height : 20}px;
  overflow: hidden;
`
const Progress = styled.div<{color: string, progress: BigNumber, height?: number}>`
  background: linear-gradient(90deg, ${({ color }) => progressColor[color].theme} 60%, ${({ color }) => progressColor[color].gradient} 100%);
  width: ${({ progress }) => progress.gt(1) ? 100 : progress.times(100).toFixed(0, BigNumber.ROUND_FLOOR)}%;
  height: 100%;
  border-radius: ${({ height }) => (height ? height : 20) / 2}px;
`
const TextWrapper = styled.div<{color: string, titlePosition?: string}>`
  position: absolute;
  top: ${({ titlePosition }) => titlePosition === 'top' ? -16 : 0}px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ titlePosition, color }) => titlePosition ? progressColor[color].theme : '#ffffff'};
  width: 100%;
  height: ${({ titlePosition }) => titlePosition === 'top' ? 'auto' : '100%'};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: ${({ titlePosition }) => titlePosition === 'top' ? '0' : '0 8px 0 16px'};
  text-shadow: 0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme},
    0 0 1px ${({ titlePosition, color }) => titlePosition ? 'none' : progressColor[color].theme};
`

interface ProgressBarProps {
  color?: string
  height?: number
  progress?: BigNumber
  titlePosition?: string
  title?: string
  value?: string | number
  titleSize?: number
  marginTop?: number
  marginBottom?: number
}
export default function ProgressBar({
  color = 'yellow',
  height,
  progress = new BigNumber(0),
  titlePosition,
  title,
  value,
  titleSize,
  marginTop,
  marginBottom
}: ProgressBarProps) {
  return (
    <Wrapper
      height={height}
      color={color}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <ProgressWrapper
        height={height}
      >
        <Progress
          color={color}
          progress={progress}
          height={height}
        />
      </ProgressWrapper>
      {title && <TextWrapper color={color} titlePosition={titlePosition}>
        <Text fontSize={titleSize ? titleSize : 12}>{title}</Text>
        <Text>{value}</Text>
      </TextWrapper>}
    </Wrapper>
  )
}
