import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'

// utils
import { MyEggProps, MyPetProps } from '../../constants/pets'
import { CONTRACT_ADDRESSES, petMap } from '../../constants'
import { WEAPONNAME, HELMETNAME } from '../../constants/weapon'

// components
import { PageBody, PageTitle, PageDes } from '../../components/views'
import Hatch from './components/Hatch'
import Pet from './components/Pet'
import RemoveModal from './components/RemoveModal'
import EquipmentModal, { EquipmentProps, EquipmentType } from './components/EquipmentModal'
import FeedModal from './components/FeedModal'
import Loading from '../../components/Loader/Loading'
import NoData from '../../components/views/NoData'

// hooks
import { useActiveWeb3React } from '../../hooks'
import useInterval from '../../hooks/useInterval'
import { useAllPets } from '../../hooks/pets'
import { usePetEggNftContract, useWeaponNftContract, useHelmetNftContract } from '../../hooks/useContract'
import useAllowanceForAll from '../../hooks/useAllowanceForAll'
import useAllMyWeapons from '../../hooks/weapons/useAllMyWeapons'
import { useAllMyHelmets } from '../../hooks/helmet'

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 894px;
  margin: 48px 0 145px;
  &>div {
    margin-bottom: 45px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 410px;
    margin: 24px 0;
    &>div {
      margin-bottom: 24px;
    }
  `}
`

export default function MyPets() {
  const { account, chainId } = useActiveWeb3React()

  const [equipmentPet, setEquipmentPet] = useState<MyPetProps | undefined>()
  const [decomposePet, setDecomposePet] = useState<MyPetProps | undefined>()
  const [feedPet, setFeedPet] = useState<MyPetProps | undefined>()

  const [pets, setPets] = useState<(MyEggProps | MyPetProps)[]>([])
  const [loadingFlag, setLoadingFlag] = useState(false)
  const getAllPets = useAllPets({})
  const fetchAllPets = useCallback(async (loading?: boolean) => {
    if (loading) {
      setLoadingFlag(true)
    }
    const pets = await getAllPets()
    setPets(pets)
    setLoadingFlag(false)
  }, [getAllPets])
  useEffect(() => {
    fetchAllPets(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useInterval(fetchAllPets, account ? 10000 : null)

  // Hatch allowance
  const eggNft = usePetEggNftContract()
  const petNftMasterAddr = chainId ? CONTRACT_ADDRESSES.petNftMaster[chainId] : ''
  const allowanceHatch = useAllowanceForAll({
    erc721: eggNft,
    to: petNftMasterAddr,
    requested: true
  })

  // Weapon equipment
  const weaponNft = useWeaponNftContract()
  const petNftAddr = chainId ? CONTRACT_ADDRESSES.petNft[chainId] : ''
  const allowanceWeaponEquipped = useAllowanceForAll({
    erc721: weaponNft,
    to: petNftAddr,
    requested: true
  })

  // Helmet equipment
  const helmetNft = useHelmetNftContract()
  const allowanceHelmetEquipped = useAllowanceForAll({
    erc721: helmetNft,
    to: petNftAddr,
    requested: true
  })

  /**
   * Equipment weapon
   */
  // Fetch all my weapons
  const [equipmentList, setEquipmentList] = useState<any[]>([])
  const [weapons, setWeapons] = useState<EquipmentProps[]>([])
  const fetchAllMyWeapons = useAllMyWeapons()
  const getAllMyWeapons = useCallback(async () => {
    if (!account) return
    const list = await fetchAllMyWeapons(true)
    const weaponList: EquipmentProps[] = list.map((d) => {
      const weaponType = d.weaponType.toNumber()
      return {
        id: d.tokenId.toString(),
        type: EquipmentType.ARMS,
        name: WEAPONNAME[weaponType],
        level: d.level.toNumber(),
        stakingPower: new BigNumber(d.stakingPower.toString()),
        increaseBattlePowerPercent: new BigNumber(d.increaseBattlePowerPercent.toString()),
        petToGet: new BigNumber(d.petToGet.toString()).div(new BigNumber(10).pow(18)),
        bakeToGet: new BigNumber(d.bakeToGet.toString()).div(new BigNumber(10).pow(18)),
        imagePrefix: '/images/weapons/' + petMap.name[weaponType].toLowerCase() + '/'
      }
    })
    setWeapons(weaponList)
  }, [account, fetchAllMyWeapons])
  useEffect(() => {
    getAllMyWeapons()
  }, [getAllMyWeapons])
  // Refresh weapons
  const refreshMyWeapons = useCallback(() => {
    getAllMyWeapons()
  }, [getAllMyWeapons])

  // Fetch all my helmets
  const [helmets, setHelmets] = useState<EquipmentProps[]>([])
  const fetchAllMyHelmets = useAllMyHelmets()
  const getAllMyHelmets = useCallback(async () => {
    if (!account) return
    const list = await fetchAllMyHelmets()
    const helmetList: EquipmentProps[] = list.map((d) => {
      const helmetType = d.helmetType.toNumber()
      return {
        id: d.tokenId.toString(),
        type: EquipmentType.HAT,
        name: HELMETNAME[helmetType],
        level: d.level.toNumber(),
        stakingPower: new BigNumber(d.stakingPower.toString()),
        increaseBattlePowerPercent: new BigNumber(0),
        petToGet: new BigNumber(d.petToGet.toString()).div(new BigNumber(10).pow(18)),
        bakeToGet: new BigNumber(d.bakeToGet.toString()).div(new BigNumber(10).pow(18)),
        imagePrefix: '/images/helmet/helmet' + helmetType + '/'
      }
    })
    setHelmets(helmetList)
  }, [account, fetchAllMyHelmets])
  useEffect(() => {
    getAllMyHelmets()
  }, [getAllMyHelmets])
  // Refresh weapons
  const refreshMyHelmets = useCallback(() => {
    getAllMyHelmets()
  }, [getAllMyHelmets])

  const onSelectEquipment = useCallback((type: EquipmentType, d: MyPetProps) => {
    setEquipmentPet(d)
    switch (type) {
      case EquipmentType.HAT:
        setEquipmentList(helmets)
        break
      case EquipmentType.ARMS:
        setEquipmentList(weapons)
        break
    }
  }, [helmets, weapons])
  const onDismissEquipment = useCallback(() => {
    setEquipmentPet(undefined)
    setEquipmentList([])
  }, [])
  // Refresh allpets && weapons
  const Update = useCallback(() => {
    fetchAllPets()
    refreshMyHelmets()
    refreshMyWeapons()
  }, [fetchAllPets, refreshMyHelmets, refreshMyWeapons])

  /**
   * Feed Pet
   */
  const onSelectFeed = useCallback((d: MyPetProps) => {
    setFeedPet(d)
  }, [])

  // Decompose Pet
  const onDecompose = useCallback((d: MyPetProps) => {
    setDecomposePet(d)
  }, [])

  return (
    <>
      <Loading open={loadingFlag} />
      <RemoveModal
        isOpen={!!decomposePet}
        onDismiss={() => {
          setDecomposePet(undefined)
        }}
        pet={decomposePet}
        decomposeSuccess={Update}
      />
      <EquipmentModal
        isOpen={!!equipmentPet}
        onDismiss={onDismissEquipment}
        equipmentList={equipmentList}
        pet={equipmentPet}
        equippedSuccess={Update}
      />
      <FeedModal
        isOpen={!!feedPet}
        onDismiss={() => {
          setFeedPet(undefined)
        }}
        pet={feedPet}
        feedSuccess={Update}
      />
      <PageBody>
        <PageTitle>My Pets</PageTitle>
        <PageDes>Your Lovely Pets are Here!</PageDes>
        {
          loadingFlag
            ? null
            : !account || pets.length === 0 ? (
              <NoData
                title="You don't own any pet"
                // content="Let’s get one from Pet Shop!"
                imageUrl="/images/decoration/empty_weapons.svg"
                margin="183px 0 129px"
                // btn={{
                //   url: '/pet-shop',
                //   name: 'Go to Pet Shop'
                // }}
              />
            ) : (
              <ListWrapper>
                {
                  pets.map((d) => {
                    return d.type === 'EGG'
                      ? <Hatch
                          key={'egg-' + d.id}
                          egg={d as MyEggProps}
                          allowance={allowanceHatch}
                          updatePets={fetchAllPets}
                        />
                      : <Pet
                          key={'pet-' + d.id}
                          pet={d as MyPetProps}
                          onSelect={(type) => {
                            onSelectEquipment(type, d as MyPetProps)
                          }}
                          allowanceHelmetEquipped={allowanceHelmetEquipped}
                          allowanceWeaponEquipped={allowanceWeaponEquipped}
                          removeSuccess={Update}
                          onSelectFeed={() => {
                            onSelectFeed(d as MyPetProps)
                          }}
                          onDecompose={() => {
                            onDecompose(d as MyPetProps)
                          }}
                        />
                  })
                }
              </ListWrapper>
            )
        }
      </PageBody>
    </>
  )
}
