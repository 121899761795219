import React from 'react'

import { AutoRow } from '../Row'
import Loader from './'

interface InlinePendingProps {
  text: string
  stroke?: string
  size?: string
}
export default function InlinePending ({text, stroke, size}: InlinePendingProps) {
  return (
    <AutoRow gap="6px" justify="center">
      {text} <Loader stroke={stroke ? stroke : 'white'} size={size}/>
    </AutoRow>
  )
}
