import { useCallback } from "react"
import { getBalanceHelmetNfts } from '../../utils/lib/utils'
import { compare } from '../../utils/func'

// hooks
import { useActiveWeb3React } from '../'
import { useHelmetNftContract } from '../useContract'

export const useAllMyHelmets = () => {
  const { account } = useActiveWeb3React()
  const helmetNft = useHelmetNftContract()

  const fetchAllMyHelmets = useCallback(async () => {
    if (account && helmetNft) {
      try {
        const myHelmets = await getBalanceHelmetNfts(helmetNft, account)
        return [
          ...myHelmets
        ].sort(compare('tokenId'))
      } catch (e) {
        console.log('fetchAllMyHelmets', e)
      }
    }
    return []
  }, [account, helmetNft])

  return fetchAllMyHelmets
}
