import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

// utils
import { getWeaponInfo } from '../../utils/lib/utils'

// hooks
import { useWeaponNftContract } from '../useContract'

const useWeaponInfo = (tokenId: string) => {
  const weaponNft = useWeaponNftContract()

  const fetchWeaponInfo = useCallback(async () => {
    if (weaponNft) {
      try {
        const weaponInfo = await getWeaponInfo(weaponNft, BigNumber.from(tokenId))
        return weaponInfo
      } catch (e) {
        console.log('useWeaponInfo', e)
      } 
    }
    return null
  }, [tokenId, weaponNft])

  return fetchWeaponInfo
}

export default useWeaponInfo
