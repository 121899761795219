import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: 700;
  margin: 32px 0 16px 0;
`
const Wrapper = styled.div<{justify?: string}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify ? justify : 'space-between' };
`
const Label = styled.label<{marginRight?: number}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  cursor: pointer;
  font-weight: 700;
  margin-right: ${({ marginRight }) => marginRight ? marginRight : 0}px;
  & > i {
    display: block;
    border: 2px solid ${({ theme }) => theme.border3};
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }
  span {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    span {
      margin-left: 5px;
    }
  `}
`
const TokenIcon = styled.img`
  margin-left: 14px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ i {
    position: relative;
    border-color: ${({ theme }) => theme.border2};
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      background: ${({ theme }) => theme.border2};
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
  &:checked ~ span {
    color: ${({ theme }) => theme.text2};
  }
`
export interface TokenProps {
  symbol: string
  name: string
  icon?: string
  defaultChecked?: boolean
}
export interface SelectTokenProps {
  title?: string
  list: TokenProps[]
  onSelectToken: (name: React.FormEvent) => void
}

export default function SelectToken({title, list, onSelectToken}: SelectTokenProps) {
  return (
    <>
      {title && <Title>{title}</Title>}
      <Wrapper justify={list.length < 3 ? 'flex-start' : undefined}>
        {
          list.map((d) => (
            <Label key={d.name} htmlFor={d.name} marginRight={list.length < 3 ? 48 : 0}>
              <Radio
                type="radio"
                id={d.name}
                defaultChecked={d.defaultChecked}
                name='token'
                onChange={onSelectToken}
              />
              <i></i>
              {d.icon && <TokenIcon src={d.icon} alt="token"/>}
              <span>{d.symbol}</span>
            </Label>
          ))
        }
      </Wrapper>
    </>
  )
}
