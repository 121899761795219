import { useCallback } from 'react'
import { Contract } from '@ethersproject/contracts'

// utils
import { approve } from '../utils/lib/utils'

const useApprove = (erc20: Contract | null, masterContractAddress?: string) => {
  const handleApprove = useCallback(async () => {
    try {
      if (erc20 && masterContractAddress) {
        const tx = await approve(erc20, masterContractAddress)
        return tx
      } else {
        throw new Error('erc20 is not a Contract or masterContractAddress is not a string')
      }
    } catch (e) {
      return false
    }
  }, [erc20, masterContractAddress])

  return { onApprove: handleApprove }
}

export default useApprove
