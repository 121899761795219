import {AbstractConnector} from '@web3-react/abstract-connector'

// TODO walletconnect
import {ChainId, injected} from '../connectors'

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // }
}

export const BIGNUMBER_FMT = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3
}

export const PetType = Object.freeze({
  kitten: 1,
  puppy: 2,
  bunny: 3
})
export const supportPetType: number[] = Object.values(PetType)
export const petMap = {
  name: {
    [PetType.kitten]: 'Kitten',
    [PetType.puppy]: 'Puppy',
    [PetType.bunny]: 'Bunny'
  },
  eggIcon: {
    [PetType.kitten]: 'kitten.svg',
    [PetType.puppy]: 'puppy.svg',
    [PetType.bunny]: 'bunny.svg'
  }
}

export const BNB: string = 'wbnb'
export const CONTRACT_ADDRESSES: {[contract: string]: { [chainId: string]: string }} = {
  arenaChallenge: {
    [ChainId.BSC_TESTNET]: '0x1d48aFA099d3dB78f684Bc18BEadab2ea8e90141',
    [ChainId.BSC_MAINNET]: '0xfF67Bc2d20a2A22Bc53Ed845AAC2ba9F156D1936'
  },
  helmetNft: {
    [ChainId.BSC_TESTNET]: '0xd97B0aDaFf4C8bd19a2587ce07f8416ad0860C90',
    [ChainId.BSC_MAINNET]: '0x2f0f57BADc77701E9e6384b9335cB9AA4900b08A'
  },
  equippedHelmet: {
    [ChainId.BSC_TESTNET]: '0x88c1836DFA91581e4F0b466708f90184237264B6',
    [ChainId.BSC_MAINNET]: '0xb6113d7DE64d01315dBDD6658Cc8bA097E36cb32'
  },
  fruitToken: {
    [ChainId.BSC_TESTNET]: '0x722B775518e17Ba756Aae9C7410677255e0d0626',
    [ChainId.BSC_MAINNET]: '0x9CF518B83804d30Fb007e17757D317D9B03619a5'
  },
  siege: {
    [ChainId.BSC_TESTNET]: '0xb3af324A7C67bAF85484651E87FB8B1aAf61a846',
    [ChainId.BSC_MAINNET]: '0x2e6F76EB65286a3a317De9073efFB253671148b0'
  },
  bakerySwapRouter: {
    [ChainId.BSC_TESTNET]: '0xCDe540d7eAFE93aC5fE6233Bee57E1270D3E330F',
    [ChainId.BSC_MAINNET]: '0xCDe540d7eAFE93aC5fE6233Bee57E1270D3E330F'
  },
  petNft: {
    [ChainId.BSC_TESTNET]: '0xAa4cDa1838Ab17F6554EB2F1b3a65214083dF3f7',
    [ChainId.BSC_MAINNET]: '0x90E88d4c8E8f19af15DfEabD516d80666F06A2F5'
  },
  petNftMaster: {
    [ChainId.BSC_TESTNET]: '0x85c8c25c0E7632D2AB5491be0Fce08C15c1Cba6f',
    [ChainId.BSC_MAINNET]: '0xE1b09ea8890b9B0A9220A0734c1ae60d882e2e50'
  },
  equippedWeapon: {
    [ChainId.BSC_TESTNET]: '0xFa37A29cCef35d4fdCAA480EEDC150d96F413b1a',
    [ChainId.BSC_MAINNET]: '0x282b064FeE00BCaC48478555Ff46971f3419A4e5'
  },
  weapon: {
    [ChainId.BSC_TESTNET]: '0x84525cE72f4f10C1Daa471C17f0DE14F96cd3132',
    [ChainId.BSC_MAINNET]: '0x3664d30A612824617e3Cf6935d6c762c8B476eDA'
  },
  weaponNft: {
    [ChainId.BSC_TESTNET]: '0x2F2d38bC4C9d7b2A57432d8a7AB2093a432885Ca',
    [ChainId.BSC_MAINNET]: '0xBe7095dBBe04E8374ea5F9f5B3f30A48D57cb004'
  },
  weaponMaster: {
    [ChainId.BSC_TESTNET]: '0xF3F0FdD548D52ac5a80E81125974aD95a96F3c53',
    [ChainId.BSC_MAINNET]: '0x0c2fC172c822B923f92A17aD9EA5c15aD7332624'
  },
  weaponNftMaster: {
    [ChainId.BSC_TESTNET]: '0xCa4465C6926C6195127e1414A85cd572Ab15092E',
    [ChainId.BSC_MAINNET]: '0xaDb60Ed40314E0B8Af8f91bC1b402011AbfaCBfC'
  },
  pet: {
    [ChainId.BSC_TESTNET]: '0xe59af933b309aFF12f323111B2B1648fF45D5dc0',
    [ChainId.BSC_MAINNET]: '0x4d4e595d643dc61EA7FCbF12e4b1AAA39f9975B8'
  },
  petEggNft: {
    [ChainId.BSC_TESTNET]: '0x0B619B394cA844576E571E43b6A120882Eb8C59a',
    [ChainId.BSC_MAINNET]: '0x8A3ACae3CD954fEdE1B46a6cc79f9189a6C79c56'
  },
  petIdo: {
    [ChainId.BSC_TESTNET]: '0x973df181FC4b317bcb961540cB5F2034AaEAfC3b',
    [ChainId.BSC_MAINNET]: '0xD88ff9035a8abF2E973f9d30baFaE7eF28AAa630'
  },
  wbnb: {
    [ChainId.BSC_TESTNET]: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f',
    [ChainId.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  busd: {
    [ChainId.BSC_TESTNET]: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
    [ChainId.BSC_MAINNET]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  usdt: {
    [ChainId.BSC_TESTNET]: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    [ChainId.BSC_MAINNET]: '0x55d398326f99059ff775485246999027b3197955',
  },
  bakery: {
    [ChainId.BSC_TESTNET]: '0xe02df9e3e622debdd69fb838bb799e3f168902c5',
    [ChainId.BSC_MAINNET]: '0xe02df9e3e622debdd69fb838bb799e3f168902c5',
  }
}
