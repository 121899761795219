import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'
import { Text } from 'rebass'
import { AlertCircle } from 'react-feather'

// utils
import { MyEggProps, MAXSTAKINGPOWER } from '../../../constants/pets'
import { petMap, CONTRACT_ADDRESSES } from '../../../constants'
import { getEggImageUrl } from '../../../utils/func'

// components
import PetsCard from '../../../components/views/pets/PetsCard'
import ProgressBar from '../../../components/views/ProgressBar'
import { ButtonPrimary } from '../../../components/Button'
import { RowBetween } from '../../../components/Row'
import Equipment from '../../../components/views/pets/Equipment'
import { MouseoverTooltip } from '../../../components/Tooltip'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import { useActiveWeb3React } from '../../../hooks'
import { usePetEggNftContract } from '../../../hooks/useContract'
import useApproveForAll from '../../../hooks/useApproveForAll'
import { useHatch } from '../../../hooks/pets'
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'

interface HatchProps {
  egg: MyEggProps
  allowance: boolean
  updatePets: () => void
}

const EggBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 20px center;
  background-size: 100px auto;
  width: 100%;
  height: 276px;
`
const PetName = styled.div`
  color: ${({ theme }) =>  theme.text4};
  font-weight: 700;
`
const TokenDecompose = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text1};
    }
  }
`

const Left = ({
  egg,
  allowance,
  updatePets
}: HatchProps) => {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  // Allowance
  const eggNft = usePetEggNftContract()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const petNftMasterAddr = chainId ? CONTRACT_ADDRESSES.petNftMaster[chainId] : ''
  const { onApproveAll } = useApproveForAll(eggNft, petNftMasterAddr)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApproveAll()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApproveAll, setRequestedApproval])

  // Hatch
  const [hatchHash, setHatchHash] = useState('')
  const hatchPending = useIsTransactionPending(hatchHash)
  const [requestedHatch, setRequestedHatch] = useState(false)
  // reset request
  useEffect(() => {
    if (hatchHash && !hatchPending) {
      setRequestedHatch(false)
      setHatchHash('')
      updatePets()
    }
  }, [hatchHash, hatchPending, updatePets])
  // hatch
  const { hatch } = useHatch()
  const handleHatch = useCallback(async () => {
    try {
      setRequestedHatch(true)
      const response = await hatch(egg.id, petMap.name[egg.petType])
      if (response) {
        addTransaction(response, {
          summary: 'Hatch Pets!'
        })
        setHatchHash(response ? response.hash : '')
      } else {
        setRequestedHatch(false)
      }
    } catch (e) {
      console.log('handleHatch', e)
      setRequestedHatch(false)
    }
  }, [egg, hatch, addTransaction])

  return (
    <>
      <EggBox>
        <img src={ process.env.REACT_APP_PETS_URL + '/api/eggs/mdkeuhvm/' + getEggImageUrl(egg)} alt="pet-egg"/>
      </EggBox>
      <PetName>#{egg.id} {petMap.name[egg.petType]}</PetName>
      <ProgressBar
        color="yellow"
        title="Battle Power"
        value={egg.battlePower}
        progress={new BigNumber(egg.battlePower).div(MAXSTAKINGPOWER)}
        marginTop={20}
        marginBottom={24}
      />
      {
        !allowance ? (
          <ButtonPrimary
          onClick={handleApprove}
          disabled={requestedApproval}
            height={40}
            padding={'0px'}
          >
            {
              requestedApproval ? (
                <InlinePending text="Approving" />
              ) : 'Approve'
            }
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            onClick={handleHatch}
            disabled={requestedHatch}
            height={40}
            padding={'0px'}
          >
            {
              requestedHatch ? (
                <InlinePending text="Hatching" />
              ) : 'Hatch'
            }
          </ButtonPrimary>
        )
      }
      <TokenDecompose>
        <RowBetween>
          <Text>PET to get when abandon:</Text>
          <Text fontWeight={700}>{0}</Text>
        </RowBetween>
        <RowBetween>
          <Text>Bake to get when abandon:</Text>
          <Text fontWeight={700}>{0}</Text>
        </RowBetween>
      </TokenDecompose>
    </>
  )
}

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(243, 248, 253, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 40px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 20px;
  `}
`
const Tips = styled.div`
  margin-top: 20px;
`

const Right = () => {
  return (
    <>
      <Equipment
        name="Hat"
        defaultImg="/images/pets/equipment/hat/default.svg"
        marginBottom={16}
      ></Equipment>
      <Equipment
        name="Arms"
        defaultImg="/images/pets/equipment/arms/default.svg"
        marginBottom={16}
      ></Equipment>
      <Equipment
        name="Shield"
        defaultImg="/images/pets/equipment/shield/default.svg"
        marginBottom={16}
      ></Equipment>
      <Equipment
        name="Armour"
        defaultImg="/images/pets/equipment/armour/default.svg"
      ></Equipment>
      <Mask>
        <Tips>
          <MouseoverTooltip text={'Hatch to unlock'} width={120} placement='top'>
            <AlertCircle size={20} />
          </MouseoverTooltip>
        </Tips>
      </Mask>
    </>
  )
}

export default function Hatch({
  egg,
  allowance,
  updatePets
}: HatchProps) {
  return (
    <PetsCard
      left={
        <Left
          egg={egg}
          allowance={allowance}
          updatePets={updatePets}
        />
      }
      right={
        <Right />
      }
    ></PetsCard>
  )
}
