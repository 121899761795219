import React, { useState } from 'react'
import styled from 'styled-components'

// utils
import { BACKGROUND } from '../../constants/Colors'
import { getRandom } from '../../utils/func'
import { PetType, petMap } from '../../constants'

interface CardProps {
  width?: number
  height?: number
  borderRadius?: number
  hideBorder?: boolean
  borderWidth?: number
  borderColor?: string
  boxShadow?: string
  cardShadow?: boolean
  hoverBorder?: boolean
}
const Card = styled.div<CardProps>`
  box-sizing: border-box;
  background: ${({ theme }) => theme.bg3};
  box-shadow: ${({ boxShadow, cardShadow }) => boxShadow ? boxShadow : cardShadow ? '0px 2px 10px rgba(168, 121, 0, 0.08)' : 'none'};
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : 25}px;
  border: ${({theme, hideBorder, borderWidth, borderColor}) => hideBorder ? 'none' : `${borderWidth ? borderWidth : 1}px solid ${borderColor ? borderColor : theme.border1}`};
  width: ${({ width }) => width ? width : 174}px;
  height: ${({ height }) => height ? height + 'px' : 'auto'};
  overflow: hidden;

  &:hover {
    // border: ${({theme, hoverBorder, hideBorder}) => hoverBorder || !hideBorder ? `1px solid ${theme.border1}` : 'none'};
    transform: scale(${({ hoverBorder }) => hoverBorder ? 1.01 : 1});
  }
`
interface BaseWeaponWrapperProps {
  wrapperHeight?: number
  wrapperShadow?: boolean
}
interface WeaponWrapperProps extends BaseWeaponWrapperProps {
  background: string
}
const WeaponWrapper = styled.div<WeaponWrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) =>  background};
  box-shadow: ${({wrapperShadow}) => wrapperShadow ? '0px 4px 6px rgba(113, 113, 113, 0.09)' : 'none'};
  height: ${({wrapperHeight}) => wrapperHeight ? wrapperHeight : 134}px;
`
const WeaponLevel = styled.div`
  position: absolute;
  top: 16px;
  left: 24px;
  color: ${({ theme }) =>  theme.text2};
  font-size: 24px;
  font-weight: bold;
`
const WeaponId = styled(WeaponLevel)`
  left: initial;
  right: 12px;
  bottom: 0;
  top: initial;
  font-size: 16px;
`
const WeaponImg = styled.img<{weaponLevel: number}>`
  width: ${({ weaponLevel }) => (weaponLevel - 1) * 2 + 52}px;
  height: auto;
`
const EllipseShadow = styled.img<{weaponWidth?: number}>`
  position: absolute;
  bottom: 16px;
  left: 50%;
  width: ${({ weaponWidth }) => weaponWidth ? weaponWidth : 62}px;
  height: auto;
  transform: translateX(-50%);
`
const UnknownLevel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 48px;
  font-weight: 700;
`

interface WeaponCardProps extends CardProps, BaseWeaponWrapperProps {
  weaponType: number
  unknownLevel?: boolean
  level: number
  showLevel?: boolean
  weaponWidth?: number
  weaponId?: number | string
  showId?: boolean
  children?: React.ReactNode
}

export default function WeaponCard(
  {
    weaponType,
    unknownLevel,
    level,
    width,
    height,
    children,
    borderRadius,
    hideBorder,
    borderWidth,
    borderColor,
    hoverBorder,
    showLevel,
    boxShadow,
    cardShadow,
    weaponWidth,
    wrapperHeight,
    wrapperShadow,
    weaponId,
    showId
  }: WeaponCardProps
) {
  const [weaponWrapperBg] = useState(BACKGROUND[Math.floor(getRandom(0, BACKGROUND.length * 10000 - 1) / 10000)])
  const petName = petMap.name[weaponType] ? petMap.name[weaponType].toLowerCase() : petMap.name[PetType.kitten].toLowerCase()
  const weaponLevel = Math.floor(level) >= 1 && Math.floor(level) <= 10 ? Math.floor(level) : 1

  return (
    <Card
      width={width}
      height={height}
      borderRadius={borderRadius}
      hideBorder={hideBorder}
      borderWidth={borderWidth}
      borderColor={borderColor}
      boxShadow={boxShadow}
      cardShadow={cardShadow}
      hoverBorder={hoverBorder}
    >
      <WeaponWrapper
        background={weaponWrapperBg}
        wrapperHeight={wrapperHeight}
        wrapperShadow={wrapperShadow}
      >
        {
          showLevel && <WeaponLevel>Lv {level}</WeaponLevel>
        }
        {
          showId && weaponId && <WeaponId>#{weaponId}</WeaponId>
        }
        {
          unknownLevel ? (
            <UnknownLevel>?</UnknownLevel>
          ) : (
            <>
              <WeaponImg
                src={'/images/weapons/' + petName + '/' + (weaponLevel - 1) + '.svg'} alt="weapon"
                weaponLevel={weaponLevel}
              />
              <EllipseShadow
                src="/images/decoration/ellipse_shadow.svg"
                weaponWidth={weaponWidth}
              />
            </>
          )
        }
      </WeaponWrapper>
      {children}
    </Card>
  )
}