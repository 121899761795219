import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'

// components
import { RowCenter } from '../../../components/Row'
import { ButtonPrimary, ButtonOutlined } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

interface wrapperProps {
  equipment?: any
  marginTop?: number
  marginBottom?: number
}
const Wrapper = styled.div<wrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: inset -2px -2px 4px ${({ equipment }) => equipment ? 'rgba(164, 118, 0, 0.44)' : 'rgba(155, 155, 155, 0.44)' };
  border: 1px solid ${({ theme, equipment }) => equipment ? theme.border5 : theme.border4};
  border-radius: 20px;
  color: ${({ theme, equipment }) => equipment ? theme.text8 : theme.text1 };
  font-size: 14px;
  font-weight: 700;
  width: 82px;
  height: 82px;
  margin-top: ${({marginTop}) => marginTop ? marginTop : 0}px;
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : 0}px;
  padding-top: 2px;

  &:hover {
    .option {
      display: flex;
    }
  }
`
const Box = styled(RowCenter)<{equipment?: any}>`
  background: ${({ theme, equipment }) => equipment ? theme.bg7 : theme.border4};
  border-radius: 20px;
  width: 74px;
  height: 55px;
`
const EqImg = styled.img`
  min-width: 40px;
  max-width: 60px;
  height: auto;
`
const Option = styled.div<{requested: boolean}>`
  position: absolute;
  top: -1px;
  left: -1px;
  display: ${({ requested }) => requested ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(53, 53, 53, 0.82);
  border-radius: 20px;
  width: 82px;
  height: 82px;
`

interface EquipmentProps extends wrapperProps {
  name: string
  defaultImg: string
  showOption?: boolean
  onSelect?: () => void
  onRemove?: () => void
  allowance?: boolean
  requestedApproval?: boolean
  handleApprove?: () => void
  requestedRemove?: boolean
}

export default function Equipment({
  equipment,
  name,
  defaultImg,
  showOption,
  onSelect = () => {},
  onRemove = () => {},
  marginTop,
  marginBottom,
  allowance,
  requestedApproval = false,
  handleApprove = () => {},
  requestedRemove = false
}: EquipmentProps) {
  return (
    <Wrapper
      equipment={equipment}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Box equipment={equipment}>
        <EqImg src={defaultImg} alt="Equipment"/>
      </Box>
      <Text>{name}</Text>
      {
        showOption && <Option className='option' requested={requestedApproval || requestedRemove}>
          {
            !allowance ? (
              <ButtonPrimary
                onClick={handleApprove}
                disabled={requestedApproval}
                borderRadius={'4px'}
                width={'62px'}
                height={22}
                padding={'0px'}
              >{
                requestedApproval ? (
                  <InlinePending text="" />
                ) : 'Approve'
              }</ButtonPrimary>
            ) : (
              <>
                <ButtonPrimary
                  onClick={onSelect}
                  borderRadius={'8px'}
                  width={'62px'}
                  height={22}
                  padding={'0px'}
                >Select</ButtonPrimary>
                {
                  equipment && <ButtonOutlined
                    onClick={onRemove}
                    disabled={requestedRemove}
                    borderRadius={'8px'}
                    width={'62px'}
                    height={22}
                    marginTop={'8px'}
                    padding={'0px'}
                  >
                    {
                      requestedRemove ? (
                        <InlinePending text="" />
                      ) : 'Remove'
                    }
                  </ButtonOutlined>
                }
              </>
            )
          }
        </Option>
      }
    </Wrapper>
  )
}
