import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'

// utils
import { CaveList, BossProps, bossVsPetProps } from '../../../constants/zootopia'
import { MyPetProps } from '../../../constants/pets'

// components
import Cave from './Cave'
import CaveModal from './CaveModal'
import FightModal from './FightModal'
import Loading from '../../../components/Loader/Loading'

// hooks
import { useActiveWeb3React } from '../../../hooks'
import useInterval from '../../../hooks/useInterval'
import { useAllPets } from '../../../hooks/pets'
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'
import {
  useSiegeBattle,
  useSiegeBattleIsWin
} from '../../../hooks/zootopia'
// import useEventListener from '../../../hooks/useEventListener'

const Wrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.border2};
  border-radius: 60px;
  width: 894px;
  height: 617px;
  margin: 22px 0 52px;
  overflow: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 20px;
    width: 100%;
    height: 500px;
  `}
`
const CaveMap = styled.div`
  position: relative;
  background-image: url(/images/zootopia/map.jpg);
  background-size: 1440px 900px;
  width: 1440px;
  height: 900px;
  point-events: none;
`

export default function Map () {
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [activeBoss, setActiveBoss] = useState<BossProps | undefined>()
  const onSelectBoss = useCallback((cave?: BossProps) => {
    setActiveBoss(cave)
  }, [])

  const [battleStatus, setBattleStatus] = useState<number>(0)
  const [bossVsPet, setBossVsPet] = useState<bossVsPetProps>([])
  const selectVs = useCallback((boss?: BossProps, pet?: MyPetProps) => {
    setBossVsPet(boss && pet ? [boss, pet] : [])
  }, [setBossVsPet])

  const closeFightModal = useCallback(() => {
    selectVs()
    setBattleStatus(0)
  }, [selectVs])

  const [pets, setPets] = useState<MyPetProps[]>([])
  const [loadingFlag, setLoadingFlag] = useState(false)
  const getAllPets = useAllPets({onlyPets: true, victory: true})
  const fetchAllPets = useCallback(async (loading?: boolean) => {
    if (loading) {
      setLoadingFlag(true)
    }
    const pets = await getAllPets()
    setPets(pets as MyPetProps[])
    setLoadingFlag(false)
  }, [getAllPets])
  useEffect(() => {
    fetchAllPets(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const updatePets = useCallback(() => {
    fetchAllPets()
  }, [fetchAllPets])
  useInterval(updatePets, account ? 10000 : null)

  // Battle
  const [battleHash, setBattleHash] = useState('')
  const battlePending = useIsTransactionPending(battleHash)
  const [requestedBattle, setRequestedBattle] = useState(false)

  const [battleInfo, setsBattleInfo] = useState<{ isWin: boolean; times: BigNumber; } | undefined>()
  const { battleIsWin } = useSiegeBattleIsWin()
  const fetchBattleInfo = useCallback(async () => {
    if (bossVsPet.length === 0) return
    try {
      const latestInfo = await battleIsWin(bossVsPet[0].cave, bossVsPet[1].id)
      if (battleInfo && latestInfo && latestInfo.times.gt(battleInfo.times)) {
        setRequestedBattle(false)
        setBattleHash('')
        updatePets()
        setsBattleInfo(undefined)
        setBattleStatus(latestInfo.isWin ? 1 : 2)
      }
    } catch (e) {
      console.log('fetchBattleInfo', e)
    }
  }, [bossVsPet, battleIsWin, battleInfo, updatePets])
  useInterval(fetchBattleInfo, battleHash && !battlePending ? 1000 : null)

  // hatch
  const { battle } = useSiegeBattle()
  const handleBattle = useCallback(async () => {
    if (bossVsPet.length === 0) return
    try {
      setRequestedBattle(true)
      const battleInfo = await battleIsWin(bossVsPet[0].cave, bossVsPet[1].id)
      setsBattleInfo(battleInfo)
      const response = await battle(bossVsPet[0].cave, bossVsPet[1].id)
      if (response) {
        addTransaction(response, {
          summary: `Challenge boss${bossVsPet[0].cave}!`
        })
        setBattleHash(response ? response.hash : '')
      } else {
        setRequestedBattle(false)
        closeFightModal()
      }
    } catch (e) {
      console.log('handleBattle', e)
      setRequestedBattle(false)
      closeFightModal()
    }
  }, [bossVsPet, addTransaction, battle, closeFightModal, battleIsWin])

  const onSelectVs = useCallback((boss, pet) => {
    onSelectBoss(undefined)
    selectVs(boss, pet)
  }, [onSelectBoss, selectVs])

  useEffect(() => {
    if (bossVsPet.length > 0) {
      handleBattle()
    }
  }, [bossVsPet, handleBattle])

  const tryAgain = useCallback(() => {
    handleBattle()
    setBattleStatus(0)
  }, [handleBattle])

  // mousemove
  // const parentRef = useRef<HTMLDivElement>(null)
  // const [parentSize, setParentSize] = useState<{width: number; height: number} | null>()
  // const getParentSize = useCallback(() => {
  //   const size = parentRef.current?.getBoundingClientRect()
  //   if (size) {
  //     setParentSize({
  //       width: size.width,
  //       height: size.height
  //     })
  //   }
  // }, [parentRef])
  // useEffect(() => {
  //   getParentSize()
  // }, [getParentSize])
  // const onResize = useCallback(() => {
  //   getParentSize()
  // }, [getParentSize])
  // useEventListener('resize', onResize)

  // interface positionProps {
  //   top: number
  //   left: number
  // }
  // interface offsetProps {
  //   x: number
  //   y: number
  // }
  // const [position, setPosition] = useState<positionProps | undefined>()
  // const [offset, setOffset] = useState<offsetProps | undefined>()
  // const onMousedown = useCallback((e: MouseEvent) => {
  //   const node = parentRef.current
  //   if (!node) return
  //   setOffset({x: e.offsetX, y: e.offsetY})
  //   setPosition({top: node.scrollTop, left: node.scrollLeft})
  // }, [parentRef])
  // const onMousemove = useCallback((e: MouseEvent) => {
  //   const node = parentRef.current
  //   if (!(parentSize && position && offset && node)) return
  //   const curOffset: offsetProps = {x: e.offsetX, y: e.offsetY}
  //   const top = position.top - (curOffset.y - offset.y)
  //   const left = position.left - (curOffset.x - offset.x)
  //   node.scrollTop = Math.min(Math.max(0, top), 900 - parentSize.height)
  //   node.scrollLeft = Math.min(Math.max(0, left), 1440 - parentSize.width)
  // }, [parentRef, parentSize, position, offset])
  // const onMouseup = useCallback(() => {
  //   setOffset(undefined)
  // }, [])
  // useEventListener('mousedown', onMousedown, parentRef)
  // useEventListener('mousemove', onMousemove, parentRef)
  // useEventListener('mouseup', onMouseup, parentRef)
  // useEventListener('mouseout', onMouseup, parentRef)

  return (
    <>
      <Loading open={loadingFlag} />
      {!!activeBoss && <CaveModal
        boss={activeBoss}
        pets={pets}
        selectVs={onSelectVs}
        onDismiss={() => {
          onSelectBoss(undefined)
        }}
        updatePets={updatePets}
      />}
      {bossVsPet.length > 0 && <FightModal
        battleStatus={battleStatus}
        bossVsPet={bossVsPet}
        onDismiss={closeFightModal}
        requestedBattle={requestedBattle}
        tryAgain={tryAgain}
      />}
      <Wrapper>
        <CaveMap>
          {
            CaveList.map((d) => {
              return <Cave
                key={d.cave}
                cave={d.cave}
                x={d.x}
                y={d.y}
                width={d.width}
                height={d.height}
                onSelect={() => {
                  // if (offset) return
                  onSelectBoss(d)
                }}
              />
            })
          }
        </CaveMap>
      </Wrapper>
    </>
  )
}
