import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { isMobile } from 'react-device-detect'
import { BigNumber } from 'bignumber.js'

// utils
import { BIGNUMBER_FMT } from '../../../constants'
import { MyPetProps } from '../../../constants/pets'

// components
import Modal from '../../../components/Modal'
import { RowBetween, RowCenter } from '../../../components/Row'
import { TokenDecompose } from '../../../components/views'
import { ButtonPrimary, ButtonOutlined } from '../../../components/Button'
import EquipmentPreview from '../../../components/views/pets/EquipmentPreview'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import { useEquippedWeapon, useEquippedHelmet } from '../../../hooks/pets'
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'

const Box = styled.div`
  width: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-height: 70vh;
  `}
`
const Top = styled(RowBetween)`
  background: ${({ theme }) => theme.bg8};
  box-shadow: 0px 4px 6px rgba(113, 113, 113, 0.09);
  padding: 24px 40px 24px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 12px 12px 0;
  `}
`
const EquipmentView = styled(RowCenter)`
  flex: 1;
`
const ImgWrapper = styled(RowCenter)`
  position: relative;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 30px center;
  background-size: 94px auto;
  color: ${({ theme }) => theme.text2};
  width: 182px;
  height: 182px;
`

const EquipmentDetail = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 30px;
  color: ${({ theme }) => theme.text2};
  width: 275px;
  padding: 24px 20px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 220px;
    padding: 12px;
  `}
`
const EquipmentImg = styled.img<{level?: number}>`
  width: auto;
  height: ${({level}) => level ? (level - 1) * 5 + 90 : 140}px;
`
const EquipmentTitle = styled(TokenDecompose)`
  font-size: 22px;
  font-weight: 700;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text4};
    }
  }
`

const Bottom = styled.div`
  height: 290px;
  padding: 32px 20px 24px;
`
const EquipmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  height: 170px;
  overflow-y: auto;
  & > div {
    margin-bottom: 16px;
    margin-right: 16px;
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    justify-content: space-between;
    padding: 0;
    & > div {
      margin-right: 0px;
    }
  `}
`
const ButtonGroup = styled(RowBetween)`
  padding: 0 40px;
  margin-top: 16px;
  button:last-child {
    margin-left: 16px;
  }
`
export enum EquipmentType {
  HAT = 1,
  ARMS = 2,
  SHIELD = 3,
  ARMOUR = 4
}
export interface EquipmentProps {
  id: string
  type: EquipmentType
  name: string
  stakingPower: BigNumber
  increaseBattlePowerPercent: BigNumber
  petToGet: BigNumber
  bakeToGet: BigNumber
  imagePrefix: string
  level?: number
}

interface EquipmentModalProps {
  isOpen: boolean
  onDismiss: () => void
  equippedSuccess: () => void
  equipmentList: EquipmentProps[]
  pet?: MyPetProps
}
export default function EquipmentModal({
  isOpen,
  onDismiss,
  equippedSuccess,
  equipmentList,
  pet
}: EquipmentModalProps) {
  const addTransaction = useTransactionAdder()

  const [activeEquipment, setActiveEquipment] = useState<undefined | EquipmentProps>()
  const selectEquipment = useCallback((d: EquipmentProps) => {
    if (!activeEquipment || activeEquipment.id !== d.id) {
      setActiveEquipment(d)
    }
  }, [activeEquipment])

  // Equipped weapon
  const [equippedHash, setEquippedHash] = useState('')
  const equippedPending = useIsTransactionPending(equippedHash)
  const [requestedEquipped, setRequestedEquipped] = useState(false)
  // reset request
  useEffect(() => {
    if (equippedHash && !equippedPending) {
      setRequestedEquipped(false)
      setEquippedHash('')
      equippedSuccess()
      onDismiss()
    }
  }, [equippedHash, equippedPending, equippedSuccess, onDismiss])
  // Equipped
  const { equippedWeapon } = useEquippedWeapon()
  const { equippedHelmet } = useEquippedHelmet()
  const handleEquipped = useCallback(async () => {
    console.log('activeEquipment', activeEquipment)
    if (!pet || !activeEquipment) {
      setRequestedEquipped(false)
      return
    }
    try {
      setRequestedEquipped(true)
      let response:any
      // todo
      switch (activeEquipment.type) {
        case EquipmentType.HAT:
          response = await equippedHelmet(pet.id, activeEquipment.id)
          break
        case EquipmentType.ARMS:
          response = await equippedWeapon(pet.id, activeEquipment.id)
          break
        case EquipmentType.SHIELD:
          response = false
          break
        case EquipmentType.ARMOUR:
          response = false
          break
      }
      if (response) {
        addTransaction(response, {
          summary: `Equipped ${activeEquipment.name}`
        })
        setEquippedHash(response ? response.hash : '')
      } else {
        setRequestedEquipped(false)
      }
    } catch (e) {
      console.log('handleEquipped', e)
      setRequestedEquipped(false)
    }
  }, [pet, activeEquipment, addTransaction, equippedHelmet, equippedWeapon])

  const close = useCallback(() => {
    if (requestedEquipped) return
    setActiveEquipment(undefined)
    onDismiss()
  }, [onDismiss, requestedEquipped])

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      maxWidth={516}
      maxHeight={100}
      borderRadius={40}
    >
      <Box>
        <Top>
          <EquipmentView>
            <ImgWrapper>
              {
                activeEquipment ? (
                  <EquipmentImg
                    src={activeEquipment.imagePrefix + (activeEquipment.level ? activeEquipment.level - 1 : 0) + '.svg'} alt="weapon"
                    level={activeEquipment?.level}
                  />
                ) : <Text fontSize={52} fontWeight={700}>?</Text>
              }
            </ImgWrapper>
          </EquipmentView>
          <EquipmentDetail>
            <EquipmentTitle>
              <RowBetween marginTop={'10px'}>
                {
                  activeEquipment ? (
                    <>
                      <Text>{ activeEquipment.name }</Text>
                      {activeEquipment.level !== undefined && <Text fontWeight={700}>Lv {activeEquipment.level}</Text>}
                    </>
                  ) : (
                    <Text>?</Text>
                  )
                }
              </RowBetween>
            </EquipmentTitle>
            <TokenDecompose>
              <RowBetween marginTop={'10px'}>
                <Text>Staking Power:</Text>
                <Text fontWeight={700}>{activeEquipment ? activeEquipment.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              </RowBetween>
              <RowBetween marginTop={'10px'}>
                <Text>PET to get after abandon:</Text>
                <Text fontWeight={700}>{activeEquipment ? activeEquipment.petToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              </RowBetween>
              <RowBetween marginTop={'10px'}>
                <Text>Bake to get when abandon:</Text>
                <Text fontWeight={700}>{activeEquipment ? activeEquipment.bakeToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              </RowBetween>
              <RowBetween marginTop={'10px'}>
                <Text>Battle power increase:</Text>
                <Text fontWeight={700}>{activeEquipment ? activeEquipment.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              </RowBetween>
            </TokenDecompose>
          </EquipmentDetail>
        </Top>
        <Bottom>
          <EquipmentList>
            {
              equipmentList.map((d:EquipmentProps) => {
                return (
                  <EquipmentPreview
                    active={activeEquipment && activeEquipment.id === d.id}
                    selectEquipment={() => {
                      selectEquipment(d)
                    }}
                    key={d.id}
                    showLevel={true}
                    level={d.level}
                    name={d.name}
                    image={ d.imagePrefix + (d.level ? d.level - 1 : 0) + '.svg' }
                  />
                )
              })
            }
          </EquipmentList>
          <ButtonGroup>
            <ButtonOutlined
              onClick={close}
              height={isMobile ? 40 : 48}
              padding={'0px'}
            >Cancel</ButtonOutlined>
            <ButtonPrimary
              onClick={handleEquipped}
              disabled={!activeEquipment || requestedEquipped}
              height={isMobile ? 40 : 48}
              padding={'0px'}
            >
              {
                requestedEquipped ? (
                  <InlinePending text="Pending" />
                ) : 'Confirm'
              }
            </ButtonPrimary>
          </ButtonGroup>
        </Bottom>
      </Box>
    </Modal>
  )
}
