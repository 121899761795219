import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

// utils
import { randomColor } from '../../../utils/func'
import { BossProps, MIN_STAMINA } from '../../../constants/zootopia'
import { MyPetProps } from '../../../constants/pets'

// components
import { RowCenter, RowEnd, RowBetween } from '../../../components/Row'
import { ButtonPrimary } from '../../../components/Button'
import ProgressBar from '../../../components/views/ProgressBar'
import InlinePending from '../../../components/Loader/InlinePending'

const Wrapper = styled.div`
  width: 100%;
  heigth: 100%;
`
const Top = styled.div`
  display: flex;
  background: #FBFFEF;
  height: 160px;
  padding: 24px;
`
const Bottom = styled.div`
  height: 330px;
  padding: 24px 0;
`
const BossBox = styled(RowCenter)`
  flex: 1;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 0px center;
  margin-right: 24px;
`
const BossImg = styled.img`
  width: auto;
  height: 108px
`
const BossDetail = styled.div`
  width: 192px;
`
const BossName = styled(Text)`
  color: ${({ theme }) => theme.text2};
  font-weight: 700;
`
const PetsDetail = styled(RowBetween)`
  padding: 0 36px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 12px;
  `}
`
const DetailLeft = styled(RowCenter)<{background: string}>`
  background-color: ${({ background }) =>  background};
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 13px center;
  background-size: 60px;
  border-radius: 20px;
  width: 134px;
  height: 130px;
`
const PetImg = styled.img`
  width: auto;
  height: 110px;
`
const UnknownPet = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 48px;
  font-weight: 700;
`
const DetailRight = styled.div`
  width: 192px;
`
const PetName = styled(Text)`
  color: ${({ theme }) => theme.text2};
  font-weight: 700;
`
const PetList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 36px;
  height: 78px;
  overflow-y: auto;
  margin-top: 20px;
  & > div {
    margin-bottom: 10px;
    margin-right: 28.5px;
    &:nth-child(5n) {
      margin-right: 0px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 320px;
    padding: 0;
    margin: 20px auto 0;
    & > div {
      margin-right: 20px;
    }
  `}
`
const PetWrapper = styled(RowCenter)<{background: string, active: boolean}>`
  background: ${({ background }) =>  background};
  border: ${({ active, theme }) => active ? `2px solid ${theme.border2}` : 'none'};
  border-radius: 10px;
  box-shadow: ${({ active, theme }) => active ? `0px 0px 8px #EBA900` : '0px 0px 8px rgba(113,113,113,0.2)'};
  cursor: pointer;
  width: 48px;
  height: 48px;
`
const MiniPetImg = styled.img`
  width: auto;
  height: 32px;
`

interface PetProps {
  pet: MyPetProps
  active: boolean
  onSelect: () => void
}
function Pet ({
  pet,
  active,
  onSelect
}: PetProps) {
  const [petBg] = useState(randomColor())

  return (
    <PetWrapper background={petBg} active={active} onClick={onSelect}>
      <MiniPetImg src={pet.petUrl} alt="pet"/>
    </PetWrapper>
  )
}

interface BossCompProps {
  boss?: BossProps
  pets: MyPetProps[]
  allowance: boolean
  requestedApproval: boolean
  handleApprove: () => void
  toggleView: () => void
  selectVs: (boss: BossProps, pet: MyPetProps) => void
}
export default function Boss ({
  boss,
  pets,
  allowance,
  requestedApproval,
  handleApprove,
  toggleView,
  selectVs
}: BossCompProps) {
  const [petBg] = useState(randomColor())

  const [activePet, setActivePet] = useState<MyPetProps | undefined>()
  const onSelectPet = useCallback((pet: MyPetProps) => {
    if (activePet && activePet.id === pet.id) return
    setActivePet(pet)
  }, [activePet])

  return (
    <Wrapper>
      <Top>
        <BossBox>
          <BossImg src={`/images/zootopia/boss/${boss?.url}`} alt="boss"/>
        </BossBox>
        <BossDetail>
          <RowEnd>
            <ButtonPrimary
              onClick={toggleView}
              width={'88px'}
              height={32}
              padding="0px"
              borderRadius={'8px'}
            >Stake</ButtonPrimary>
          </RowEnd>
          <BossName marginTop={'7px'} marginBottom={'16px'}>{boss?.boss}</BossName>
          <ProgressBar
            color="yellow"
            title="Battle Power"
            value={boss?.battlePower}
            progress={boss ? new BigNumber(Math.pow(boss.battlePower, 1 / 3)).div(100) : new BigNumber(0)}
            marginBottom={10}
          />
        </BossDetail>
      </Top>
      <Bottom>
        <PetsDetail>
          <DetailLeft background={petBg}>
            {
              activePet
                ? <PetImg src={activePet.petUrl} alt="pet"/>
                : <UnknownPet>?</UnknownPet>
            }
          </DetailLeft>
          <DetailRight>
            <PetName marginBottom={'16px'}>{activePet ? `#${activePet.id} ${activePet.name}` : '?'}</PetName>
            <ProgressBar
              color="yellow"
              title="Battle Power"
              value={activePet ? activePet.battlePower : 0}
              progress={activePet ? new BigNumber(Math.pow(activePet.battlePower, 1 / 3)).div(100) : new BigNumber(0)}
              marginBottom={6}
            />
            <ProgressBar
              color="green"
              title="Experience"
              value={activePet ? `${activePet.experience}/100` : '0/100'}
              marginBottom={6}
              progress={activePet ? new BigNumber(activePet.experience).div(100) : new BigNumber(0)}
            />
            <ProgressBar
              color="blue"
              title="Stamina"
              value={activePet ? `${activePet.stamina}/100` : '0/100'}
              progress={activePet ? new BigNumber(activePet.stamina).div(100) : new BigNumber(0)}
            />
          </DetailRight>
        </PetsDetail>
        <PetList>
          {
            pets.filter((d) => !d.wins.includes(boss ? boss.cave : -1)).map((d) => {
              return (
                <Pet
                  key={d.id}
                  pet={d}
                  active={!!activePet && activePet.id === d.id}
                  onSelect={() => {
                    onSelectPet(d)
                  }}
                />
              )
            })
          }
        </PetList>
        <RowCenter>
          {
            !allowance ? (
              <ButtonPrimary
                onClick={handleApprove}
                disabled={
                  !activePet ||
                  requestedApproval
                }
                width={'192px'}
                height={32}
                padding="0px"
                borderRadius={'8px'}
                marginTop={'20px'}
              >
                {
                  requestedApproval ? (
                    <InlinePending text="Approving" />
                  ) : 'Approve'
                }
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                onClick={() => {
                  boss && activePet && selectVs(boss, activePet)
                }}
                disabled={
                  !activePet ||
                  new BigNumber(activePet.stamina).lt(MIN_STAMINA)
                }
                width={'192px'}
                height={32}
                padding="0px"
                borderRadius={'8px'}
                marginTop={'20px'}
              >{activePet && new BigNumber(activePet.stamina).lt(MIN_STAMINA) ? 'Insufficient Stamina' : 'Fight'}</ButtonPrimary>
            )
          }
        </RowCenter>
      </Bottom>
    </Wrapper>
  )
}
