import React, { useState } from 'react'
import styled from 'styled-components'

// utils
import { randomColor } from '../../../utils/func'

const Card = styled.div<{background: string}>`
  display: flex;
  background: ${({ background }) => background};
  border-radius: 40px;
  box-shadow: 0px 1.88046px 15.0437px rgba(135, 119, 79, 0.16);
  width: 410px;
  height: 500px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 20px;
  `}
`
const CardLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 24px;
`
const CardRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1.88046px 15.0437px rgba(135, 119, 79, 0.16);
  border-radius: 40px;
  width: 124px;
  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 20px;
    width: 100px;
  `}
`

interface PetsCardProps {
  left?: React.ReactNode
  right?: React.ReactNode
}
export default function PetsCard({left, right}: PetsCardProps) {
  const [cardBg] = useState(randomColor())
  return (
    <Card
      background={cardBg}
    >
      <CardLeft>{ left }</CardLeft>
      <CardRight>{ right }</CardRight>
    </Card>
  )
}
