import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

// utils
import { MyPetProps } from '../../../constants/pets'
import { BIGNUMBER_FMT } from '../../../constants'

// components
import Modal from '../../../components/Modal'
import { PetName, TokenDecompose } from '../../../components/views'
import { RowBetween } from '../../../components/Row'
import { ButtonPrimary } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'
import useDecomposeNFT from '../../../hooks/useDecomposeNFT'
import { usePetNftContract } from '../../../hooks/useContract'

const Box = styled.div`
  width: 100%;
  height: 100%;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.bg8};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 0 24px 18px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  `}
`
const ImgWrapper = styled.div`
  position: relative;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 0px center;
  background-size: 150px auto;
  width: 100%;
  height: 115px;
`
const Img = styled.img`
  position: absolute;
  left: 50%;
  bottom: 6px;
  width: auto;
  height: 200px;
  transform: translateX(-50%);
`
const Bottom = styled.div`
  color: ${({ theme }) => theme.text4};
  font-weight: 700;
  text-align: center;
  padding: 20px 24px 24px;
`

interface RemoveModalProps {
  isOpen: boolean
  onDismiss: () => void
  pet?: MyPetProps
  decomposeSuccess: () => void
}

export default function RemoveModal({
  isOpen,
  onDismiss,
  pet,
  decomposeSuccess
}: RemoveModalProps) {
  const addTransaction = useTransactionAdder()

  // Hatch
  const [decomposeHash, setDecomposeHash] = useState('')
  const decomposePending = useIsTransactionPending(decomposeHash)
  const [requestedDecompose, setRequestedDecompose] = useState(false)
  // reset request
  useEffect(() => {
    if (decomposeHash && !decomposePending) {
      setRequestedDecompose(false)
      setDecomposeHash('')
      decomposeSuccess()
      onDismiss()
    }
  }, [decomposeHash, decomposePending, decomposeSuccess, onDismiss])
  // hatch
  const petNft = usePetNftContract()
  const { onDecomposeNFT } = useDecomposeNFT(petNft, pet?.id)
  const handleDecompose = useCallback(async () => {
    try {
      setRequestedDecompose(true)
      const response = await onDecomposeNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Decompose Pets!'
        })
        setDecomposeHash(response ? response.hash : '')
      } else {
        setRequestedDecompose(false)
      }
    } catch (e) {
      console.log('handleDecompose', e)
      setRequestedDecompose(false)
    }
  }, [onDecomposeNFT, addTransaction])

  const onClose = useCallback(() => {
    if (requestedDecompose) return
    onDismiss()
  }, [onDismiss, requestedDecompose])

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      maxWidth={290}
      maxHeight={70}
      borderRadius={40}
      overflow="initial"
    >
      <Box>
        <Top>
          <ImgWrapper>
            <Img src={pet?.petUrl} alt="pet"/>
          </ImgWrapper>
          <PetName marginTop={8}>#{pet?.id} {pet?.name}</PetName>
          <TokenDecompose marginTop={4}>
            <RowBetween marginTop={'6px'}>
              <Text>Battle power:</Text>
              <Text fontWeight={700}>{
                pet
                  ? new BigNumber(pet.battlePower)
                    .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  : 0
              }</Text>
            </RowBetween>
            <RowBetween marginTop={'6px'}>
              <Text>PET to get after abandon:</Text>
              <Text fontWeight={700}>{
                pet
                  ? new BigNumber(pet.petToGet)
                    .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  : 0
              }</Text>
            </RowBetween>
            <RowBetween marginTop={'6px'}>
              <Text>Bake to get after abandon:</Text>
              <Text fontWeight={700}>{
                pet
                  ? new BigNumber(pet.bakeToGet)
                    .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  : 0
              }</Text>
            </RowBetween>
          </TokenDecompose>
        </Top>
        <Bottom>
          <Text padding={'0px 10px'}>Are you sure to abandon this pet?</Text>
          <ButtonPrimary
            disabled={requestedDecompose}
            onClick={handleDecompose}
            height={40}
            marginTop={27}
            padding={'0px'}
          >
            {
              requestedDecompose ? <InlinePending text="Pending" /> : 'Confirm'
            }
          </ButtonPrimary>
        </Bottom>
      </Box>
    </Modal>
  )
}
