import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from 'bignumber.js'

import { CONTRACT_ADDRESSES, BNB } from '../../constants'

// hooks
import { useTokenContract } from '../../hooks/useContract'
import { useActiveWeb3React } from '../../hooks'

export function useTokenBalance(tokenName: string) {
  const { account, chainId, library } = useActiveWeb3React()
  const [balance, setBalance] = useState(new BigNumber(-1))
  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[tokenName][chainId] : undefined)
  const getBalance = useCallback(async () => {
    if (account && erc20) {
      const balance = tokenName === BNB
        ? await library?.getBalance(account)
        : await erc20.balanceOf(account)
      setBalance(new BigNumber(balance.toString()).div(new BigNumber(10).pow(18)))
    }
  }, [account, erc20, tokenName, library])

  useEffect(() => {
    if (account && erc20) {
      getBalance()
    }
    let refreshInterval = setInterval(getBalance, 10000)
    return () => clearInterval(refreshInterval)
  }, [account, erc20, getBalance])

  return balance
}
