import dynamic from 'next/dynamic'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { darken } from 'polished'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled, { css } from 'styled-components'
// import { isMobile } from 'react-device-detect'
import { injected, walletconnect } from '../../connectors'
// import { NetworkContextName } from '@/constants'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import Base from '../Button'
import Loader from '../Loader'
import { RowBetween } from '..//Row'
import WalletModal from '../WalletModal/indexTwo'

const Identicon = dynamic(() => import('../Identicon'), {
  ssr: false,
})

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(Base)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0 25px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 500;
  height: 52px;
  user-select: none;
  :focus {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 28px;
    padding: 0 6px;
  `};
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnectPhone = styled.img`
  width: 32px;
  height: 32px;
  display: none;
  /* margin-left: 16px; */
  ${({ theme }) => theme.mediaWidth.upToSmall`
      display: block;
  `}
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background: #FFFDF9;
  border-radius: 10px !important;
  border: none;
  border: 1px solid #EBA900!important;
  color: ${({ theme }) => theme.text2}!important;
  font-weight: 700;
  padding: 0 5px;
  width: 135px;
  height: 40px;
  display: block;
  text-align: center;
  :hover,
  :focus {
    /* color: ${({ theme }) => theme.primaryText1}; */
  }

  ${({ faded }) =>
    faded &&
    css`
      border: 1px solid ${({ theme }) => theme.border7};
      color: ${({ theme }) => theme.black};

      :hover,
      :focus {
        /* color: ${({ theme }) => darken(0.05, theme.primaryText1)}; */
      }
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
      display: none !important;
  `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background: #FFFDF9;
  color: ${({ theme }) => theme.text2};
  font-weight: 500;
  width: 170px;
  height: 40px;
  border-radius: 10px !important;
  border: 1px solid #EBA900!important;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 107px;
    border-radius: 8px !important;
  `}
`

const Text = styled.p<{ width?: string }>`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  width: fit-content;
  font-weight: 700;
  width: ${({ width }) => (width ? width : 'auto')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={'/images/walletConnectIcon.svg'} alt={''} />
      </IconWrapper>
    )
  }
  return null
}

function Web3StatusInner() {
  const { account, connector, error } = useWeb3React()
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="#ffffff" />
          </RowBetween>
        ) : (
          <>
            <Text>{shortenAddress(account)}</Text>
          </>
        )}
        {null && !hasPendingTransactions && connector && <StatusIcon connector={connector} />}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text width={'100%'}>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <>
        <Web3StatusConnectPhone src={'/images/notloggedin.png'} onClick={toggleWalletModal}></Web3StatusConnectPhone>
        <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
          {/* <IconPlus /> */}
          <Text>{'Connect Wallet'}</Text>
        </Web3StatusConnect>
      </>
    )
  }
}

export default function Web3Status() {
  // const { active } = useWeb3React()
  // const contextNetwork = useWeb3React(NetworkContextName)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  // if (!contextNetwork.active && !active) {
  //   return null
  // }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
