import {useCallback} from 'react'
import {BigNumber} from '@ethersproject/bignumber'
import {Contract} from '@ethersproject/contracts'

import {decomposition} from '../utils/lib/utils'

const useDecomposeNFT = (nftContract: Contract | null, tokenId?: string) => {
  const handleDecomposeNFT = useCallback(async () => {
    try {
      if (nftContract && tokenId) {
        return await decomposition(
          nftContract,
          BigNumber.from(tokenId)
        )
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  },
  [nftContract, tokenId])

  return { onDecomposeNFT: handleDecomposeNFT }
}

export default useDecomposeNFT
