import { useCallback, useEffect, useState } from "react"
import BigNumber from "bignumber.js"

// utils
import { getPrice } from '../utils/lib/utils'
import { CONTRACT_ADDRESSES } from '../constants'

// hooks
import { usePetIdoContract } from './useContract'
import { useActiveWeb3React } from './'
import { useBlockNumber } from '../state/application/hooks'

export default function useRate({
  baseAsset,
  quoteAsset = 'busd',
  exclude = []
} : {
  baseAsset: string,
  quoteAsset?: string,
  exclude?: string[]
}): BigNumber {
  const [rate, setRate] = useState(new BigNumber(0))
  const petIdoContract = usePetIdoContract()
  const { chainId } = useActiveWeb3React()
  const latestBlockNumber = useBlockNumber()

  const fetchRate = useCallback(async () => {
    try {
      if (chainId && petIdoContract) {
        const price = (baseAsset === quoteAsset || exclude.includes(baseAsset))
          ? new BigNumber(1).times(new BigNumber(10).pow(8))
          : await getPrice(petIdoContract, CONTRACT_ADDRESSES[baseAsset][chainId], CONTRACT_ADDRESSES[quoteAsset][chainId])
        setRate(new BigNumber(price.toNumber()).div(new BigNumber(10).pow(8)))
        return
      }
    } catch (e) {
      console.log('---e--->', e)
    }
    setRate(new BigNumber(0))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAsset, quoteAsset, setRate, chainId, petIdoContract])

  useEffect(() => {
    fetchRate()
  }, [latestBlockNumber, baseAsset, quoteAsset, fetchRate])

  return rate
}