import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

// utils
import { randomColor } from '../../../utils/func'
import { BossProps } from '../../../constants/zootopia'
import { MyPetProps } from '../../../constants/pets'

// components
import { RowCenter, RowBetween } from '../../../components/Row'
import { ButtonPrimary } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'
import {
  useCaveStakingPets,
  useSiegeStake,
  useSiegeUnStake,
  useSiegeHarvest,
  useSiegeUnstakeAll,
  useSiegePendingFruit
} from '../../../hooks/zootopia'
import useInterval from '../../../hooks/useInterval'

const Wrapper = styled.div`
  width: 100%;
  heigth: 100%;
`
const Top = styled(RowBetween)`
  position: relative;
  background: #FBFFEF;
  height: 160px;
`
const Back = styled(RowCenter)`
  position: absolute;
  top: 12px;
  left: 16px;
  cursor: pointer;
  width: 24px;
  height:  24px;
`
const StakeWrapper = styled(RowBetween)`
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.text2};
`
const DividingLine = styled.img`
  width: auto;
  height: 85px;
`
const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 12px 16px;
  height: 330px;
  overflow-y: auto;
  & > div {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  `}
`

const PetCard = styled(RowCenter)<{backgroundColor: string}>`
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 1.88046px 15.0437px rgba(135, 119, 79, 0.16);
  width: 196px;
  height: 234px;
  padding: 0px 8px 14px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 220px;
    padding: 0 16px 24px;
  `}
`
const PetBox = styled(RowCenter)`
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 10px center;
  background-size: 67px auto;
  width: 100%;
  height: 107px;
`
const PetImg = styled.img`
  width: auto;
  height: 87px;
`
const PetName = styled.div`
  color: ${({ theme }) =>  theme.text4};
  font-weight: 700
`
const TokenDecompose = styled.div`
  width: 100%;
  font-size: 12px;
  margin: 8px 14px;
  & > div {
    position: relative;
    div:first-child {
      color: ${({ theme }) =>  theme.text1};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 16px 0;
  `}
`
const Value = styled(Text)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) =>  theme.text4};
  font-weight: 700
`

interface PetProps {
  boss?: BossProps
  pet: MyPetProps
  updatePets: () => void
}
function Pet ({
  boss,
  pet,
  updatePets
}: PetProps) {
  const [petBg] = useState(randomColor())
  const addTransaction = useTransactionAdder()

  // Stake pet on cave
  const [siegeStakeHash, setSiegeStakeHash] = useState('')
  const siegeStakePending = useIsTransactionPending(siegeStakeHash)
  const [requestedSiegeStake, setRequestedSiegeStake] = useState(false)
  // reset request
  useEffect(() => {
    if (siegeStakeHash && !siegeStakePending) {
      setRequestedSiegeStake(false)
      setSiegeStakeHash('')
      updatePets()
    }
  }, [siegeStakeHash, siegeStakePending, updatePets])
  // Stake
  const { onSiegeStake } = useSiegeStake()
  const handleSiegeStake = useCallback(async (caveId, tokenId: string | number) => {
    try {
      setRequestedSiegeStake(true)
      const response = await onSiegeStake(caveId, tokenId)
      if (response) {
        addTransaction(response, {
          summary: 'Stake pet!'
        })
        setSiegeStakeHash(response ? response.hash : '')
      } else {
        setRequestedSiegeStake(false)
      }
    } catch (e) {
      console.log('handleSiegeStake', e)
      setRequestedSiegeStake(false)
    }
  }, [onSiegeStake, addTransaction])

  // Unstake pet on cave
  const [siegeUnStakeHash, setSiegeUnStakeHash] = useState('')
  const siegeUnStakePending = useIsTransactionPending(siegeUnStakeHash)
  const [requestedSiegeUnStake, setRequestedSiegeUnStake] = useState(false)
  // reset request
  useEffect(() => {
    if (siegeUnStakeHash && !siegeUnStakePending) {
      setRequestedSiegeUnStake(false)
      setSiegeUnStakeHash('')
      updatePets()
    }
  }, [siegeUnStakeHash, siegeUnStakePending, updatePets])
  // Unstake
  const { onSiegeUnStake } = useSiegeUnStake()
  const handleSiegeUnStake = useCallback(async (caveId, tokenId: string | number) => {
    try {
      setRequestedSiegeUnStake(true)
      const response = await onSiegeUnStake(caveId, tokenId)
      if (response) {
        addTransaction(response, {
          summary: 'Unstake pet!'
        })
        setSiegeUnStakeHash(response ? response.hash : '')
      } else {
        setRequestedSiegeUnStake(false)
      }
    } catch (e) {
      console.log('handleSiegeUnStake', e)
      setRequestedSiegeUnStake(false)
    }
  }, [onSiegeUnStake, addTransaction])

  return (
    <PetCard backgroundColor={petBg}>
      <PetBox>
        <PetImg src={pet.petUrl} alt="pet"/>
      </PetBox>
      <PetName>#{pet.id} {pet.name}</PetName>
      <TokenDecompose>
        <RowBetween>
          <Text>Staking Power:</Text>
          <Value>{
            new BigNumber(pet.battlePower)
              .toFixed(0, BigNumber.ROUND_FLOOR)
          }</Value>
        </RowBetween>
        <RowBetween>
          <Text>PET to get after abandon:</Text>
          <Value>{new BigNumber(pet.petToGet).toFixed(0, BigNumber.ROUND_FLOOR)}</Value>
        </RowBetween>
        <RowBetween>
          <Text>Bake to get when abandon:</Text>
          <Value>{new BigNumber(pet.bakeToGet).toFixed(0, BigNumber.ROUND_FLOOR)}</Value>
        </RowBetween>
      </TokenDecompose>
      {
        pet.staking ? (
          <ButtonPrimary
            onClick={() => {
              boss && handleSiegeUnStake(boss.cave, pet.id)
            }}
            disabled={requestedSiegeUnStake}
            height={24}
            padding={'0px'}
            borderRadius={'8px'}
            fontSize={12}
          >
            {requestedSiegeUnStake ? (
              <InlinePending text="Unstaking" />
            ) : (
              'Unstake'
            )}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            disabled={requestedSiegeStake}
            onClick={() => {
              boss && handleSiegeStake(boss.cave, pet.id)
            }}
            height={24}
            padding={'0px'}
            borderRadius={'8px'}
            fontSize={12}
          >
            {requestedSiegeStake ? (
              <InlinePending text="Staking" />
            ) : (
              'Stake'
            )}
          </ButtonPrimary>
        )
      }
    </PetCard>
  )
}

interface StakeProps {
  boss?: BossProps
  pets: MyPetProps[]
  toggleView: () => void
  updatePets: () =>  void
}
export default function Stake ({
  boss,
  pets,
  toggleView,
  updatePets
}: StakeProps) {
  const addTransaction = useTransactionAdder()

  const [caveInfo, setCaveInfo] = useState<{
      amount: BigNumber
      rewardDebt: BigNumber
      stakingPetNFTs: MyPetProps[]
  } | null>(null)
  const fetchCaveStakingPets = useCaveStakingPets(boss ? boss.cave : '')
  const handleCaveStakingPets = useCallback(async () => {
    const stakingPets = await fetchCaveStakingPets()
    setCaveInfo(stakingPets)
  }, [fetchCaveStakingPets])
  useInterval(handleCaveStakingPets, 10000)

  const [fruit, setFruit] = useState<BigNumber>(new BigNumber(0))
  const fetchPendingFruit = useSiegePendingFruit(boss?.cave)
  const handlePendingFruit = useCallback(async () => {
    const fruit = await fetchPendingFruit()
    setFruit(fruit)
  }, [fetchPendingFruit])
  useInterval(handlePendingFruit, 10000)

  const onUpdatePets = useCallback(() => {
    updatePets()
    handleCaveStakingPets()
    handlePendingFruit()
  }, [updatePets, handleCaveStakingPets, handlePendingFruit])

  // Harvest Fruit
  const [siegeHarvestHash, setSiegeHarvestHash] = useState('')
  const siegeHarvestPending = useIsTransactionPending(siegeHarvestHash)
  const [requestedSiegeHarvest, setRequestedSiegeHarvest] = useState(false)
  // reset request
  useEffect(() => {
    if (siegeHarvestHash && !siegeHarvestPending) {
      setRequestedSiegeHarvest(false)
      setSiegeHarvestHash('')
      onUpdatePets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siegeHarvestHash, siegeHarvestPending])
  // harvest
  const { onSiegeHarvest } = useSiegeHarvest()
  const handleSiegeHarvest = useCallback(async (caveId: string | number) => {
    try {
      setRequestedSiegeHarvest(true)
      const response = await onSiegeHarvest(caveId)
      if (response) {
        addTransaction(response, {
          summary: 'Harvest FRUIT!'
        })
        setSiegeHarvestHash(response ? response.hash : '')
      } else {
        setRequestedSiegeHarvest(false)
      }
    } catch (e) {
      console.log('handleSiegeHarvest', e)
      setRequestedSiegeHarvest(false)
    }
  }, [onSiegeHarvest, addTransaction])

  // Unstake All
  const [siegeUnstakeAllHash, setSiegeUnstakeAllHash] = useState('')
  const siegeUnstakeAllPending = useIsTransactionPending(siegeUnstakeAllHash)
  const [requestedSiegeUnstakeAll, setRequestedSiegeUnstakeAll] = useState(false)
  // reset request
  useEffect(() => {
    if (siegeUnstakeAllHash && !siegeUnstakeAllPending) {
      setRequestedSiegeUnstakeAll(false)
      setSiegeUnstakeAllHash('')
      onUpdatePets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siegeUnstakeAllHash, siegeUnstakeAllPending])
  // Unstake All
  const { onSiegeUnstakeAll } = useSiegeUnstakeAll()
  const handleSiegeUnstakeAll = useCallback(async (caveId: string | number) => {
    try {
      setRequestedSiegeUnstakeAll(true)
      const response = await onSiegeUnstakeAll(caveId)
      if (response) {
        addTransaction(response, {
          summary: 'Unstake All!'
        })
        setSiegeUnstakeAllHash(response ? response.hash : '')
      } else {
        setRequestedSiegeUnstakeAll(false)
      }
    } catch (e) {
      console.log('handleSiegeUnstakeAll', e)
      setRequestedSiegeUnstakeAll(false)
    }
  }, [onSiegeUnstakeAll, addTransaction])

  // All staking power
  const [allStakingPower, setAllStakingPower] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    const stakingPower = caveInfo ? caveInfo.stakingPetNFTs.reduce((acc, cur) => {
      return acc.plus(cur.battlePower)
    }, new BigNumber(0)) : new BigNumber(0)
    setAllStakingPower(stakingPower)
  }, [caveInfo])

  return (
    <Wrapper>
      <Top>
        <Back onClick={toggleView}>
          <img src="/images/decoration/arrow-left.svg" alt="icon" />
        </Back>
        <StakeWrapper>
          <Text fontSize={14}>FRUIT Earned</Text>
          <Text fontSize={20} fontWeight={700} margin={'12px 0'}>{fruit.div(new BigNumber(10).pow(18)).toFixed(3, BigNumber.ROUND_FLOOR)}</Text>
          <ButtonPrimary
            onClick={() => {
              boss && handleSiegeHarvest(boss.cave)
            }}
            disabled={
              requestedSiegeHarvest ||
              fruit.lte(0)
            }
            width={'100px'}
            height={24}
            borderRadius={'8px'}
            padding={'0'}
            fontSize={12}
          >
            {requestedSiegeHarvest ? (
              <InlinePending text="Harvesting" />
            ) : (
              'Harvest'
            )}
          </ButtonPrimary>
        </StakeWrapper>
        <DividingLine src="/images/decoration/dividing_line_paw.png" alt="dividing-line"/>
        <StakeWrapper>
        <Text fontSize={14}>Staking Power</Text>
          <Text fontSize={20} fontWeight={700} margin={'12px 0'}>{allStakingPower.toFixed(0, BigNumber.ROUND_FLOOR)}</Text>
          <ButtonPrimary
            onClick={() => {
              boss && handleSiegeUnstakeAll(boss.cave)
            }}
            disabled={
              requestedSiegeUnstakeAll ||
              allStakingPower.lte(0)
            }
            width={'100px'}
            height={24}
            borderRadius={'8px'}
            padding={'0'}
            fontSize={12}
          >
            {requestedSiegeUnstakeAll ? (
              <InlinePending text="Unstaking" />
            ) : (
              'Unstake All'
            )}
          </ButtonPrimary>
        </StakeWrapper>
      </Top>
      <Bottom>
        {
          [
            ...(caveInfo ? caveInfo.stakingPetNFTs : []),
            ...pets.filter((d) => boss && d.wins.includes(boss.cave))
          ].map((d) => (
            <Pet
              key={(d.staking ? 'staking-' : '') + d.id}
              boss={boss}
              pet={d}
              updatePets={onUpdatePets}
            />
          ))
        }
      </Bottom>
    </Wrapper>
  )
}