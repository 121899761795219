import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'

import { NetworkConnector } from './NetworkConnector'

// TODO
export const ChainId = {
  BSC_MAINNET: 56,
  BSC_TESTNET: 97,
  POLYGON_MAINNET: 137,
  POLYGON_MUMBAI: 80001
}

const NETWORK_URL: string = process.env.REACT_APP_NETWORK_URL || ''

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56')

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL }
})

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, ChainId.BSC_MAINNET, ChainId.BSC_TESTNET]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 56: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// Binance Smartchain Wallet
export const bsc = new BscConnector({
  supportedChainIds: [1, 3, ChainId.BSC_MAINNET, ChainId.BSC_TESTNET, ChainId.POLYGON_MAINNET, ChainId.POLYGON_MUMBAI],
})
