import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

// utils
import { MyPetProps } from '../../../constants/pets'
import { BIGNUMBER_FMT } from '../../../constants'

// components
import { RowCenter, RowBetween } from '../../../components/Row'
import { ButtonConfirmed } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import { ChallengerProps, useArenaUnStake, useArenaHarvest } from '../../../hooks/arena'
import { useActiveWeb3React } from '../../../hooks'
import { useTransactionAdder, useIsTransactionPending } from '../../../state/transactions/hooks'

const Wrapper = styled.div`
  border-radius: 40px;
  width: 282px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 20px;
  `}
`

const PetWrapper = styled(RowCenter)`
  background: bottom 4px center / auto no-repeat url(/images/arena/ellipse_light_yellow.svg), bottom / contain no-repeat url(/images/arena/ring_bg.svg);
  width: 100%;
  height: 200px;
  overflow: hidden;
`
function Pet ({
  pet
}: {
  pet?: MyPetProps
}) {
  return (
    <PetWrapper align={pet ? 'flex-end' : undefined}>
      <img src={pet ? pet.petUrl : '/images/arena/unknow_pet.svg'} alt="pet" />
    </PetWrapper>
  )
}

const PetDetail = styled.div<{rank?: number}>`
  position: relative;
  background-color: ${({ theme }) => theme.bg3};
  background-image: ${({rank}) => rank && rank < 4 ? 'url(/images/arena/bg_rank' + rank + '.svg)' : 'none'};
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100px auto;
  box-shadow: 0px 2px 10px rgba(168, 121, 0, 0.08);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 0 16px 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  `}
`
const RankImg = styled.img`
  position: absolute;
  top: -89px;
  left: 16px;
  transform: translateY(30px);
`
const RankNum = styled(RowCenter)`
  background: ${({ theme }) => theme.bg4};
  border-radius: 50%;
  color: ${({ theme }) => theme.text3};
  font-size: 20px;
  font-weight: bold;
  width: 34px;
  height: 34px;
`
const PetName = styled(Text)`
  color: ${({ theme }) => theme.text2};
  font-weight: bold;
  font-size: 20px;
`
const Key = styled(Text)`
  color: ${({ theme }) => theme.text1};
  font-size: 12px;
`
const Value = styled(Text)`
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: bold;
`
interface WinnerProps {
  challenger: ChallengerProps
  onBattle: () => void
  update: () => void
}
export default function Winner ({
  challenger,
  onBattle,
  update
}: WinnerProps) {
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  // Unstake pet on Arena
  const [arenaUnStakeHash, setArenaUnStakeHash] = useState('')
  const arenaUnStakePending = useIsTransactionPending(arenaUnStakeHash)
  const [requestedArenaUnStake, setRequestedArenaUnStake] = useState(false)
  // reset request
  useEffect(() => {
    if (arenaUnStakeHash && !arenaUnStakePending) {
      setRequestedArenaUnStake(false)
      setArenaUnStakeHash('')
      update()
    }
  }, [arenaUnStakeHash, arenaUnStakePending, update])
  // Unstake
  const { onArenaUnStake } = useArenaUnStake()
  const handleArenaUnStake = useCallback(async (arenaId: string | number) => {
    try {
      setRequestedArenaUnStake(true)
      const response = await onArenaUnStake(arenaId)
      if (response) {
        addTransaction(response, {
          summary: 'Unstake pet!'
        })
        setArenaUnStakeHash(response ? response.hash : '')
      } else {
        setRequestedArenaUnStake(false)
      }
    } catch (e) {
      console.log('handleArenaUnStake', e)
      setRequestedArenaUnStake(false)
    }
  }, [onArenaUnStake, addTransaction])

  // Harvest on Arena
  const [arenaUnHarvestHash, setArenaHarvestHash] = useState('')
  const arenaHarvestPending = useIsTransactionPending(arenaUnHarvestHash)
  const [requestedArenaHarvest, setRequestedArenaHarvest] = useState(false)
  // reset request
  useEffect(() => {
    if (arenaUnHarvestHash && !arenaHarvestPending) {
      setRequestedArenaHarvest(false)
      setArenaHarvestHash('')
      update()
    }
  }, [arenaUnHarvestHash, arenaHarvestPending, update])
  // Harvest
  const { onArenaHarvest } = useArenaHarvest()
  const handleArenaHarvest = useCallback(async (arenaId: string | number) => {
    try {
      setRequestedArenaHarvest(true)
      const response = await onArenaHarvest(arenaId)
      if (response) {
        addTransaction(response, {
          summary: 'Harvest!'
        })
        setArenaHarvestHash(response ? response.hash : '')
      } else {
        setRequestedArenaHarvest(false)
      }
    } catch (e) {
      console.log('handleArenaHarvest', e)
      setRequestedArenaHarvest(false)
    }
  }, [onArenaHarvest, addTransaction])

  return (
    <Wrapper>
      <Pet pet={challenger.pet} />
      <PetDetail rank={challenger.arenaId}>
        <RowBetween height={60} padding={'15px 0 11px'}>
          <div>
            {
              challenger.arenaId < 4
                ? <RankImg src={'/images/arena/rank' + challenger.arenaId + '.svg'} alt="icon"></RankImg>
                : <RankNum>{challenger.arenaId}</RankNum>
            }
          </div>
          <PetName>{challenger.pet ? `#${challenger.pet.id} ${challenger.pet.name}` : '-'}</PetName>
        </RowBetween>
        <RowBetween>
          <Key>FRUIT/Day:</Key>
          <Value>{challenger.fruitDay.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
        </RowBetween>
        <RowBetween>
          <Key>Fighting Power:</Key>
          <Value>{challenger.pet ? new BigNumber(challenger.pet.battlePower).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '-'}</Value>
        </RowBetween>
        <RowBetween marginBottom={'24px'}>
          <Key>Pending Harvest FRUIT:</Key>
          <Value>{challenger.pet ? challenger.pendingFruit.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '-'}</Value>
        </RowBetween>
        {
          challenger.challengerOwner === account
            ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={() => {
                    handleArenaHarvest(challenger.arenaId)
                  }}
                  disabled={requestedArenaHarvest}
                  height={36}
                  padding={0}
                >
                  {
                    requestedArenaHarvest ? (
                      <InlinePending text="Pending" />
                    ) : (
                      'Harvest'
                    )
                  }
                </ButtonConfirmed>
                <ButtonConfirmed
                  onClick={() => {
                    challenger.pet && handleArenaUnStake(challenger.arenaId)
                  }}
                  disabled={requestedArenaUnStake}
                  height={36}
                  padding={0}
                  marginLeft={'8px'}
                >
                  {
                    requestedArenaUnStake ? (
                      <InlinePending text="Pending" />
                    ) : (
                      'Unstake'
                    )
                  }
                </ButtonConfirmed>
              </RowBetween>
            )
            : (
              <ButtonConfirmed
                onClick={onBattle}
                height={36}
                padding={0}
              >
                {
                  challenger.pet ? (
                    <>
                      <img src="/images/arena/battle.svg" alt="icon" />
                      Battle
                    </>
                  ) : 'Stake'
                }
              </ButtonConfirmed>
            )
        }
      </PetDetail>
    </Wrapper>
  )
}
