import React from 'react'

// components
import { PageTitle, PageDes } from '../../components/views'
import Map from './components/Map'

export default function Zootopia () {
  return (
    <>
      <PageTitle>Siege Battle</PageTitle>
      <PageDes>Win the battles, own your caves and be the hero!</PageDes>
      <Map />
    </>
  )
}
