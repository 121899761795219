import styled from 'styled-components'

interface PageBodyProps {
  backgroundImage?: string
  backgroundSize?: string
  backgroundPosition?: string
}
export const PageBody = styled.div<PageBodyProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${({ backgroundImage }) => backgroundImage ? backgroundImage : '/images/decoration/pets_weapon.png'});
  background-repeat: no-repeat;
  background-size: ${({ backgroundSize }) => backgroundSize ? backgroundSize : 'auto 108px'};
  background-position: ${({ backgroundPosition }) => backgroundPosition ? backgroundPosition : 'center bottom'};
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    background: none;
  `}
`

export const PageTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 40px;
  font-weight: 700;
  margin-top: 45px;
`
export const PageDes = styled.div`
  color: ${({ theme }) => theme.text1};
`
export const Card = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 40px;
  box-shadow: 0px 1px 10px rgba(168, 121, 0, 0.13);
`
export const ItemTitle = styled.div`
  color: ${({ theme }) => theme.text4};
  font-weight: 700;
`

export const PetName = styled.div<{marginTop?: number, marginBottom?: number}>`
  color: ${({ theme }) =>  theme.text4};
  font-weight: 700;
  margin-top: ${({ marginTop }) =>  marginTop ? marginTop : 0}px;
  margin-bottom: ${({ marginBottom }) =>  marginBottom ? marginBottom : 0}px;
`
export const TokenDecompose = styled.div<{marginTop?: number, marginBottom?: number}>`
  width: 100%;
  font-size: 14px;
  margin-top: ${({ marginTop }) =>  marginTop ? marginTop : 0}px;
  margin-bottom: ${({ marginBottom }) =>  marginBottom ? marginBottom : 0}px;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text1};
    }
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
