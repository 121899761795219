import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

// Components
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Header from '../components/Header'
// import Footer from '../components/Footer'
import Web3ReactManager from '../components/Web3ReactManager'
import Popups from '../components/Popups'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'

// Route
import { RedirectPathToHomeOnly } from './Home/redirects'
import Home from './Home'
// import Demo from './Demo'
// import Ido from './Ido'
import BuyWeapons from './BuyWeapons'
import MyWeapons from './MyWeapons'
import UpgradeWeapon from './MyWeapons/upgrade'
import BuyPets from './BuyPets'
import MyPets from './MyPets'
import Zootopia from './Zootopia'
import Arena from './Arena'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  z-index: 9;
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 72px);
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 16px;
  `};

  z-index: 1;
`

function App() {
  return (
    <HashRouter>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
      </AppWrapper>
      <BodyWrapper>
        <Popups />
        <Web3ReactManager>
          <Switch>
            <Route exact strict path="/" component={Home} />
            {/* <Route exact strict path="/demo" component={Demo} /> */}
            {/* <Route exact strict path="/initial-offering" component={Ido} /> */}
            <Route exact strict path="/forge-weapons" component={BuyWeapons} />
            <Route exact strict path="/my-weapons" component={MyWeapons} />
            <Route exact strict path="/my-weapons/upgrade/:weaponId" component={UpgradeWeapon} />
            <Route exact strict path="/pet-shop" component={BuyPets} />
            <Route exact strict path="/my-pets" component={MyPets} />
            <Route exact strict path="/siege-battle" component={Zootopia} />
            <Route exact strict path="/tournament-battle" component={Arena} />
            <Route component={RedirectPathToHomeOnly} />
          </Switch>
        </Web3ReactManager>
      </BodyWrapper>
      {/* <Footer /> */}
    </HashRouter>
  )
}

export default App
