import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'
import { Text } from 'rebass'

// utils
import { MyPetProps } from '../../../constants/pets'
import { BIGNUMBER_FMT, CONTRACT_ADDRESSES, petMap } from '../../../constants'

// components
import PetsCard from '../../../components/views/pets/PetsCard'
import Equipment from '../../../components/views/pets/Equipment'
import { RowBetween } from '../../../components/Row'
import { ButtonPrimary, ButtonOutlined } from '../../../components/Button'
import ProgressBar from '../../../components/views/ProgressBar'
import { EquipmentType } from './EquipmentModal'

// hooks
import { useActiveWeb3React } from '../../../hooks'
import useApproveForAll from '../../../hooks/useApproveForAll'
import { useWeaponNftContract, useHelmetNftContract } from '../../../hooks/useContract'
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'
import { useEquippedWeapon, useEquippedHelmet } from '../../../hooks/pets'

const PetBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 16px center;
  background-size: 152px auto;
  width: 100%;
  height: 246px;
`
const PetImg = styled.img`
  width: auto;
  height: 200px;
`
const PetName = styled.div`
  color: ${({ theme }) =>  theme.text4};
  font-weight: 700
`
const ButtonLight = styled(ButtonOutlined)`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 94px;
  `}
`
const ButtonMain = styled(ButtonPrimary)`
  width: 114px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 94px;
  `}
`
const TokenDecompose = styled.div`
  width: 100%;
  font-size: 14px;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text1};
    }
  }
`

interface PetProps {
  pet: MyPetProps
  allowanceHelmetEquipped?: boolean
  allowanceWeaponEquipped?: boolean
  onSelect?: (type: EquipmentType) => void
  removeSuccess?: () => void
  onSelectFeed?: () => void
  onDecompose?: () => void
}

const Left = ({
  pet,
  onSelectFeed = () => {},
  onDecompose = () => {}
}: PetProps) => {
  return (
    <>
      <PetBox>
        <PetImg src={pet.petUrl} alt="egg"/>
      </PetBox>
      <PetName>#{pet.id} {pet.name}</PetName>
      <RowBetween marginTop={'8px'} marginBottom={'14px'}>
        <ButtonLight
          onClick={onDecompose}
          width={'114px'}
          height={28}
          padding={'0px'}
        >Abandon</ButtonLight>
        {
          false && (
            <ButtonMain
              onClick={onSelectFeed}
              width={'114px'}
              height={28}
              padding={'0px'}
            >Feed</ButtonMain>
          )
        }
      </RowBetween>
      <ProgressBar
        color="yellow"
        title="Battle Power"
        value={new BigNumber(pet.battlePower).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}
        progress={new BigNumber(Math.pow(new BigNumber(pet.battlePower).toNumber(), 1 / 3)).div(100)}
        marginBottom={10}
      />
      <ProgressBar
        color="green"
        title="Experience"
        value={pet.experience + '/100'}
        progress={new BigNumber(pet.experience).div(100)}
        marginBottom={10}
      />
      <ProgressBar
        color="blue"
        title="Stamina"
        value={pet.stamina + '/100'}
        progress={new BigNumber(pet.stamina).div(100)}
        marginBottom={16}
      />
      <TokenDecompose>
        <RowBetween>
          <Text>PET to get after abandon:</Text>
          <Text fontWeight={700}>{
            new BigNumber(pet.petToGet)
              .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
          }</Text>
        </RowBetween>
        <RowBetween>
          <Text>BAKE to get after abandon:</Text>
          <Text fontWeight={700}>{
            new BigNumber(pet.bakeToGet)
              .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
          }</Text>
        </RowBetween>
      </TokenDecompose>
    </>
  )
}

const Right = ({
  pet,
  allowanceHelmetEquipped,
  allowanceWeaponEquipped,
  onSelect,
  removeSuccess = () => {}
}: PetProps) => {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  // Weapon equipped allowance
  const petNftAddr = chainId ? CONTRACT_ADDRESSES.petNft[chainId] : ''
  const [requestedApproval, setRequestedApproval] = useState(false)
  const [approvalType, setApprovalType] = useState<EquipmentType | undefined>()
  const weaponNft = useWeaponNftContract()
  const weaponApprove = useApproveForAll(weaponNft, petNftAddr)
  const helmetNft = useHelmetNftContract()
  const helmetApprove = useApproveForAll(helmetNft, petNftAddr)
  const handleApprove = useCallback(async (type: EquipmentType) => {
    try {
      setRequestedApproval(true)
      setApprovalType(type)
      let approval: any
      switch (type) {
        case EquipmentType.HAT:
          approval = helmetApprove
          break
        case EquipmentType.ARMS:
          approval = weaponApprove
          break
      }
      const txHash = approval ? await approval.onApproveAll() : undefined
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
        setApprovalType(undefined)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
          setApprovalType(undefined)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
      setApprovalType(undefined)
    }
  }, [helmetApprove, weaponApprove, setRequestedApproval])

  // Remove Weapon
  const [removeType, setRemoveType] = useState<undefined | EquipmentType>()
  const [removeHash, setRemoveHash] = useState('')
  const removePending = useIsTransactionPending(removeHash)
  const [requestedRemove, setRequestedRemove] = useState(false)
  // reset request
  useEffect(() => {
    if (removeHash && !removePending) {
      setRequestedRemove(false)
      setRemoveHash('')
      setRemoveType(undefined)
      removeSuccess()
    }
  }, [removeHash, removePending, removeSuccess])
  // Equipped
  const { equippedWeapon } = useEquippedWeapon()
  const { equippedHelmet } = useEquippedHelmet()
  const handleRemove = useCallback(async (type: EquipmentType, name: string) => {
    try {
      setRemoveType(type)
      setRequestedRemove(true)
      let response:any
      // todo
      switch (type) {
        case EquipmentType.HAT:
          response = await equippedHelmet(pet.id, 0)
          break
        case EquipmentType.ARMS:
          response = await equippedWeapon(pet.id, 0)
          break
        case EquipmentType.SHIELD:
          response = false
          break
        case EquipmentType.ARMOUR:
          response = false
          break
      }
      if (response) {
        addTransaction(response, {
          summary: `Remove ${name}!`
        })
        setRemoveHash(response ? response.hash : '')
      } else {
        setRequestedRemove(false)
        setRemoveType(undefined)
      }
    } catch (e) {
      console.log('handleRemove Equipment', e)
      setRequestedRemove(false)
      setRemoveType(undefined)
    }
  }, [pet, addTransaction, equippedHelmet, equippedWeapon])

  return (
    <>
      <Equipment
        onSelect={() => {
          onSelect && onSelect(EquipmentType.HAT)
        }}
        equipment={pet.helmet}
        name="Hat"
        defaultImg={
          pet.helmet
            ? '/images/helmet/helmet' + pet.helmet.weaponType + '/' + (pet.helmet.level - 1) + '.svg'
            : "/images/pets/equipment/hat/default.svg"
        }
        marginBottom={16}
        showOption={true}
        allowance={allowanceHelmetEquipped}
        requestedApproval={approvalType === EquipmentType.HAT && requestedApproval}
        handleApprove={() => {
          !approvalType && handleApprove(EquipmentType.HAT)
        }}
        requestedRemove={
          removeType === EquipmentType.HAT && requestedRemove
        }
        onRemove={() => {
          handleRemove(EquipmentType.HAT, 'Helmet')
        }}
      ></Equipment>
      <Equipment
        onSelect={() => {
          onSelect && onSelect(EquipmentType.ARMS)
        }}
        equipment={pet.weapon}
        name="Arms"
        defaultImg={
          pet.weapon
            ? '/images/weapons/' + petMap.name[pet.weapon.weaponType].toLowerCase() + '/' + (pet.weapon.level - 1) + '.svg'
            : "/images/pets/equipment/arms/default.svg"
        }
        marginBottom={16}
        showOption={true}
        allowance={allowanceWeaponEquipped}
        requestedApproval={approvalType === EquipmentType.ARMS && requestedApproval}
        handleApprove={() => {
          !approvalType && handleApprove(EquipmentType.ARMS)
        }}
        requestedRemove={
          removeType === EquipmentType.ARMS && requestedRemove
        }
        onRemove={() => {
          handleRemove(EquipmentType.ARMS, 'Weapon')
        }}
      ></Equipment>
      <Equipment
        name="Shield"
        defaultImg="/images/pets/equipment/shield/default.svg"
        marginBottom={16}
      ></Equipment>
      <Equipment
        name="Armour"
        defaultImg="/images/pets/equipment/armour/default.svg"
      ></Equipment>
    </>
  )
}

export default function Pet({
  pet,
  allowanceHelmetEquipped,
  allowanceWeaponEquipped,
  onSelect,
  removeSuccess,
  onSelectFeed,
  onDecompose
}: PetProps) {
  return (
    <PetsCard
      left={
        <Left
          pet={pet}
          onSelectFeed={onSelectFeed}
          onDecompose={onDecompose}
        />
      }
      right={
        <Right
          pet={pet}
          allowanceHelmetEquipped={allowanceHelmetEquipped}
          allowanceWeaponEquipped={allowanceWeaponEquipped}
          onSelect={onSelect}
          removeSuccess={removeSuccess}
        />
      }
    ></PetsCard>
  )
}
