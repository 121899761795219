import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: 700;
  margin: 40px 0 16px 0;
`
const PetsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  cursor: pointer;
  font-weight: 700;
  & > i {
    display: block;
    border: 2px solid ${({ theme }) => theme.border3};
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    span {
      margin-left: 5px;
    }
  `}
`
const PetAvatar = styled.img`
  margin: 0 8px 0 14px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ i {
    position: relative;
    border-color: ${({ theme }) => theme.border2};
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      background: ${({ theme }) => theme.border2};
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
  &:checked ~ span {
    color: ${({ theme }) => theme.text2};
  }
`
export interface PetProps {
  name: string
  icon: string
  petType: number
  defaultChecked?: boolean
}
export interface SelectPetProps {
  title?: string
  pets: PetProps[]
  onSelectPet: (name: React.FormEvent) => void
}

export default function SelectPet({title, pets, onSelectPet}: SelectPetProps) {
  return (
    <>
      {title && <Title>{title}</Title>}
      <PetsWrapper>
        {
          pets.map((d) => (
            <Label key={d.name} htmlFor={d.name}>
              <Radio
                type="radio"
                id={d.name}
                defaultChecked={d.defaultChecked}
                name='pets'
                onChange={onSelectPet}
              />
              <i></i>
              <PetAvatar src={d.icon} alt="pet"/>
              <span>{d.name}</span>
            </Label>
          ))
        }
      </PetsWrapper>
    </>
  )
}
