import { useCallback } from 'react'

import { harvestWeaponNFT } from '../../utils/lib/utils'

// hooks
import { useWeaponMasterContract } from '../useContract'

const useHarvestWeaponNFT = () => {
  const weaponMaster = useWeaponMasterContract()

  const handleHarvest = useCallback(async () => {
    try {
      if (weaponMaster) {
        const txHash = await harvestWeaponNFT(weaponMaster)
        return txHash
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }, [weaponMaster])

  return { onHarvestWeaponNFT: handleHarvest }
}

export default useHarvestWeaponNFT
