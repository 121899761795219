import { useCallback, useEffect, useState } from "react"
import BigNumber from "bignumber.js"
import { BigNumber as EBigNumber} from '@ethersproject/bignumber'

// utils
import { getWillBuyAmount, getNeedAmountToBuy } from '../utils/lib/utils'
import { CONTRACT_ADDRESSES } from '../constants'

// hooks
import { useBakerySwapRouterContract } from './useContract'
import { useActiveWeb3React } from '.'
import { useBlockNumber } from '../state/application/hooks'

export default function usePET({
  baseAsset,
  quoteAsset = 'pet',
  amount = '0',
  exclude = [],
  isExactOut,
} : {
  baseAsset: string
  quoteAsset?: string
  amount: string | number
  exclude?: string[]
  isExactOut?: boolean
}): BigNumber {
  const [pet, setPET] = useState(new BigNumber(0))
  const bakerySwapRouterContract = useBakerySwapRouterContract()
  const { chainId } = useActiveWeb3React()
  const latestBlockNumber = useBlockNumber()

  const fetchPET = useCallback(async () => {
    try {
      if (chainId && bakerySwapRouterContract) {
        const amountVal = new BigNumber(amount).gt(0) ? amount : 0
        const petAmount = (baseAsset === quoteAsset || exclude.includes(baseAsset))
          ? EBigNumber.from(new BigNumber(amountVal).toString()).mul(EBigNumber.from(10).pow(10))
          : new BigNumber(amountVal).lte(0)
            ? EBigNumber.from(0)
            : isExactOut
              ? await getNeedAmountToBuy(
                bakerySwapRouterContract,
                EBigNumber.from(amountVal).mul(EBigNumber.from(10).pow(10)),
                CONTRACT_ADDRESSES[baseAsset][chainId],
                CONTRACT_ADDRESSES[quoteAsset][chainId]
              )
              : await getWillBuyAmount(
                bakerySwapRouterContract,
                EBigNumber.from(amountVal).mul(EBigNumber.from(10).pow(10)),
                CONTRACT_ADDRESSES[baseAsset][chainId],
                CONTRACT_ADDRESSES[quoteAsset][chainId]
              )
        setPET(new BigNumber(petAmount.toString()).div(new BigNumber(10).pow(18)))
        return
      }
    } catch (e) {
      console.log('---e--->', e)
    }
    setPET(new BigNumber(0))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAsset, quoteAsset, amount, setPET, chainId, bakerySwapRouterContract])

  useEffect(() => {
    fetchPET()
  }, [latestBlockNumber, baseAsset, quoteAsset, fetchPET, amount])

  return pet
}
