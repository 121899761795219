import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

// utils
import { synthesis } from '../../utils/lib/utils'
import { CONTRACT_ADDRESSES } from '../../constants'

// hooks
import { useWeaponNftMasterContract } from '../useContract'
import { useActiveWeb3React } from '../'

const useWeaponSynthesis = () => {
  const { chainId } = useActiveWeb3React()
  const weaponNftMaster = useWeaponNftMasterContract()
  const handleSynthesis = useCallback(async (tokenName: string, weaponType: number, amount: string) => {
    if (synthesis && chainId && weaponNftMaster) {
      try {
        const tx = await synthesis(
          weaponNftMaster,
          CONTRACT_ADDRESSES[tokenName][chainId],
          BigNumber.from(weaponType),
          BigNumber.from(amount).mul(BigNumber.from(10).pow(8))
        )
        return tx
      } catch (e) {
        console.log('useWeaponSynthesis', e)
        return false
      }
    } else {
      return false
    }
  }, [chainId, weaponNftMaster])

  return {synthesis: handleSynthesis}
}

export default useWeaponSynthesis