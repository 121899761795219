import { useCallback } from "react"
import { BigNumber } from "bignumber.js"
import { BigNumber as EBigNumber } from '@ethersproject/bignumber'

// utils
import {
  arenaChallengeGetArenaInfos,
  getPetInfo,
  arenaChallengeStake,
  arenaChallengeUnstake,
  arenaChallengeHarvest,
  arenaFruitPerBlock
} from '../../utils/lib/utils'
import { compare } from '../../utils/func'
import { MyPetProps } from '../../constants/pets'

// hooks
import {
  useArenaChallengeContract,
  usePetNftContract,
  useWeaponNftContract,
  useSiegeContract,
  useHelmetNftContract
} from '../useContract'
import { ParsingPet } from '../pets'
import { useBlockNumber } from '../../state/application/hooks'

export interface ChallengerProps {
  arenaId: number
  pendingFruit: BigNumber
  totalRewardDebt: BigNumber
  allocPoint: BigNumber
  fruitDay: BigNumber
  lastRewardBlock: BigNumber
  challenger: BigNumber
  challengeTimes: BigNumber
  challengeRewardDebt: BigNumber
  challengeTotalRewardDebt: BigNumber
  challengerOwner: string
  pet: MyPetProps | undefined
}

export const useTop50 = () => {
  const arenaChallenge = useArenaChallengeContract()
  const petNft = usePetNftContract()
  const helmetNft = useHelmetNftContract()
  const weaponNft = useWeaponNftContract()
  const siegeContract = useSiegeContract()
  const latestBlockNumber = useBlockNumber()
  const fetchTop50 = useCallback(async () => {
    if (arenaChallenge && petNft) {
      try {
        const list = await arenaChallengeGetArenaInfos(arenaChallenge)
        const fruitPerBlock = await arenaFruitPerBlock(arenaChallenge)
        const totalAllocPoint = list.reduce((acc, cur) => {
          return acc.add(cur.allocPoint)
        }, EBigNumber.from(0))
        const top50: ChallengerProps[] = await Promise.all(
          list.map(async (d) => {
            const petInfo = d.challenger.gt(0) ? await getPetInfo(petNft, d.challenger) : undefined
            const pet = petInfo ? await ParsingPet(
              petInfo,
              helmetNft,
              weaponNft,
              siegeContract,
              latestBlockNumber
            ) : undefined
            const fruitDay = new BigNumber(fruitPerBlock.div(EBigNumber.from(10).pow(18)).mul(28800).toString())
              .times(new BigNumber(d.allocPoint.toString()).div(totalAllocPoint.toString()))
            return {
              arenaId: d.arenaId.toNumber(),
              pendingFruit: new BigNumber(d.pendingFruit.div(EBigNumber.from(10).pow(18)).toString()),
              totalRewardDebt: new BigNumber(d.totalRewardDebt.div(EBigNumber.from(10).pow(18)).toString()),
              allocPoint: new BigNumber(d.allocPoint.toString()),
              lastRewardBlock: new BigNumber(d.lastRewardBlock.toString()),
              challenger: new BigNumber(d.challenger.toString()),
              challengeTimes: new BigNumber(d.challengeTimes.toString()),
              challengeRewardDebt: new BigNumber(d.challengeRewardDebt.div(EBigNumber.from(10).pow(18)).toString()),
              challengeTotalRewardDebt: new BigNumber(d.challengeTotalRewardDebt.div(EBigNumber.from(10).pow(18)).toString()),
              challengerOwner: d.challengerOwner,
              fruitDay,
              pet
            }
          })
        )
        return [
          ...top50
        ].sort(compare('tokenId'))
      } catch (e) {
        console.log('fetchTop50', e)
      }
    }
    return []
  }, [arenaChallenge, petNft, helmetNft, weaponNft, siegeContract, latestBlockNumber])

  return fetchTop50
}

// Arena Stake
export const useArenaStake = () => {
  const arenaChallengeContract = useArenaChallengeContract()
  const handleArenaStake = useCallback(async (arenaId, tokenId: string | number) => {
    try {
      if (arenaChallengeContract) {
        const tx = await arenaChallengeStake(
          arenaChallengeContract,
          EBigNumber.from(arenaId),
          EBigNumber.from(tokenId)
        )
        return tx
      }
    } catch (e) {
      console.log('useArenaStake', e)
    }
    return false
  }, [arenaChallengeContract])

  return { onArenaStake: handleArenaStake }
}

// Arena Unstake
export const useArenaUnStake = () => {
  const arenaChallengeContract = useArenaChallengeContract()
  const handleArenaUnStake = useCallback(async (arenaId: string | number) => {
    try {
      if (arenaChallengeContract) {
        const tx = await arenaChallengeUnstake(
          arenaChallengeContract,
          EBigNumber.from(arenaId)
        )
        return tx
      }
    } catch (e) {
      console.log('useArenaUnStake', e)
    }
    return false
  }, [arenaChallengeContract])

  return { onArenaUnStake: handleArenaUnStake }
}

// Arena Harvest
export const useArenaHarvest = () => {
  const arenaChallengeContract = useArenaChallengeContract()
  const handleArenaHarvest = useCallback(async (arenaId: string | number) => {
    try {
      if (arenaChallengeContract) {
        const tx = await arenaChallengeHarvest(
          arenaChallengeContract,
          EBigNumber.from(arenaId)
        )
        return tx
      }
    } catch (e) {
      console.log('handleArenaHarvest', e)
    }
    return false
  }, [arenaChallengeContract])

  return { onArenaHarvest: handleArenaHarvest }
}
