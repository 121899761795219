import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'
import { NavLink } from 'react-router-dom'

// utils
import { PetType, supportPetType, CONTRACT_ADDRESSES, BIGNUMBER_FMT } from '../../../constants'

// components
import WeaponCard from '../../../components/views/WeaponCard'
import { RowBetween } from '../../../components/Row'
import { ButtonOutlined, ButtonPrimary } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import { useActiveWeb3React } from '../../../hooks'
import { useWeaponNftContract } from '../../../hooks/useContract'
import useApproveForAll from '../../../hooks/useApproveForAll'
import useStakeWeaponNFT from '../../../hooks/weapons/useStakeWeaponNFT'
import useUnstakeWeaponNFT from '../../../hooks/weapons/useUnstakeWeaponNFT'
import useDecomposeNFT from '../../../hooks/weapons/useDecomposeNFT'
import { useTransactionAdder, useIsTransactionPending } from '../../../state/transactions/hooks'

const Wrapper = styled.div`
  width: 282px;
  margin-bottom: 24px;
  &:not(:nth-child(3n)) {
    margin-right: 23px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    &:not(:nth-child(3n)) {
      margin-right: 0;
    }
  `}
`
const WeaponDetail = styled.div`
  font-size: 14px;
  padding: 32px 16px 20px;
`
const Value = styled(Text)`
  color: ${({theme}) => theme.text4};
  font-weight: 700
`
const UpgradeLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 10px;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 36px;
  text-decoration: none;
  margin-top: 10px;
`

export interface WeaponProps {
  tokenId: string
  weaponType: number
  level: number
  stakingPower: BigNumber
  increaseBattlePowerPercent: BigNumber
  petToGet: BigNumber
  bakeToGet: BigNumber
  staking?: boolean
}
export default function Weapon({
  weapon,
  allowance,
  refreshMyWeapons
}: {
  weapon: WeaponProps,
  allowance: boolean,
  refreshMyWeapons: () => void
}) {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  // Allowance
  const weaponNft = useWeaponNftContract()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const weaponMasterAddr = chainId ? CONTRACT_ADDRESSES.weaponMaster[chainId] : ''
  const { onApproveAll } = useApproveForAll(weaponNft, weaponMasterAddr)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApproveAll()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApproveAll, setRequestedApproval])

  // Stake
  const [stakeHash, setStakeHash] = useState('')
  const stakePending = useIsTransactionPending(stakeHash)
  const [requestedStake, setRequestedStake] = useState(false)
  // reset request
  useEffect(() => {
    let timer: any
    if (stakeHash && !stakePending) {
      refreshMyWeapons()
      timer = setTimeout(() => {
        setRequestedStake(false)
        setStakeHash('')
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [stakeHash, stakePending, refreshMyWeapons])

  const { onStakeWeaponNFT } = useStakeWeaponNFT(weapon.tokenId)
  const handleStake = useCallback(async () => {
    try {
      setRequestedStake(true)
      const response = await onStakeWeaponNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Stake Weapon NFT'
        })
        setStakeHash(response ? response.hash : '')
      } else {
        setRequestedStake(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onStakeWeaponNFT, addTransaction])

  // Unstake
  const [unstakeHash, setUnstakeHash] = useState('')
  const unstakePending = useIsTransactionPending(unstakeHash)
  const [requestedUnstake, setRequestedUnstake] = useState(false)
  // reset request
  useEffect(() => {
    let timer: any
    if (unstakeHash && !unstakePending) {
      refreshMyWeapons()
      timer = setTimeout(() => {
        setRequestedUnstake(false)
        setUnstakeHash('')
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [unstakeHash, unstakePending, refreshMyWeapons])

  const { onUnstakeWeaponNFT } = useUnstakeWeaponNFT(weapon.tokenId)
  const handleUnstake = useCallback(async () => {
    try {
      setRequestedUnstake(true)
      const response = await onUnstakeWeaponNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Unstake Weapon NFT'
        })
        setUnstakeHash(response ? response.hash : '')
      } else {
        setRequestedUnstake(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onUnstakeWeaponNFT, addTransaction])

  // Melt
  const [decomposeHash, setDecomposeHash] = useState('')
  const decomposePending = useIsTransactionPending(decomposeHash)
  const [requestedDecompose, setRequestedDecompose] = useState(false)
  // reset request
  useEffect(() => {
    if (decomposeHash && !decomposePending) {
      refreshMyWeapons()
    }
  }, [decomposeHash, decomposePending, refreshMyWeapons])
  const { onDecomposeNFT } = useDecomposeNFT(weapon.tokenId)
  const handleDecompose = useCallback(async () => {
    try {
      setRequestedDecompose(true)
      const response = await onDecomposeNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Melt Weapon NFT'
        })
        setDecomposeHash(response ? response.hash : '')
      } else {
        setRequestedDecompose(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onDecomposeNFT, addTransaction])

  return (
    <Wrapper>
      <WeaponCard
        weaponType={supportPetType.includes(weapon.weaponType) ? weapon.weaponType : PetType.kitten}
        level={weapon.level}
        width={282}
        weaponWidth={86}
        showLevel={true}
        borderRadius={40}
        hideBorder={true}
        hoverBorder={true}
        cardShadow={true}
        wrapperHeight={166}
        wrapperShadow={true}
        weaponId={weapon.tokenId}
      >
        <WeaponDetail>
          <RowBetween padding={'0 0 8px 0'}>
          <Text>Staking Power:</Text>
            <Value>{weapon.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
          </RowBetween>
          <RowBetween padding={'0 0 8px 0'}>
            <Text>$PET left after melting:</Text>
            <Value>{weapon.petToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
          </RowBetween>
          <RowBetween padding={'0 0 8px 0'}>
            <Text>$BAKE left after melting:</Text>
            <Value>{weapon.bakeToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
          </RowBetween>
          <RowBetween padding={'0 0 22px 0'}>
            <Text>Battle power increase:</Text>
            <Value>{weapon.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
          </RowBetween>
          <RowBetween>
            <ButtonOutlined
              onClick={handleDecompose}
              disabled={
                !allowance ||
                weapon.staking ||
                requestedDecompose ||
                requestedStake ||
                requestedUnstake
              }
              width={'120px'}
              height={36}
              padding={'0px 0px'}
            >
              {
                requestedDecompose ? (
                  <InlinePending text="Melting" stroke="#EBA900"/>
                ) : 'Melt'
              }
            </ButtonOutlined>
            {
              !allowance ? (
                <ButtonPrimary
                  onClick={handleApprove}
                  disabled={requestedApproval}
                  width={'120px'}
                  height={36}
                  padding={'0px 0px'}
                >
                  {
                    requestedApproval ? (
                      <InlinePending text="Approving" />
                    ) : 'Approve'
                  }
                </ButtonPrimary>
              ) : weapon.staking ? (
                <ButtonPrimary
                  onClick={handleUnstake}
                  disabled={requestedUnstake || requestedDecompose}
                  width={'120px'}
                  height={36}
                  padding={'0px 0px'}
                >
                  {requestedUnstake ? (
                    <InlinePending text="Unstaking" />
                  ) : (
                    'Unstake'
                  )}
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  onClick={handleStake}
                  disabled={requestedStake || requestedDecompose}
                  width={'120px'}
                  height={36}
                  padding={'0px 0px'}
                >
                  {requestedStake ? (
                    <InlinePending text="Staking" />
                  ) : (
                    'Stake'
                  )}
                </ButtonPrimary>
              )
            }
          </RowBetween>
          {
            false && (weapon.staking ? (
              <ButtonOutlined
                disabled={true}
                width={'100%'}
                height={36}
                padding={'0px 0px'}
                marginTop={10}
              >
                Upgrade
              </ButtonOutlined>
            ) : (
              <UpgradeLink
                to={'/my-weapons/upgrade/' + weapon.tokenId}
              >Upgrade</UpgradeLink>
            )
          )}
        </WeaponDetail>
      </WeaponCard>
    </Wrapper>
  )
}
