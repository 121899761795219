import { useCallback } from 'react'
import { Contract } from '@ethersproject/contracts'

// utils
import { setApprovalForAll } from '../utils/lib/utils'

const useApproveForAll = (erc721: Contract | null, to?: string) => {
  const handleApprove = useCallback(async () => {
    try {
      if (erc721 && to) {
        const tx = await setApprovalForAll(erc721, to)
        return tx
      } else {
        throw new Error('erc721 is not a Contract or to is not a string')
      }
    } catch (e) {
      return false
    }
  }, [erc721, to])

  return { onApproveAll: handleApprove }
}

export default useApproveForAll
