import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { BigNumber } from 'bignumber.js'

// utils
import { MyPetProps } from '../../constants/pets'

// components
import { PageBody, PageTitle, PageDes } from '../../components/views'
import Winner from './components/Winner'
import Paginate from '../../components/views/Paginate'
import Loading from '../../components/Loader/Loading'
import ChallengeModal from './components/ChallengeModal'
import { ButtonPrimary } from '../../components/Button'
import { RowCenter } from '../../components/Row'

// hooks
import { useTop50, ChallengerProps } from '../../hooks/arena'
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import useInterval from '../../hooks/useInterval'
import { useAllPets } from '../../hooks/pets'

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 894px;
  margin: 48px 0 0;
  &>div {
    margin-bottom: 40px;
  }
  &>div:not(:nth-child(3n)) {
    margin-right: 24px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 410px;
    margin: 24px 0 0;
    &>div {
      margin-bottom: 24px;
    }
    &>div:not(:nth-child(3n)) {
      margin-right: 0;
    }
  `}
`
const ButtonGroups = styled(RowCenter)`
  flex: 1;
`

export default function Top50() {
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  const [page, setPage] = useState(0)
  const onPageChange = useCallback((d) => {
    setPage(d.selected)
  }, [])

  // fetch top50 data
  const [top50, setTop50] = useState<ChallengerProps[]>([])
  const [loadingFlag, setLoadingFlag] = useState(false)
  const getTop50 = useTop50()
  const fetchTop50 = useCallback(async (loading?: boolean) => {
    if (loading) {
      setLoadingFlag(true)
    }
    const top50 = await getTop50()
    // console.log('top50', top50)
    setTop50(top50)
    setLoadingFlag(false)
  }, [getTop50])

  useEffect(() => {
    fetchTop50(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useInterval(fetchTop50, account ? 10000 : null)

  const [bossIndex, setBossIndex] = useState<number | undefined>()
  const [boss, setBoss] = useState<ChallengerProps | undefined>()
  useEffect(() => {
    if (bossIndex === undefined) return
    const newBoss = top50[bossIndex]
    if (
      !boss ||
      !newBoss.pet ||
      (boss.pet && !(new BigNumber(newBoss.pet.id).eq(boss.pet.id))) ||
      newBoss.challengeTimes.gt(boss.challengeTimes)
    ) {
      setBoss(newBoss)
    }
  }, [bossIndex, top50, boss])
  const closeChallengeModal = useCallback(() => {
    setBossIndex(undefined)
    setBoss(undefined)
  }, [])

  const [myPets, setMyPets] = useState<MyPetProps[]>([])
  const getAllPets = useAllPets({onlyPets: true})
  const fetchAllPets = useCallback(async () => {
    const pets = await getAllPets()
    setMyPets(pets as MyPetProps[])
  }, [getAllPets])
  useEffect(() => {
    fetchAllPets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useInterval(fetchAllPets, account ? 10000 : null)

  const update = useCallback(() => {
    fetchAllPets()
    fetchTop50()
  }, [fetchAllPets, fetchTop50])

  return (
    <PageBody>
      <Loading open={loadingFlag} />
      <PageTitle>Tournament Battle</PageTitle>
      <PageDes>Be the Champion, Win the rewards!</PageDes>
      <ChallengeModal
        boss={boss}
        onDismiss={closeChallengeModal}
        myPets={myPets}
        update={update}
      />
      {
        !account ? (
          <ButtonGroups>
            <ButtonPrimary
              onClick={toggleWalletModal}
              width={'240px'}
              height={48}
              padding={'0px'}
            >
              Connect Wallet
            </ButtonPrimary>
          </ButtonGroups>
        ) : (
          <ListWrapper>
            {
              top50.slice(page * 9, Math.min(page * 9 + 9, 50)).map((d) => {
                return (
                  <Winner
                    key={d.arenaId}
                    challenger={d}
                    onBattle={() => {
                      setBossIndex(d.arenaId - 1)
                    }}
                    update={update}
                  />
                )
              })
            }
          </ListWrapper>
        )
      }
      {
        top50.length > 0 ? (
          <Paginate
            pageCount={6}
            initialPage={page}
            onPageChange={onPageChange}
            marginBottom={isMobile ?  24 : 130}
          />
        ) : null
      }
    </PageBody>
  )
}