import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { BigNumber } from 'bignumber.js'

import { BIGNUMBER_FMT } from '../../../constants'

// components
import { ButtonPrimary } from '../../../components/Button'
import InlinePending from '../../../components/Loader/InlinePending'

// hooks
import useWeaponEarned from '../../../hooks/weapons/useWeaponEarned'
import { useTransactionAdder, useIsTransactionPending } from '../../../state/transactions/hooks'
import useHarvestWeaponNFT from '../../../hooks/weapons/useHarvestWeaponNFT'
import useUnstakeAllWeaponNFT from '../../../hooks/weapons/useUnstakeAllWeaponNFT'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${({theme}) => theme.bg3};
  box-shadow: 0px 2px 10px rgba(168, 121, 0, 0.08);
  border-radius: 20px;
  height: 217px;
  margin: 54px 0;

  & > div {
    flex: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    background: none;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: initial;
    margin: 24px 0;

    & > div {
      flex: initial;
    }
  `}
`
const DividImg = styled.img`
  width: 6px;
  height: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme}) => theme.text2};
  font-weight: 700;
  height: 100%;
  padding: 24px 0 27px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    background: ${({theme}) => theme.bg3};
    box-shadow: 0px 2px 10px rgba(168, 121, 0, 0.08);
    border-radius: 20px;
    width: 100%;
    height: 217px;
    margin-bottom: 24px;
  `}
`
const Value = styled.div`
  background: ${({theme}) => theme.border3}29;
  border-radius: 16px;
  font-size: 24px;
  width: 240px;
  line-height: 56px;
  text-align: center;
  margin: 25px 0 27px;
`

interface EarningProps {
  allStakingPower: BigNumber
  refreshMyWeapons: () => void
}

export default function Earning({allStakingPower, refreshMyWeapons}: EarningProps) {
  const pendingHarvestWeapon = useWeaponEarned()
  const addTransaction = useTransactionAdder()

  // Harvest
  const [harvestHash, setHarvestHash] = useState('')
  const stakePending = useIsTransactionPending(harvestHash)
  const [requestedHarvest, setRequestedHarvest] = useState(false)
  // reset request
  useEffect(() => {
    let timer: any
    if (harvestHash && !stakePending) {
      timer = setTimeout(() => {
        setRequestedHarvest(false)
        setHarvestHash('')
      }, 15000)
    }
    return () => clearTimeout(timer)
  }, [harvestHash, stakePending])

  const { onHarvestWeaponNFT } = useHarvestWeaponNFT()
  const handleHarvest = useCallback(async () => {
    try {
      setRequestedHarvest(true)
      const response = await onHarvestWeaponNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Harvest Weapon'
        })
        setHarvestHash(response ? response.hash : '')
      } else {
        setRequestedHarvest(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onHarvestWeaponNFT, addTransaction])

  // Unstake All
  const [unstakeAllHash, setUnstakeAllHash] = useState('')
  const unstakeAllPending = useIsTransactionPending(unstakeAllHash)
  const [requestedUnstakeAll, setRequestedUnstakeAll] = useState(false)
  // reset request
  useEffect(() => {
    let timer: any
    if (unstakeAllHash && !unstakeAllPending) {
      refreshMyWeapons()
      timer = setTimeout(() => {
        setRequestedUnstakeAll(false)
        setUnstakeAllHash('')
      }, 3000)
    }
    return () => clearTimeout(timer)
  }, [unstakeAllHash, unstakeAllPending, refreshMyWeapons])

  const { onUnstakeAllWeaponNFT } = useUnstakeAllWeaponNFT()
  const handleUnstakeAll = useCallback(async () => {
    try {
      setRequestedUnstakeAll(true)
      const response = await onUnstakeAllWeaponNFT()
      if (response) {
        addTransaction(response, {
          summary: 'Unstake All Weapon'
        })
        setUnstakeAllHash(response ? response.hash : '')
      } else {
        setRequestedUnstakeAll(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onUnstakeAllWeaponNFT, addTransaction])

  return (
    <Wrapper>
      <Box>
        <Text>Weapon Earned</Text>
        <Value>{ pendingHarvestWeapon.toFormat(3, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) }</Value>
        <ButtonPrimary
          onClick={handleHarvest}
          disabled={
            pendingHarvestWeapon.lte(0) ||
            allStakingPower.lte(0) ||
            requestedHarvest
          }
          width={'120px'}
          height={36}
          padding="0px 0px"
        >
          {
            requestedHarvest ? (
              <InlinePending text="Harvesting" />
            ) : 'Harvest'
          }
        </ButtonPrimary>
      </Box>
      <DividImg src="/images/decoration/dividing_line.png" alt="dividing_line"/>
      <Box>
        <Text>Staking Power</Text>
        <Value>{allStakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</Value>
        <ButtonPrimary
          onClick={handleUnstakeAll}
          disabled={
            allStakingPower.lte(0) ||
            requestedUnstakeAll
          }
          width={'120px'}
          height={36}
          padding="0px 0px"
        >
          {
            requestedUnstakeAll ? (
              <InlinePending text="Unstaking" />
            ) : 'Unstake All'
          }
        </ButtonPrimary>
      </Box>
    </Wrapper>
  )
}