import React from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'

import { Card } from './'

const Wrapper = styled.div<{margin?: string}>`
  position: relative;
  width: 616px;
  margin: ${({ margin }) => margin ? margin : '60px 0'};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin: 60px 0;
  `}
`
const Box = styled(Card)`
  position: relative;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  text-align: center;
  padding: 50px 140px 24px;
  z-index: 1;
  min-height: 150px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 24px 16px;
    min-height: 0;
  `}
`
const BoxHead = styled.div`
  color: ${({ theme }) => theme.text2};
`
const BoxContent = styled.div`
  margin: 24px 0 46px;
`
const NavItem = styled(NavLink)`
  position: relative;
  display: inline-block;
  background: ${({ theme }) => theme.bg4};
  border-radius: 10px;
  color: ${({ theme }) => theme.text6};
  font-size: 14px;
  font-weight: 700;
  width: 240px;
  line-height: 40px;
  text-decoration: none;
`
const ImgCat = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
interface NoDataProps {
  title: string
  content?: string
  hideMeow?: boolean
  imageUrl?: string
  margin?: string
  btn?: {
    [key: string]: string
  }
}
export default function NoData(
  {
    title,
    content,
    hideMeow,
    imageUrl,
    margin,
    btn
  }: NoDataProps
) {
  return (
    <Wrapper margin={margin}>
      {imageUrl && <ImgCat src={imageUrl} alt="cat"/>}
      <Box>
        <BoxHead>
          {!hideMeow && <Text fontSize={24} fontWeight={700}>Meow~</Text>}
          <Text fontSize={18} fontWeight={700}>{title}</Text>
        </BoxHead>
        {content && <BoxContent>{content}</BoxContent>}
        {btn && <NavItem to={btn.url}>{btn.name}</NavItem>}
      </Box>
    </Wrapper>
  )
}
