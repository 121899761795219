import { useCallback, useEffect, useState } from 'react'
import { Contract } from '@ethersproject/contracts'

// utils
import { isApprovedForAll } from '../utils/lib/utils'

// hooks
import { useActiveWeb3React } from '.'
import useInterval from './useInterval'

const useAllowanceForAll = (
  {
    erc721,
    to,
    requested,
    reset
  }: {
    erc721: Contract | null,
    to?: string,
    requested?: boolean,
    reset?: boolean
  }
) => {
  const [allowance, setAllowance] = useState(false)
  const {account} = useActiveWeb3React()

  const fetchAllowanceForAll = useCallback(async () => {
    if (account && erc721 && to) {
      const allowance = await isApprovedForAll(
        account,
        erc721,
        to,
      )
      setAllowance(allowance) 
    }
  }, [account, erc721, to])

  useEffect(() => {
    if (account && erc721 && to) {
      fetchAllowanceForAll()
    }
  }, [account, erc721, to, fetchAllowanceForAll])

  useInterval(fetchAllowanceForAll, reset || (requested && !allowance) ? 3000 : null)

  return allowance
}

export default useAllowanceForAll