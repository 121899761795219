import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'

import { Colors } from './styled'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#212833'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    headerBg: darkMode ? '#FFFFFF' : '#FFFFFF',
    footerBg: darkMode ? '#FFFFFF' : '#FFFFFF',

    // text
    text1: darkMode ? '#828282' : '#828282',
    text2: darkMode ? '#EBA900' : '#EBA900',
    text3: darkMode ? '#FFFFFF' : '#FFFFFF',
    text4: darkMode ? '#4F4F4F' : '#4F4F4F',
    text5: darkMode ? '#333333' : '#333333',
    text6: darkMode ? '#FFFDF9' : '#FFFDF9',
    text7: darkMode ? '#BDBDBD' : '#BDBDBD',
    text8: darkMode ? '#B96F00' : '#B96F00',
    text9: darkMode ? '#565656' : '#565656',
    text10: darkMode ? '#676767' : '#676767',
    text11: darkMode ? '#483730' : '#483730',
    text12: darkMode ? '#C5BFD8' : '#C5BFD8',
    text13: darkMode ? '#A6A8AB' : '#A6A8AB',
    text14: darkMode ? '#715EF6' : '#715EF6',

    // backgrounds / greys
    bg1: darkMode ? '#FFFDF9' : '#FFFDF9',
    bg2: darkMode ? '#FFF5DC' : '#FFF5DC',
    bg3: darkMode ? '#FFFFFF' : '#FFFFFF',
    bg4: darkMode ? '#EBA900' : '#EBA900',
    bg5: darkMode ? '#ADDF7E' : '#ADDF7E',
    bg6: darkMode ? '#94CFCB' : '#94CFCB',
    bg7: darkMode ? '#F4D37C' : '#F4D37C',
    bg8: darkMode ? '#F3F8FD' : '#F3F8FD',
    bg9: darkMode ? '#F1F1F1' : '#F1F1F1',
    bg10: darkMode ? '#483730' : '#483730',

    opacityBg1: darkMode ? '#f4d37c33' : '#f4d37c33',

    // button
    btnBg1: darkMode ? '#F7DD99' : '#F7DD99',
    btnBg2: darkMode ? '#AE895D' : '#C27A4F',

    // border
    border1: darkMode ? '#F4D37C' : '#F4D37C',
    border2: darkMode ? '#EBA900' : '#EBA900',
    border3: darkMode ? '#E0E0E0' : '#E0E0E0',
    border4: darkMode ? '#BDBDBD' : '#BDBDBD',
    border5: darkMode ? '#B96F00' : '#B96F00',
    border6: darkMode ? '#DADADA' : '#DADADA',
    border7: darkMode ? '#9A9A9A' : '#9A9A9A',
  
    // button color
    btnText1: darkMode ? '#E1CCAC' : '#FFFDFA',
    btnText2: darkMode ? '#905C35' : '#E1B697',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#EBA900' : '#EBA900',
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: darkMode ? '#FFCD8470' : '#AC562A70',
    primary5: darkMode ? '#FFCD84' : '#AC562A',
    primary6: darkMode
      ? 'linear-gradient(180deg, #FFA440 0%, #F74F4C 100%)'
      : 'linear-gradient(180deg, #FFA440 0%, #F74F4C 100%)',
    
    // color text
    primaryText1: darkMode ? '#722F0D' : '#FFFDFA',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FF6871',
    red2: '#F82D3A',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E'
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,

    darkMode: darkMode
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
  html, input, textarea, button {
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.018em;
    font-display: fallback;
  }
  @supports (font-variation-settings: normal) {
    html, input, textarea, button {
      // font-family: 'Inter var', sans-serif;
      font-family: 'Yuanti SC', 'Inter var', sans-serif;
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  button {
    user-select: none;
  }

  html {
    font-size: 16px;
    font-variant: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    letter-spacing: 0.01em;
  }
  h1, h2, h3, h4, p {
    margin: 0;
  }
`

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg1};
  }

  body {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${
      ({ theme }) => theme.bg1
    };
  }
`
