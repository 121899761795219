import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'

// todo
enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  POLYGON_MAINNET = 137,
  POLYGON_MUMBAI = 80001
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.ROPSTEN]: 'ropsten.',
  [ChainId.RINKEBY]: 'rinkeby.',
  [ChainId.GÖRLI]: 'goerli.',
  [ChainId.KOVAN]: 'kovan.',
  [ChainId.BSC_TESTNET]: 'testnet.',
  [ChainId.BSC_MAINNET]: '',
  [ChainId.POLYGON_MAINNET]: '',
  [ChainId.POLYGON_MUMBAI]: 'mumbai.'
}

const ETHERSCAN_SUFFIXS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'etherscan.io',
  [ChainId.ROPSTEN]: 'etherscan.io',
  [ChainId.RINKEBY]: 'etherscan.io',
  [ChainId.GÖRLI]: 'etherscan.io',
  [ChainId.KOVAN]: 'etherscan.io',
  [ChainId.BSC_MAINNET]: 'bscscan.com',
  [ChainId.BSC_TESTNET]: 'bscscan.com',
  [ChainId.POLYGON_MAINNET]: 'polygonscan.com',
  [ChainId.POLYGON_MUMBAI]: 'polygonscan.com'
}

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[ChainId.MAINNET]}${ETHERSCAN_SUFFIXS[
    chainId
  ] || ETHERSCAN_SUFFIXS[ChainId.MAINNET]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function getNetworkName(chainId: ChainId) {
  return [ChainId.BSC_MAINNET, ChainId.BSC_TESTNET].includes(chainId)
    ? 'Bscscan'
    : [ChainId.POLYGON_MAINNET, ChainId.POLYGON_MUMBAI].includes(chainId)
    ? 'PolygonScan'
    : 'Etherscan'
}
