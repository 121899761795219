import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'

import ERC20_ABI from '../utils/lib/abi/erc20.json'
import PET_EGG_NFT_ABI from '../utils/lib/abi/petEggNFT.json'
import PET_IDO_ABI from '../utils/lib/abi/petIdo.json'
import PET_ABI from '../utils/lib/abi/petToken.json'
import WEAPON_MASTER_ABI from '../utils/lib/abi/weaponMaster.json'
import WEAPON_NFT_ABI from '../utils/lib/abi/weaponNFT.json'
import WEAPON_NFT_MASTER_ABI from '../utils/lib/abi/weaponNftMaster.json'
import WEAPON_ABI from '../utils/lib/abi/weaponToken.json'
import EQUIPPED_WEAPON_ABI from '../utils/lib/abi/equippedWeapon.json'
import PET_NFT_MASTER_ABI from '../utils/lib/abi/petNftMaster.json'
import PET_NFT_ABI from '../utils/lib/abi/petNFT.json'
import BAKERY_SWAP_ROUTER_ABI from '../utils/lib/abi/bakerySwapRouter.json'
import FRUIT_ABI from '../utils/lib/abi/fruitToken.json'
import SIEGE_ABI from '../utils/lib/abi/siege.json'
import EQUIPPED_HELMET_ABI from '../utils/lib/abi/equippedHelmet.json'
import HELMET_NFT_ABI from '../utils/lib/abi/helmetNFT.json'
import ARENA_CHALLENGE_ABI from '../utils/lib/abi/arenaChallenge.json'


// utils
import { getContract } from '../utils'

// hooks
import { useActiveWeb3React } from './index'
import {CONTRACT_ADDRESSES} from "../constants";

// todo
export declare enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97
}

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function usePetContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.pet[chainId as number], PET_ABI, withSignerIfPossible)
}

export function usePetEggNftContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.petEggNft[chainId as number], PET_EGG_NFT_ABI, withSignerIfPossible)
}

export function usePetIdoContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.petIdo[chainId as number], PET_IDO_ABI, withSignerIfPossible)
}

export function useWeaponMasterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.weaponMaster[chainId as number], WEAPON_MASTER_ABI, withSignerIfPossible)
}

export function useWeaponNftContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.weaponNft[chainId as number], WEAPON_NFT_ABI, withSignerIfPossible)
}

export function useWeaponNftMasterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.weaponNftMaster[chainId as number], WEAPON_NFT_MASTER_ABI, withSignerIfPossible)
}

export function useWeaponContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.weapon[chainId as number], WEAPON_ABI, withSignerIfPossible)
}

export function usePetNftContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.petNft[chainId as number], PET_NFT_ABI, withSignerIfPossible)
}

export function usePetNftMasterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.petNftMaster[chainId as number], PET_NFT_MASTER_ABI, withSignerIfPossible)
}

export function useEquippedWeaponContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.equippedWeapon[chainId as number], EQUIPPED_WEAPON_ABI, withSignerIfPossible)
}

export function useBakerySwapRouterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.bakerySwapRouter[chainId as number], BAKERY_SWAP_ROUTER_ABI, withSignerIfPossible)
}

export function useFruitTokenContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.fruitToken[chainId as number], FRUIT_ABI, withSignerIfPossible)
}

export function useSiegeContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.siege[chainId as number], SIEGE_ABI, withSignerIfPossible)
}

export function useHelmetNftContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.helmetNft[chainId as number], HELMET_NFT_ABI, withSignerIfPossible)
}

export function useEquippedHelmetContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.equippedHelmet[chainId as number], EQUIPPED_HELMET_ABI, withSignerIfPossible)
}

export function useArenaChallengeContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(CONTRACT_ADDRESSES.arenaChallenge[chainId as number], ARENA_CHALLENGE_ABI, withSignerIfPossible)
}
