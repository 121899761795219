import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

// utils
import { upgrade } from '../../utils/lib/utils'
import { CONTRACT_ADDRESSES } from '../../constants'

// hooks
import { useWeaponNftMasterContract } from '../useContract'
import { useActiveWeb3React } from '../'

const useWeaponUpgrade = () => {
  const { chainId } = useActiveWeb3React()
  const weaponNftMaster = useWeaponNftMasterContract()
  const handleUpgrade = useCallback(async (tokenName: string, tokenId: number | string, amount: string) => {
    if (upgrade && chainId && weaponNftMaster) {
      try {
        const tx = await upgrade(
          weaponNftMaster,
          CONTRACT_ADDRESSES[tokenName][chainId],
          BigNumber.from(tokenId),
          BigNumber.from(amount).mul(BigNumber.from(10).pow(8))
        )
        return tx
      } catch (e) {
        console.log('useWeaponUpgrade', e)
        return false
      }
    } else {
      return false
    }
  }, [chainId, weaponNftMaster])

  return {upgrade: handleUpgrade}
}

export default useWeaponUpgrade