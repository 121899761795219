import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'

// utils
import { CONTRACT_ADDRESSES } from '../../constants'

// components
import { PageBody, PageTitle, PageDes } from '../../components/views'
import Loading from '../../components/Loader/Loading'
import NoData from '../../components/views/NoData'
import Earning from './components/Earning'
import Weapon, { WeaponProps } from './components/Weapon'

// hooks
import { useActiveWeb3React } from '../../hooks'
import useAllMyWeapons from '../../hooks/weapons/useAllMyWeapons'
import { useWeaponNftContract } from '../../hooks/useContract'
import useAllowanceForAll from '../../hooks/useAllowanceForAll'

const Wrapper = styled.div`
  width: 892px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}
`
const WeaponList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 109px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0px;
  `}
`

export default function MyWeapons() {
  const { account, chainId } = useActiveWeb3React()
  const [weapons, setWeapons] = useState<WeaponProps[]>([])
  const [allStakingPower, setAllStakingPower] = useState<BigNumber>(new BigNumber(0))

  const [loadingFlag, setLoadingFlag] = useState(false)
  const fetchAllMyWeapons = useAllMyWeapons()
  const getAllMyWeapons = useCallback(async (loading?: boolean) => {
    if (!account) return
    if (loading) {
      setLoadingFlag(true)
    }
    const list = await fetchAllMyWeapons()
    let stakingPower = new BigNumber(0)
    const weaponList: WeaponProps[] = list.map((d) => {
      if (d.staking) {
        stakingPower = stakingPower.plus(d.stakingPower.toString())
      }
      return {
        tokenId: d.tokenId.toString(),
        weaponType: d.weaponType.toNumber(),
        level: d.level.toNumber(),
        stakingPower: new BigNumber(d.stakingPower.toString()),
        increaseBattlePowerPercent: new BigNumber(d.increaseBattlePowerPercent.toString()),
        petToGet: new BigNumber(d.petToGet.toString()).div(new BigNumber(10).pow(18)),
        bakeToGet: new BigNumber(d.bakeToGet.toString()).div(new BigNumber(10).pow(18)),
        staking: d.staking ? d.staking : false
      }
    })
    setAllStakingPower(stakingPower)
    setWeapons(weaponList)
    setLoadingFlag(false)
  }, [account, fetchAllMyWeapons])
  useEffect(() => {
    getAllMyWeapons(true)
  }, [getAllMyWeapons])
  // Refresh weapons
  const refreshMyWeapons = useCallback(() => {
    getAllMyWeapons()
  }, [getAllMyWeapons])

  // NFT staking allowance
  const weaponNft = useWeaponNftContract()
  const weaponMasterAddr = chainId ? CONTRACT_ADDRESSES.weaponMaster[chainId] : ''
  const allowance = useAllowanceForAll({
    erc721: weaponNft,
    to: weaponMasterAddr,
    requested: true
  })

  return (
    <PageBody>
      <Loading open={loadingFlag} />
      <PageTitle>My Weapons</PageTitle>
      <PageDes>Your weapons are here!</PageDes>
      {
        !account || weapons.length === 0 ? loadingFlag ? null : (
          <NoData
            title="You don't own any weapon"
            // content="Let’s get your battle pets some legendray weapons!"
            imageUrl="/images/decoration/empty_weapons.svg"
            margin="183px 0 129px"
            // btn={{
            //   url: '/forge-weapons',
            //   name: 'Forge Weapons'
            // }}
          />
        ) : (
          <Wrapper>
            <Earning
              allStakingPower={allStakingPower}
              refreshMyWeapons={refreshMyWeapons}
            />
            <WeaponList>
              {
                weapons.map((d) => (
                  <Weapon
                    key={d.tokenId}
                    weapon={d}
                    allowance={allowance}
                    refreshMyWeapons={refreshMyWeapons}
                  />
                ))
              }
            </WeaponList>
          </Wrapper>
        )
      }
    </PageBody>
  )
}
