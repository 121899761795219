import React from 'react'
import styled from 'styled-components'

// components
import Loader from './'

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
`

export default function Loading ({open}: {open: boolean}) {
  return (
    <>
      {
        open && <LoadingWrapper>
          <Loader size="48px" stroke="white" />
        </LoadingWrapper>
      }
    </>
  )
}
