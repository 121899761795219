import { BigNumber } from 'bignumber.js'
import { PetType } from './'

import { OptionProps } from '../components/views/InputSwap'

export const TOKENSLIST: OptionProps[] = [{
  symbol: 'PET',
  name: 'pet'
}, {
  symbol: 'BAKE',
  name: 'bakery'
}, {
  symbol: 'WEAPON',
  name: 'weapon'
}]

export const MINSTAKINGPOWER = 500
export const MAXSTAKINGPOWER = 1000000
export const RATIO = 1
export const DECOMPOSERATIO = 0.9

export interface WeaponProps {
  tokenId: string
  weaponType: number
  level: number
  stakingPower: BigNumber
  increaseBattlePowerPercent: BigNumber
  petToGet: BigNumber
  bakeToGet: BigNumber
  staking?: boolean
}

export const WEAPONNAME = {
  [PetType.kitten]: 'Sword',
  [PetType.puppy]: 'Chewy Bone',
  [PetType.bunny]: 'Excalibur'
}

export const HELMETNAME: {[key:number]: string} = {
  0: 'Xmas Hat A',
  1: 'Xmas Hat B',
  2: 'Xmas Hat C'
}
