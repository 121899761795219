import React, { useState } from 'react'
import styled from 'styled-components'

// utils
import { randomColor } from '../../../utils/func'

interface WrapperProps {
  size?: number
}
const active = 'ACTIVEEQUIPMENT'
const Wrapper = styled.div.attrs({
  active
})<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) =>  theme.bg3};
  box-shadow: 0px 0px 8px rgba(113, 113, 113, 0.1);
  border-radius: 24px;
  width: ${({ size }) =>  size}px;
  height: ${({ size }) =>  size}px;
  overflow: hidden;
  user-select: none;

  &.${active} {
    background: ${({ theme }) =>  theme.bg4};
    border: 3px solid ${({ theme }) =>  theme.border2};
    box-shadow: 0px 0px 6px rgba(235, 169, 0, 0.9);
    .name {
      color: ${({ theme }) =>  theme.text3};
    }
    .level {
      background: ${({ theme }) =>  theme.bg4};
      color: ${({ theme }) =>  theme.text3};
    }
  }
`
const EquipmentBox = styled.div<{background: string}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background};
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 5px center;
  background-size: 42px auto;
  width: 100%;
`
const EquipmentImg = styled.img<{imageSize?: number}>`
  width: auto;
  height: ${({ imageSize }) => imageSize ? imageSize : 60}px;
`
const Name = styled.div`
  color: ${({ theme }) =>  theme.text2};
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
`
const Level = styled.div`
  position: absolute;
  top: 6px;
  left: 0px;
  background: ${({ theme }) =>  theme.bg3};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding: 0 12px;
`

interface EquipmentPreviewProps extends WrapperProps {
  active?: boolean
  showLevel?: boolean
  level?: number
  image: string
  imageSize?: number
  name: string
  selectEquipment?: () => void
}
export default function EquipmentPreview({
  size = 94,
  active,
  showLevel,
  level,
  image,
  imageSize,
  name,
  selectEquipment
}: EquipmentPreviewProps) {
  const [cardBg] = useState(randomColor())

  return (
    <Wrapper
      onClick={selectEquipment}
      size={size}
      className={active ? 'ACTIVEEQUIPMENT' : ''}
    >
      {showLevel && level !== undefined && <Level className="level">Lv {level}</Level>}
      <EquipmentBox
        background={cardBg}
      >
        <EquipmentImg src={image} imageSize={imageSize} alt="equipment"/>
      </EquipmentBox>
      <Name className="name">{name}</Name>
    </Wrapper>
  )
}
