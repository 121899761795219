import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { BigNumber } from 'bignumber.js'

// utils
import { MyPetProps } from '../../../constants/pets'
import { CONTRACT_ADDRESSES } from '../../../constants'

// components
import Modal from '../../../components/Modal'
import { RowCenter } from '../../../components/Row'
import SelectPet from './SelectPet'
import Battle, { bossVsPetProps } from './Battle'

// hooks
import {
  ChallengerProps,
  useArenaStake
} from '../../../hooks/arena'
import { useActiveWeb3React } from '../../../hooks'
import useAllowanceForAll from '../../../hooks/useAllowanceForAll'
import useApproveForAll from '../../../hooks/useApproveForAll'
import { usePetNftContract } from '../../../hooks/useContract'
import {
  useTransactionAdder,
  useIsTransactionPending
} from '../../../state/transactions/hooks'
import useInterval from '../../../hooks/useInterval'

const TitleImg = styled.img`
  position: absolute;
  top: -30px;
  left: 50%;
  width: auto;
  height: 88px;
  transform: translate(-50%, -29px);
  z-index: 1;
`
const TitleNum = styled(RowCenter)`
  position: absolute;
  top: 0;
  left: 50%;
  background-color: ${({ theme }) => theme.bg4};
  border-radius: 50%;
  color: ${({ theme }) => theme.text3};
  font-size: 34px;
  font-weight: bold;
  width: 58px;
  height: 58px;
  transform: translate(-50%, -50%);
  z-index: 1;
`

interface ChallengeModalProps {
  boss?: ChallengerProps
  onDismiss?: () => void
  myPets: MyPetProps[]
  update: () => void
}

export default function ChallengeModal({
  boss,
  onDismiss = () => {},
  myPets,
  update
}: ChallengeModalProps) {
  const { chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  // Allowance
  const petNftContract = usePetNftContract()
  const arenaChallengeAddr = chainId ? CONTRACT_ADDRESSES.arenaChallenge[chainId] : ''
  const allowance = useAllowanceForAll({
    erc721: petNftContract,
    to: arenaChallengeAddr,
    requested: true
  })
  const [requestedApproval, setRequestedApproval] = useState(false)
  const approve = useApproveForAll(petNftContract, arenaChallengeAddr)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await approve.onApproveAll()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [approve, setRequestedApproval])

  // Stake pet on Arena
  const [arenaStakeHash, setArenaStakeHash] = useState('')
  const arenaStakePending = useIsTransactionPending(arenaStakeHash)
  const [requestedArenaStake, setRequestedArenaStake] = useState(false)
  // reset request
  useEffect(() => {
    if (arenaStakeHash && !arenaStakePending) {
      setRequestedArenaStake(false)
      setArenaStakeHash('')
      update()
      // close Challenge Modal
      onDismiss()
    }
  }, [arenaStakeHash, arenaStakePending, update, onDismiss])
  // Stake
  const { onArenaStake } = useArenaStake()
  const handleArenaStake = useCallback(async (arenaId, tokenId: string | number) => {
    try {
      setRequestedArenaStake(true)
      const response = await onArenaStake(arenaId, tokenId)
      if (response) {
        addTransaction(response, {
          summary: 'Stake pet!'
        })
        setArenaStakeHash(response ? response.hash : '')
      } else {
        setRequestedArenaStake(false)
      }
    } catch (e) {
      console.log('handleArenaStake', e)
      setRequestedArenaStake(false)
    }
  }, [onArenaStake, addTransaction])

  const [battleStatus, setBattleStatus] = useState<number>(0)
  const [bossVsPet, setBossVsPet] = useState<bossVsPetProps>([])

  // Battle
  const [arenaBattleHash, setArenaBattleHash] = useState('')
  const arenaBattlePending = useIsTransactionPending(arenaBattleHash)
  const [requestedBattle, setRequestedBattle] = useState(false)
  const [battleOver, setBattleOver] = useState(false)
  const [challengerInfo, setChallengerInfo] = useState<ChallengerProps | undefined>()
  const [battleNum, setBattleNum] = useState(0)
  // reset request
  useEffect(() => {
    if (arenaBattleHash && !arenaBattlePending) {
      setRequestedBattle(false)
      setArenaBattleHash('')
      setBattleOver(true)
    }
  }, [arenaBattleHash, arenaBattlePending, setBattleOver])
  useEffect(() => {
    if (!battleOver) return
    if (
      boss?.pet &&
      challengerInfo?.pet &&
      (
        !(new BigNumber(boss.pet.id).eq(challengerInfo.pet.id)) ||
        boss.challengeTimes.gt(challengerInfo.challengeTimes.plus(battleNum))
      )
    ) {
      // console.log('id', battleNum, `${boss.pet.id}: ${boss.challengeTimes.toString()}`, `${challengerInfo.pet.id}: ${challengerInfo.challengeTimes.toString()}`)
      setBattleOver(false)
      setBattleStatus(new BigNumber(boss.pet.id).eq(challengerInfo.pet.id) ? 2 : 1)
      setBattleNum(battleNum + 1)
    }
  }, [battleOver, boss, challengerInfo, battleNum])
  useInterval(update, battleOver ? 1000 : null)

  const onCloseBattle = useCallback(() => {
    onDismiss()
    setBattleStatus(0)
    setRequestedBattle(false)
    setBossVsPet([])
    setChallengerInfo(undefined)
    setBattleOver(false)
    setBattleNum(0)
  }, [onDismiss])

  const onClose = useCallback(() => {
    if (requestedArenaStake || requestedBattle) return
    onCloseBattle()
  }, [onCloseBattle, requestedArenaStake, requestedBattle])

  const handleBattle = useCallback(async () => {
    if (bossVsPet.length === 0) return
    setChallengerInfo(bossVsPet[0])
    try {
      setRequestedBattle(true)
      const response = await onArenaStake(bossVsPet[0].arenaId, bossVsPet[1].id)
      if (response) {
        addTransaction(response, {
          summary: `#${bossVsPet[0].pet?.id} VS #${bossVsPet[1].id}!`
        })
        setArenaBattleHash(response ? response.hash : '')
      } else {
        console.log('Failed')
        onCloseBattle()
      }
    } catch (e) {
      console.log('handleBattle', e)
      onCloseBattle()
    }
  }, [onArenaStake, addTransaction, bossVsPet, onCloseBattle])

  const tryAgain = useCallback(() => {
    handleBattle()
    setBattleStatus(0)
  }, [handleBattle])
  useEffect(() => {
    if (bossVsPet.length > 0) {
      tryAgain()
    }
  }, [bossVsPet, tryAgain])

  const selectVs = useCallback((pet: MyPetProps, boss?: ChallengerProps) => {
    if (boss?.pet) {
      // Challenge
      setBossVsPet([boss, pet])
    } else {
      // Stake
      handleArenaStake(boss?.arenaId, pet.id)
    }
  }, [handleArenaStake])

  return (
    <Modal
      isOpen={!!boss}
      onDismiss={onClose}
      maxWidth={430}
      maxHeight={90}
      borderRadius={30}
      borderSize={isMobile ? 0 : 2}
      borderColor="#EBA900"
      overflow="initial"
    >
      {
        boss ?
          boss.arenaId < 4
            ? <TitleImg src={`/images/arena/rank${boss.arenaId}.svg`} alt='rank' />
            : <TitleNum>{boss.arenaId}</TitleNum>
        : null
      }
      {
        bossVsPet.length === 0
          ? (
            <SelectPet
              boss={boss}
              pets={myPets}
              allowance={allowance}
              requestedApproval={requestedApproval}
              handleApprove={handleApprove}
              selectVs={selectVs}
              requestedArenaStake={requestedArenaStake}
            />
          )
          : <Battle
              battleStatus={battleStatus}
              bossVsPet={bossVsPet}
              onDismiss={onClose}
              requestedBattle={requestedBattle}
              tryAgain={tryAgain}
            />
      }
    </Modal>
  )
}