import { useCallback } from 'react'
import { BigNumber } from 'bignumber.js'
import { BigNumber as EBigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'

// utils
import { PET_BASE_URL } from '../../constants/pets'
import { compare, getPetImageUrl } from '../../utils/func'
import { CONTRACT_ADDRESSES, BNB } from '../../constants'
import { CHARGE_STAMINA_BLOCKS } from '../../constants/zootopia'
import {
  getBalancePetNfts,
  getUserPets,
  getHelmetInfo,
  getWeaponInfo,
  hatchPet,
  buyPet,
  buyPetByBnb,
  equippedWeapon,
  feedPet,
  feedPetByBnb,
  siegePetWins,
  equippedHelmet
} from '../../utils/lib/utils'

// hooks
import { useActiveWeb3React } from '..'
import {
  usePetNftContract,
  usePetEggNftContract,
  useWeaponNftContract,
  usePetNftMasterContract,
  useEquippedWeaponContract,
  useSiegeContract,
  useHelmetNftContract,
  useEquippedHelmetContract
} from '../useContract'
import { useBlockNumber } from '../../state/application/hooks'

export const ParsingPet = async (
  d: any,
  helmetNft: Contract | null,
  weaponNft: Contract | null,
  siegeContract: Contract | null,
  latestBlockNumber?: number,
  victory?: boolean
) => {
  const helmet = d.helmet.gt(0) && helmetNft ? await getHelmetInfo(helmetNft, d.helmet) : undefined
  const weapon = d.weapon.gt(0) && weaponNft ? await getWeaponInfo(weaponNft, d.weapon) : undefined
  const petUrl = getPetImageUrl({
    petType: d.petType.toNumber(),
    weaponType: weapon ? weapon.weaponType.toNumber() : 0,
    weaponLevel: weapon ? weapon.level.toNumber() : 0,
    helmetType: helmet ? helmet.helmetType.toNumber() + 1 : 0,
    helmetLevel: helmet ? helmet.level.toNumber() : 0
  })
  const wins = victory && siegeContract ? await siegePetWins(siegeContract, d.tokenId) : []
  return {
    type: 'PET',
    id: d.tokenId.toString(),
    petType: d.petType.toNumber(),
    battlePower: new BigNumber(d.battlePower.toString()).integerValue(BigNumber.ROUND_FLOOR).toNumber(),
    name: d.name,
    petUrl: process.env.REACT_APP_PETS_URL + PET_BASE_URL + petUrl,
    petToGet: d.petToGet
      .add(helmet ? helmet.petToGet : 0)
      .add(weapon ? weapon.petToGet : 0)
      .div(EBigNumber.from(10).pow(18)).toString(),
    bakeToGet: d.bakeToGet
      .add(helmet ? helmet.bakeToGet : 0)
      .add(weapon ? weapon.bakeToGet : 0)
      .div(EBigNumber.from(10).pow(18)).toString(),
    experience: d.experience.toString(), // 当前体力值 +  (当前区块号 - extraUintA)/1200 * 10
    stamina: d.stamina.gte(100) || !(latestBlockNumber && new BigNumber(latestBlockNumber).gt(0))
      ? d.stamina.toString()
      : BigNumber.max(0, BigNumber.min(
          100,
          new BigNumber(d.stamina.toString()).plus(
            new BigNumber(latestBlockNumber)
              .minus(d.extraUintA.toString())
              .div(CHARGE_STAMINA_BLOCKS)
              .times(10)
              .integerValue(BigNumber.ROUND_FLOOR)
              .toString()
          )
        )).toString(),
    weapon: weapon ? {
      tokenId: weapon.tokenId.toString(),
      weaponType: weapon.weaponType.toNumber(),
      level: weapon.level.toNumber(),
      stakingPower: new BigNumber(weapon.stakingPower.toString()),
      increaseBattlePowerPercent: new BigNumber(weapon.increaseBattlePowerPercent.toString()),
      petToGet: new BigNumber(weapon.petToGet.toString()).div(new BigNumber(10).pow(18)),
      bakeToGet: new BigNumber(weapon.bakeToGet.toString()).div(new BigNumber(10).pow(18))
    } : undefined,
    shield: d.shield.toString(),
    helmet: helmet ? {
      tokenId: helmet.tokenId.toString(),
      weaponType: helmet.helmetType.toNumber(),
      level: helmet.level.toNumber(),
      stakingPower: new BigNumber(helmet.stakingPower.toString()),
      increaseBattlePowerPercent: new BigNumber(0),
      petToGet: new BigNumber(helmet.petToGet.toString()).div(new BigNumber(10).pow(18)),
      bakeToGet: new BigNumber(helmet.bakeToGet.toString()).div(new BigNumber(10).pow(18))
    } : undefined,
    armour: d.armour.toString(),
    staking: d.staking ? true : false,
    wins: wins.filter((d) => d.isWin).map((d) => d.caveId.toNumber()),
    extraUintA: d.extraUintA.toString(),
    extraUintB: d.extraUintB.toString(),
    extraUintC: d.extraUintC.toString(),
    extraUintD: d.extraUintD.toString(),
    extraUintE: d.extraUintE.toString(),
    extraUintF: d.extraUintF.toString(),
    extraUintG: d.extraUintG.toString(),
    extraUintH: d.extraUintH.toString(),
    extraStringA: d.extraStringA,
    extraStringB: d.extraStringB,
    extraStringC: d.extraStringC,
  }
}

// get all pets and eggs
export const useAllPets = ({
  onlyPets,
  victory
}: {
  onlyPets?: boolean
  victory?: boolean
}) => {
  const { account } = useActiveWeb3React()
  const petNft = usePetNftContract()
  const petEggNft = usePetEggNftContract()
  const helmetNft = useHelmetNftContract()
  const weaponNft = useWeaponNftContract()
  const siegeContract = useSiegeContract()
  const latestBlockNumber = useBlockNumber()

  const fetchAllPets = useCallback(async () => {
    if (petNft && petEggNft && account && siegeContract) {
      try {
        const pets = await getBalancePetNfts(petNft, account)
        const eggs = onlyPets ? [] : await getUserPets(petEggNft, account)
        const petsList = await Promise.all(
          pets.map(async (d) => {
            const pet = await ParsingPet(
              d,
              helmetNft,
              weaponNft,
              siegeContract,
              latestBlockNumber,
              victory
            )
            return pet
          })
        )
        const eggsList = eggs.map((d) => {
          return {
            type: 'EGG',
            id: d.tokenId.toString(),
            petType: d.petType.toNumber(),
            battlePower: new BigNumber(d.battlePower.toString()).integerValue(BigNumber.ROUND_FLOOR).toNumber()
          }
        })
        return [
          ...petsList.sort(compare('id')),
          ...eggsList.sort(compare('id'))
        ]
      } catch (e) {
        console.log('getUserPets', e)
        return []
      }
    }
    return []
  }, [petNft, petEggNft, account, helmetNft, weaponNft, onlyPets, siegeContract, victory, latestBlockNumber])

  return fetchAllPets
}

// Hatch pets
export const useHatch = () => {
  const petNftMaster = usePetNftMasterContract()
  const handleHatch = useCallback(async (tokenId: string, name: string) => {
    if (petNftMaster) {
      try {
        const tx = await hatchPet(petNftMaster, EBigNumber.from(tokenId), name)
        return tx
      } catch (e) {
        console.log('useHatch', e)
        return false
      }
    } else {
      return false
    }
  }, [petNftMaster])

  return {hatch: handleHatch}
}

// Buy Pets
export const useBuyPets = () => {
  const { chainId } = useActiveWeb3React()
  const petNftMaster = usePetNftMasterContract()
  const handleBuyPets = useCallback(async (
    tokenName: string,
    petType: number,
    name:string,
    amount: string
  ) => {
    if (buyPet && chainId && petNftMaster) {
      try {
        const tx = tokenName === BNB
          ? await buyPetByBnb(
            petNftMaster,
            EBigNumber.from(petType),
            name,
            EBigNumber.from(amount).mul(EBigNumber.from(10).pow(8))
          ) : await buyPet(
            petNftMaster,
            CONTRACT_ADDRESSES[tokenName][chainId],
            EBigNumber.from(petType),
            name,
            EBigNumber.from(amount).mul(EBigNumber.from(10).pow(8))
          )
        return tx
      } catch (e) {
        console.log('useBuyPets', e)
        return false
      }
    } else {
      return false
    }
  }, [chainId, petNftMaster])

  return {buyPets: handleBuyPets}
}

// Equipped Weapon
export const useEquippedWeapon = () => {
  const equippedWeaponContract = useEquippedWeaponContract()
  const handleEquippedWeapon = useCallback(async (
    petNftTokenId: string | number,
    weaponNftTokenId: string | number
  ) => {
    if (equippedWeaponContract) {
      try {
        const tx = await equippedWeapon(
          equippedWeaponContract,
          EBigNumber.from(petNftTokenId),
          EBigNumber.from(weaponNftTokenId)
        )
        return tx
      } catch (e) {
        console.log('useEquippedWeapon', e)
        return false
      }
    } else {
      return false
    }
  }, [equippedWeaponContract])

  return {equippedWeapon: handleEquippedWeapon}
}

// Feed Pets
export const useFeedPets = () => {
  const { chainId } = useActiveWeb3React()
  const petNftMaster = usePetNftMasterContract()
  const handleFeedPets = useCallback(async (
    tokenName: string,
    petTokenId: number | string,
    amount: string
  ) => {
    if (feedPet && chainId && petNftMaster) {
      try {
        const tx = tokenName === BNB
          ? await feedPetByBnb(
            petNftMaster,
            EBigNumber.from(petTokenId),
            EBigNumber.from(amount).mul(EBigNumber.from(10).pow(8))
          )
          : await feedPet(
            petNftMaster,
            CONTRACT_ADDRESSES[tokenName][chainId],
            EBigNumber.from(petTokenId),
            EBigNumber.from(amount).mul(EBigNumber.from(10).pow(8))
          )
        return tx
      } catch (e) {
        console.log('useFeedPets', e)
        return false
      }
    } else {
      return false
    }
  }, [chainId, petNftMaster])

  return {feedPets: handleFeedPets}
}

// Equipped Helmet
export const useEquippedHelmet = () => {
  const equippedHelmetContract = useEquippedHelmetContract()
  const handleEquippedHelmet = useCallback(async (
    petNftTokenId: string | number,
    helmetNftTokenId: string | number
  ) => {
    if (equippedHelmetContract) {
      try {
        const tx = await equippedHelmet(
          equippedHelmetContract,
          EBigNumber.from(petNftTokenId),
          EBigNumber.from(helmetNftTokenId)
        )
        return tx
      } catch (e) {
        console.log('useEquippedHelmet', e)
        return false
      }
    } else {
      return false
    }
  }, [equippedHelmetContract])

  return {equippedHelmet: handleEquippedHelmet}
}
