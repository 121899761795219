import React from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { ExternalLink } from '../../theme/components'

const NavElement = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 96px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    overflow-x: auto;
    margin: 16px 0;
  `};
`
const activeClassName = 'ACTIVENAV'
const NavItem = styled(NavLink).attrs({
  activeClassName
})`
  position: relative;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  white-space:nowrap;
  margin-right: 40px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Nunito', 'Inter var', sans-serif;
  
  &.${activeClassName} {
    color: ${({ theme }) => theme.text2};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 16px;
  `};
`
const OutLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.text1};
  font-weight: bold;
  text-decoration: none;
  margin-right: 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 16px;
  `};
`

export default function Nav() {
  const location = useLocation()

  return (
    <NavElement>
      {/* <NavItem to={'/initial-offering'} className={location.pathname === '/initial-offering' ? activeClassName : ''}>Initial Offering</NavItem> */}
      <NavItem to={'/siege-battle'} className={location.pathname === '/siege-battle' ? activeClassName : ''}>Siege Battle</NavItem>
      <NavItem to={'/tournament-battle'} className={location.pathname === '/tournament-battle' ? activeClassName : ''}>Tournament Battle</NavItem>
      {/* <NavItem to={'/pet-shop'} className={location.pathname === '/pet-shop' ? activeClassName : ''}>Pet Shop</NavItem> */}
      <NavItem to={'/my-pets'} className={location.pathname === '/my-pets' ? activeClassName : ''}>My Pets</NavItem>
      {/* <NavItem to={'/forge-weapons'} className={location.pathname === '/forge-weapons' ? activeClassName : ''}>Forge Weapons</NavItem> */}
      <NavItem to={'/my-weapons'} className={location.pathname === '/my-weapons' ? activeClassName : ''}>My Weapons</NavItem>
      {/* <OutLink href={'https://battlepets.medium.com/battle-pets-roadmap-424c98058f7a'}>Roadmap</OutLink> */}
      {/* <OutLink href={'https://twitter.com/battle_pets'}>Updates</OutLink> */}
      <OutLink href={'https://twitter.com/HelloPetsLabs'}>Updates</OutLink>
    </NavElement>
  )
}
