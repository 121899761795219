import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

import { stakeWeaponNFT } from '../../utils/lib/utils'

// hooks
import { useWeaponMasterContract } from '../useContract'

const useStakeWeaponNFT = (tokenId: string) => {
  const weaponMaster = useWeaponMasterContract()

  const handleStake = useCallback(async () => {
    try {
      if (weaponMaster && tokenId) {
        const txHash = await stakeWeaponNFT(
          weaponMaster,
          BigNumber.from(tokenId)
        )
        return txHash
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  },
  [tokenId, weaponMaster])

  return { onStakeWeaponNFT: handleStake }
}

export default useStakeWeaponNFT
