import { useCallback } from 'react'

import { unstakeAllWeaponNFT } from '../../utils/lib/utils'

// hooks
import { useWeaponMasterContract } from '../useContract'

const useUnstakeAllWeaponNFT = () => {
  const weaponMaster = useWeaponMasterContract()

  const handleUnstake = useCallback(async () => {
    try {
      if (weaponMaster) {
        const txHash = await unstakeAllWeaponNFT(weaponMaster)
        return txHash
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }, [weaponMaster])

  return { onUnstakeAllWeaponNFT: handleUnstake }
}

export default useUnstakeAllWeaponNFT
