import React, { useCallback } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'
import { Button as RebassButton } from 'rebass/styled-components'

import { BIGNUMBER_FMT } from '../../constants'

// components
import NumericalInput from '../NumericalInput'
import Divider from '../Divider'
import Select, { OptionItem } from '../Select'

// hooks

const InputBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border3};
  border-radius: 10px;
  color: ${({ theme }) => theme.text7};
  padding: 12px 16px;
  height: 56px;
`
const Wrapper = styled(InputBox)<{marginTop?: number, marginBottom?: number}>`
  position: relative;
  width: 100%;
  margin-top: ${({marginTop}) => marginTop ? marginTop : 0}px;
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : 0}px;
`
const Balance = styled.div`
  position: absolute;
  top: -28px;
  right: 0;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 400;
`
const BalanceSpan = styled.span`
  color: ${({ theme }) => theme.text2};
  font-weight: 700;
`

const Min = styled(RebassButton)<{altDisabledStyle?: boolean}>`
  background: ${({ theme }) => theme.bg4};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.text6};
  font-weight: 700;
  font-size: 14px;
  width: 66px;
  height: 32px;
  padding: 0;
  margin-right: 16px;

  &:disabled {
    background-color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.btnBg1 : theme.btnBg1)};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.btnText2 : theme.btnText1)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`
const Max = styled(Min)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

export interface OptionProps {
  symbol: string
  name: string
  swap?: boolean
  disable?: boolean
  feed?: boolean
}

interface InputSwapProps {
  options: OptionProps[]
  baseAsset: OptionProps
  updateBaseAsset: (value: OptionProps) => void
  balance: BigNumber
  value: string | number
  updateValue: (value: string) => void
  marginTop?: number
  marginBottom?: number,
  hideMin?: boolean,
  hideMax?: boolean
  onMin?: () => void
  onMax?: () => void
  disableMin?: boolean
  disableMax?: boolean
}

export default function InputSwap(
  {
    options,
    baseAsset,
    updateBaseAsset,
    balance,
    value,
    updateValue,
    marginTop,
    marginBottom,
    hideMin,
    hideMax,
    onMin = () => {},
    onMax = () => {},
    disableMin,
    disableMax
  }: InputSwapProps
) {
  const onSelect = useCallback((d) => {
    if (baseAsset !== d.symbol) {
      updateBaseAsset(d)
      updateValue('')
    }
  }, [baseAsset, updateBaseAsset, updateValue])

  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      {
        balance.gte(0) && <Balance>Balance: <BalanceSpan>{balance.toFormat(3, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)}</BalanceSpan> {baseAsset.symbol}</Balance>
      }
      <NumericalInput
        fontSize={'22px'}
        fontWeight={700}
        value={value}
        onUserInput={updateValue}
      />
      {!hideMin && <Min disabled={disableMin} onClick={onMin}>Min</Min>}
      {!hideMax && <Max disabled={disableMax} onClick={onMax}>Max</Max>}
      <Divider isHorizontal={false} height={'24px'} marginRight={16} />
      <Select
        selectedValue={baseAsset.symbol}
        height={30}
        top='43px'
        right='-16px'
        minWidth={72}
      >
        {
          options.map((d) => {
            return (
              <OptionItem
                key={d.symbol}
                onClick={() => {
                  onSelect(d)
                }}
              >{d.symbol}</OptionItem>
            )
          })
        }
      </Select>
    </Wrapper>
  )
}
