import { useCallback, useEffect, useState } from 'react'
import { Contract } from '@ethersproject/contracts'
import BigNumber from 'bignumber.js'

// utils
import { getAllowance } from '../utils/lib/utils'

// hooks
import { useActiveWeb3React } from '.'
import useInterval from './useInterval'

const useAllowance = (
  {
    erc20,
    masterContractAddress,
    requested,
    reset
  }: {
    erc20?: Contract | null,
    masterContractAddress?: string,
    requested?: boolean,
    reset?: boolean
  }
) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const {account} = useActiveWeb3React()

  const fetchAllowance = useCallback(async () => {
    if (account && erc20 && masterContractAddress) {
      const allowance = await getAllowance(
        account,
        erc20,
        masterContractAddress,
      )
      setAllowance(new BigNumber(allowance.toString()))
    }
  }, [account, erc20, masterContractAddress])

  useEffect(() => {
    if (account && erc20 && masterContractAddress) {
      fetchAllowance()
    }
  }, [account, erc20, masterContractAddress, fetchAllowance])

  useInterval(fetchAllowance, reset || (requested && !allowance.toNumber()) ? 3000 : null)

  return allowance
}

export default useAllowance
