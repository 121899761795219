import { useCallback } from 'react'
import { BigNumber } from 'bignumber.js'
import { BigNumber as EBigNumber } from '@ethersproject/bignumber'

// utils
import {
  siegeBattle,
  siegePetIsWinCaveBoss,
  siegeGetStakingPetNfts,
  siegeStake,
  siegeUnstake,
  siegeHarvest,
  siegeUnstakeAll,
  siegePendingFruit
} from '../../utils/lib/utils'
import { ParsingPet } from '../pets'
import { compare } from '../../utils/func'

// hooks
import { useActiveWeb3React } from '..'
import {
  useSiegeContract,
  usePetNftContract,
  useHelmetNftContract,
  useWeaponNftContract
} from '../useContract'
import { useBlockNumber } from '../../state/application/hooks'

// Challenge boss
export const useSiegeBattle = () => {
  const siegeContract = useSiegeContract()
  const handleBattle = useCallback(async (caveId: string | number, tokenId: string | number) => {
    if (siegeContract) {
      try {
        const tx = await siegeBattle(
          siegeContract,
          EBigNumber.from(caveId),
          EBigNumber.from(tokenId)
        )
        return tx
      } catch (e) {
        console.log('useSiegeBattle', e)
        return false
      }
    } else {
      return false
    }
  }, [siegeContract])

  return { battle: handleBattle }
}

// siegePetIsWinCaveBoss
export const useSiegeBattleIsWin = () => {
  const siegeContract = useSiegeContract()
  const handleBattleIsWin = useCallback(async (caveId: string | number, tokenId: string | number) => {
    if (siegeContract) {
      try {
        const res = await siegePetIsWinCaveBoss(
          siegeContract,
          EBigNumber.from(caveId),
          EBigNumber.from(tokenId)
        )
        return {
          isWin: res.isWin,
          times: new BigNumber(res.times.toString())
        }
      } catch (e) {
        console.log('useSiegeBattle', e)
        return undefined
      }
    } else {
      return undefined
    }
  }, [siegeContract])

  return { battleIsWin: handleBattleIsWin }
}

// Fetch cave staking pets
export const useCaveStakingPets = (caveId: string | number) => {
  const { account } = useActiveWeb3React()
  const siegeContract = useSiegeContract()
  const petNft = usePetNftContract()
  const helmetNft = useHelmetNftContract()
  const weaponNft = useWeaponNftContract()
  const latestBlockNumber = useBlockNumber()

  const fetchCaveStakingPets = useCallback(async () => {
    if (siegeContract && petNft && account) {
      try {
        const caveInfos = await siegeGetStakingPetNfts(
          siegeContract,
          petNft,
          EBigNumber.from(caveId),
          account
        )
        const stakingPetNFTs = await Promise.all(
          caveInfos.stakingPetNFTs.map(async (d) => {
            const pet = await ParsingPet(
              d,
              helmetNft,
              weaponNft,
              siegeContract,
              latestBlockNumber,
              false
            )
            return pet
          })
        )
        return {
          amount: new BigNumber(caveInfos.amount.toString()),
          rewardDebt: new BigNumber(caveInfos.rewardDebt.toString()),
          stakingPetNFTs: stakingPetNFTs.sort(compare('id'))
        }
      } catch (e) {
        console.log('fetchStakingPets', e)
      }
    }
    return null
  }, [siegeContract, petNft, helmetNft, weaponNft, latestBlockNumber, caveId, account])

  return fetchCaveStakingPets
}

// siegeStake
export const useSiegeStake = () => {
  const siegeContract = useSiegeContract()
  const handleSiegeStake = useCallback(async (caveId, tokenId: string | number) => {
    try {
      if (siegeContract) {
        const tx = await siegeStake(
          siegeContract,
          EBigNumber.from(caveId),
          EBigNumber.from(tokenId)
        )
        return tx
      }
    } catch (e) {
      console.log('useSiegeStake', e)
    }
    return false
  }, [siegeContract])

  return { onSiegeStake: handleSiegeStake }
}

// siegeUnstake
export const useSiegeUnStake = () => {
  const siegeContract = useSiegeContract()
  const handleSiegeUnStake = useCallback(async (caveId, tokenId: string | number) => {
    try {
      if (siegeContract) {
        const tx = await siegeUnstake(
          siegeContract,
          EBigNumber.from(caveId),
          EBigNumber.from(tokenId)
        )
        return tx
      }
    } catch (e) {
      console.log('useSiegeUnStake', e)
    }
    return false
  }, [siegeContract])

  return { onSiegeUnStake: handleSiegeUnStake }
}

// siegeHarvest
export const useSiegeHarvest = () => {
  const siegeContract = useSiegeContract()
  const handleSiegeHarvest = useCallback(async (caveId: string | number) => {
    try {
      if (siegeContract) {
        const tx = await siegeHarvest(
          siegeContract,
          EBigNumber.from(caveId)
        )
        return tx
      }
    } catch (e) {
      console.log('useSiegeHarvest', e)
    }
    return false
  }, [siegeContract])

  return { onSiegeHarvest: handleSiegeHarvest }
}

// siegeUnstakeAll
export const useSiegeUnstakeAll = () => {
  const siegeContract = useSiegeContract()
  const handleSiegeUnstakeAll = useCallback(async (caveId: string | number) => {
    try {
      if (siegeContract) {
        const tx = await siegeUnstakeAll(
          siegeContract,
          EBigNumber.from(caveId)
        )
        return tx
      }
    } catch (e) {
      console.log('useSiegeHarvest', e)
    }
    return false
  }, [siegeContract])

  return { onSiegeUnstakeAll: handleSiegeUnstakeAll }
}

// siegePendingFruit
export const useSiegePendingFruit = (caveId?: number | string) => {
  const { account } = useActiveWeb3React()
  const siegeContract = useSiegeContract()

  const fetchHarvest = useCallback(async () => {
    if (siegeContract && account && caveId) {
      try {
        const fruit = await siegePendingFruit(siegeContract, EBigNumber.from(caveId), account)
        return new BigNumber(fruit.toString())
      } catch (e) {
        console.log('fetchHarvest', e)
      }
    }
    return new BigNumber(0)
  }, [caveId, account, siegeContract])

  return fetchHarvest
}
