import React from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

interface PaginateWrapperProps {
  marginTop?: number
  marginBottom?: number
  align?: string
}
const PaginateWrapper = styled.div<PaginateWrapperProps>`
  width: 100%;
  ul {
    display: flex;
    align-items: center;
    justify-content: ${({ align }) => align ? align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center' : 'center'};
    margin-top: ${({ marginTop }) => marginTop ? marginTop : 0}px;
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : 0}px;
    padding: 0;
    li {
      border-radius: 50%;
      color: ${({ theme }) => theme.text4};
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      width: 24px;
      height: 24px;
      list-style-type: none;
      margin: 0 4px;
      user-select: none;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        outline: none;
      }
    }
    .active {
      background: ${({ theme }) => theme.bg4};
      color: ${({ theme }) => theme.text3};
    }
    .previous, .next {
      width: 34px;
      height: 34px;
    }
    .previous img {
      transform: rotate(180deg);
    }
    .previous.disabled img {
      transform: rotate(0deg);
    }
    .next.disabled img {
      transform: rotate(180deg);
    }
  }
`

interface PaginateProps {
  pageCount: number
  onPageChange: (d: any) =>  void
  initialPage: number
  marginPagesDisplayed?: number
  pageRangeDisplayed?: number
  marginTop?: number
  marginBottom?: number
  align?: string
}

export default function Paginate({
  pageCount,
  onPageChange,
  initialPage,
  marginPagesDisplayed = 1,
  pageRangeDisplayed = 5,
  marginTop,
  marginBottom,
  align
}: PaginateProps) {
  return (
    <PaginateWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      align={align}
    >
      <ReactPaginate
        previousLabel={
          <img src={`/images/decoration/cat_paw${initialPage <= 0 ? '_disable' : ''}.svg`} alt="icon"/>
        }
        nextLabel={
          <img src={`/images/decoration/cat_paw${initialPage >= pageCount - 1 ? '_disable' : ''}.svg`} alt="icon"/>
        }
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={onPageChange}
        initialPage={initialPage}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </PaginateWrapper>
  )
}
