import { useCallback } from "react"
import { getBalanceWeaponNfts, getStakingWeaponNfts } from '../../utils/lib/utils'
import { compare } from '../../utils/func'

// hooks
import { useActiveWeb3React } from '../'
import { useWeaponNftContract, useWeaponMasterContract } from '../useContract'

const useAllMyWeapons = () => {
  const { account } = useActiveWeb3React()
  const weaponNft = useWeaponNftContract()
  const weaponMaster = useWeaponMasterContract()
  const fetchAllMyWeapons = useCallback(async (onlyMyWeapons?: boolean) => {
    if (account && weaponNft && weaponMaster) {
      try {
        const myWeapons = await getBalanceWeaponNfts(weaponNft, account)
        const myStakingWeapons = onlyMyWeapons ? [] : await getStakingWeaponNfts(weaponMaster, weaponNft, account)
        // Deduplication
        const stakingSet = new Set()
        myStakingWeapons.forEach((d) => {
          stakingSet.add(d.tokenId)
        })
        return [
          ...myStakingWeapons.map((d) => {
            return {
              ...d,
              staking: true
            }
          }),
          ...myWeapons.filter((d) => !stakingSet.has(d.tokenId))
        ].sort(compare('tokenId'))
      } catch (e) {
        console.log('fetchAllMyWeapons', e)
      }
    }
    return []
  }, [account, weaponNft, weaponMaster])

  return fetchAllMyWeapons
}

export default useAllMyWeapons