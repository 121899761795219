import { MyPetProps } from './pets'
export interface CaveProps {
  cave: number
  x: number
  y: number
  width: number
  height: number
  onSelect?: () => void
}

export interface BossProps extends CaveProps {
  name: string
  boss: string
  battlePower: number
  url: string
}

export type bossVsPetProps = [BossProps , MyPetProps] | []
export const MIN_STAMINA = 20
export const CHARGE_STAMINA_BLOCKS = 1200

export const CaveList: BossProps[] = [{
  cave: 1,
  name: "Noon's Hole Guardian",
  boss: "Noon's Hole Guardian",
  x: 9,
  y: 77,
  width: 120,
  height: 117,
  battlePower: 100,
  url: 'boss1.png'
}, {
  cave: 2,
  name: 'Cross Cave Guardian',
  boss: 'Cross Cave Guardian',
  x: 291,
  y: 40,
  width: 120,
  height: 106,
  battlePower: 500,
  url: 'boss2.png'
}, {
  cave: 3,
  name: 'Red Lake Cave Guardian',
  boss: 'Red Lake Cave Guardian',
  x: 454,
  y: 159,
  width: 142,
  height: 77,
  battlePower: 1000,
  url: 'boss3.png'
}, {
  cave: 4,
  name: 'Blue Cave Guardian',
  boss: 'Blue Cave Guardian',
  x: 375,
  y: 196,
  width: 105,
  height: 106,
  battlePower: 5000,
  url: 'boss4.png'
}, {
  cave: 5,
  name: 'Rising Star Cave Guardian',
  boss: 'Rising Star Cave Guardian',
  x: 536,
  y: 219,
  width: 160,
  height: 121,
  battlePower: 10000,
  url: 'boss5.png'
}, {
  cave: 6,
  name: 'Easter Cave Guardian',
  boss: 'Easter Cave Guardian',
  x: 705,
  y: 186,
  width: 121,
  height: 91,
  battlePower: 50000,
  url: 'boss6.png'
}, {
  cave: 7,
  name: 'Spy Cave Guardian',
  boss: 'Spy Cave Guardian',
  x: 716,
  y: 302,
  width: 157,
  height: 111,
  battlePower: 100000,
  url: 'boss7.png'
}, {
  cave: 8,
  name: "Bing Cave Guardian",
  boss: "Bing Cave Guardian",
  x: 346,
  y: 333,
  width: 102,
  height: 79,
  battlePower: 200000,
  url: 'boss8.png'
}, {
  cave: 9,
  name: 'Deer Cave Guardian',
  boss: 'Deer Cave Guardian',
  x: 169,
  y: 388,
  width: 181,
  height: 132,
  battlePower: 300000,
  url: 'boss9.png'
}, {
  cave: 10,
  name: 'Cave Bath Guardian',
  boss: 'Cave Bath Guardian',
  x: 37,
  y: 399,
  width: 124,
  height: 121,
  battlePower: 500000,
  url: 'boss10.png'
}, {
  cave: 11,
  name: "Bears' Cave Guardian",
  boss: "Bears' Cave Guardian",
  x: 609,
  y: 474,
  width: 157,
  height: 111,
  battlePower: 700000,
  url: 'boss11.png'
}, {
  cave: 12,
  name: 'Cave of Dogs Guardian',
  boss: 'Cave of Dogs Guardian',
  x: 873,
  y: 403,
  width: 150,
  height: 144,
  battlePower: 1000000,
  url: 'boss12.png'
}, {
  cave: 13,
  name: "Sheep’s Cave Guardian",
  boss: "Sheep’s Cave Guardian",
  x: 826,
  y: 561,
  width: 271,
  height: 204,
  battlePower: 1500000,
  url: 'boss13.png'
}, {
  cave: 14,
  name: 'Apollo 11 Cave Guardian',
  boss: 'Apollo 11 Cave Guardian',
  x: 980,
  y: 247,
  width: 159,
  height: 122,
  battlePower: 2000000,
  url: 'boss14.png'
}, {
  cave: 15,
  name: 'Unicorn Cave Guardian',
  boss: 'Unicorn Cave Guardian',
  x: 1099,
  y: 78,
  width: 142,
  height: 108,
  battlePower: 3000000,
  url: 'boss15.png'
}, {
  cave: 16,
  name: "Cave of the lakes Guardian",
  boss: "Cave of the lakes Guardian",
  x: 980,
  y: 141,
  width: 179,
  height: 100,
  battlePower: 5000000,
  url: 'boss16.png'
}, {
  cave: 17,
  name: 'Anna Cave Guardian',
  boss: 'Anna Cave Guardian',
  x: 681,
  y: 96,
  width: 117,
  height: 81,
  battlePower: 7000000,
  url: 'boss17.png'
}, {
  cave: 18,
  name: 'Cave of Horror Guardian',
  boss: 'Cave of Horror Guardian',
  x: 493,
  y: 402,
  width: 155,
  height: 98,
  battlePower: 10000000,
  url: 'boss18.png'
}, {
  cave: 19,
  name: 'Cave of Beasts Guardian',
  boss: 'Cave of Beasts Guardian',
  x: 1033,
  y: 485,
  width: 159,
  height: 119,
  battlePower: 15000000,
  url: 'boss19.png'
}, {
  cave: 20,
  name: "Dragon's Breath Cave Guardian",
  boss: "Dragon's Breath Cave Guardian",
  x: 1140,
  y: 555,
  width: 216,
  height: 157,
  battlePower: 20000000,
  url: 'boss20.png'
}]
