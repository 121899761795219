import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import IconArrowBottom from '../../assets/svg/icon_arrow_bottom.svg'

interface SelectProps {
  children?: React.ReactNode
  selectedValue?: string
  height?: number
  top?: string
  right?: string
  bottom?: string
  left?: string
  minWidth?: number
  maxHeight?: number
  showList?: boolean
}

const SelectWrapper = styled.div<SelectProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${({height}) => height ? height : 32}px;
  user-select: none;
`
const HideInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
`
const SelectedItem = styled.div`
  color: ${({theme}) => theme.text1};
  font-size: 14px;
  font-weight: 700;
`
const IconArrow = styled.img`
  margin-left: 8px;
`
const ListWrapper = styled.div<SelectProps>`
  position: absolute;
  top: ${({top, bottom}) => top ? top : bottom ? 'initial' : '100%'};
  bottom: ${({top, bottom}) => top || !bottom ? 'initial' : bottom};
  left: ${({left, right}) => left ? left : right ? 'initial' : '0px'};
  right: ${({left, right}) => left || !right ? 'initial' : right};
  background: ${({theme}) => theme.bg3};
  border-radius: 5px;
  box-shadow: ${({ showList }) => showList ? '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)' : 'none'};
  min-width: ${({ minWidth }) => minWidth ? minWidth : 52}px;
  max-width: 300px;
  max-height: ${({ showList, maxHeight }) => !showList ? 0 : maxHeight ? maxHeight : 300}px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .4s;
  z-index: 2;
`
const UlElement = styled.ul`
  margin: 0;
  padding: 8px 0;
`
export const OptionItem = styled.li`
  color: ${({theme}) => theme.text1};
  font-size: 16px;
  line-height: 24px;
  padding: 6px 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

export default function Select({
  children,
  selectedValue,
  height,
  top,
  right,
  bottom,
  left,
  minWidth,
  maxHeight
}: SelectProps) {
  const [showList, setShowList] = useState(false)
  const onFocus = useCallback(() => {
    setShowList(true)
  }, [])
  const onBlur = useCallback(() => {
    setShowList(false)
  }, [])

  return (
    <SelectWrapper height={height}>
      <SelectedItem>{ selectedValue }</SelectedItem>
      <IconArrow src={IconArrowBottom} alt="icon"/>
      <HideInput onFocus={onFocus} onBlur={onBlur} />
      <ListWrapper
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        minWidth={minWidth}
        maxHeight={maxHeight}
        showList={showList}
      >
        <UlElement>
          { children }
        </UlElement>
      </ListWrapper>
    </SelectWrapper>
  )
}
