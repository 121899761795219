import {Contract, ContractTransaction} from '@ethersproject/contracts'
import {MaxUint256, Zero} from '@ethersproject/constants'
import {BigNumber} from '@ethersproject/bignumber'

export function calculateGasMargin(value: BigNumber): BigNumber {
    return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

export async function getCurrentIdoAmount(petIdo: Contract): Promise<BigNumber> {
    return await petIdo.currentIdoAmount()
}

export async function getMaxTotalIdoAmount(petIdo: Contract): Promise<BigNumber> {
    return await petIdo.maxTotalIdoAmount()
}

export async function getPrice(petIdo: Contract, baseTokenAddress: string, quoteTokenAddress: string): Promise<BigNumber> {
    return await petIdo.getPrice(baseTokenAddress, quoteTokenAddress)
}

export async function ido(petIdo: Contract, amount: BigNumber, petType: number, tokenAddress: string): Promise<ContractTransaction> {
    return await petIdo.ido(amount, petType, tokenAddress)
}

export async function idoBnb(petIdo: Contract, amount: BigNumber, petType: number): Promise<ContractTransaction> {
    return await petIdo.idoBnb(petType, {
        value: amount
    })
}

export async function getUserPets(petEggNft: Contract, account: string): Promise<Array<{
    petType: BigNumber;
    battlePower: BigNumber;
    tokenId: BigNumber;
}>> {
    const length = await petEggNft.balanceOf(account)
    return await Promise.all(
        Array.from(Array(Number(length)).keys()).map(async index => {
            const tokenId = await petEggNft.tokenOfOwnerByIndex(account, `${index}`)
            const egg = await petEggNft.petInfoMap(tokenId)
            return {
                ...egg,
                tokenId
            }
        })
    )
}

export const approve = async (erc20: Contract, to: string): Promise<ContractTransaction> => {
    return await erc20.approve(to, MaxUint256)
}

export const getAllowance = async (
    account: string,
    erc20: Contract,
    to: string
): Promise<BigNumber> => {
    try {
        return await erc20.allowance(account, to)
    } catch (e) {
        return Zero
    }
}

export const setApprovalForAll = async (erc721: Contract, to: string): Promise<ContractTransaction> => {
    return await erc721.setApprovalForAll(to, true)
}

export const isApprovedForAll = async (
    account: string,
    erc721: Contract,
    to: string
): Promise<boolean> => {
    try {
        return await erc721.isApprovedForAll(account, to)
    } catch (e) {
        return false
    }
}
export const WEAPON_NFT_MAX_STAKING_POWER = 1E6;

export async function getPetAmount(weaponNftMaster: Contract, tokenAddress: string, tokenAmount: BigNumber): Promise<BigNumber> {
    return await weaponNftMaster.getPetAmount(tokenAddress, tokenAmount)
}

export async function getTokenAmount(weaponNftMaster: Contract, tokenAddress: string, petAmount: BigNumber): Promise<BigNumber> {
    return await weaponNftMaster.getTokenAmount(tokenAddress, petAmount)
}

export async function synthesis(weaponNftMaster: Contract, tokenAddress: string, weaponType: BigNumber, amount: BigNumber): Promise<ContractTransaction> {
    return await weaponNftMaster.synthesis(tokenAddress, weaponType, amount)
}

export async function upgrade(weaponNftMaster: Contract, tokenAddress: string, tokenId: BigNumber, amount: BigNumber): Promise<ContractTransaction> {
    return await weaponNftMaster.upgrade(tokenAddress, tokenId, amount)
}

export async function decomposition(weaponNft: Contract, tokenId: BigNumber): Promise<ContractTransaction> {
    return await weaponNft.burn(tokenId)
}

export const levelIncreaseBattlePowerPercentMap: { [level: number]: BigNumber } = {
    1: BigNumber.from(1),
    2: BigNumber.from(2),
    3: BigNumber.from(3),
    4: BigNumber.from(4),
    5: BigNumber.from(6),
    6: BigNumber.from(8),
    7: BigNumber.from(10),
    8: BigNumber.from(13),
    9: BigNumber.from(16),
    10: BigNumber.from(20)
};


export function getLevel(stakingPower: number): number {
    let level = 1; // < 1k
    if (stakingPower >= 1E3 && stakingPower < 2E3) {
        level = 2
    } else if (stakingPower >= 2E3 && stakingPower < 5E3) {
        level = 3;
    } else if (stakingPower >= 5E3 && stakingPower < 1E4) {
        level = 4;
    } else if (stakingPower >= 1E4 && stakingPower < 2E4) {
        level = 5;
    } else if (stakingPower >= 2E4 && stakingPower < 5E4) {
        level = 6;
    } else if (stakingPower >= 5E4 && stakingPower < 1E5) {
        level = 7;
    } else if (stakingPower >= 1E5 && stakingPower < 5E5) {
        level = 8;
    } else if (stakingPower >= 5E5 && stakingPower < 1E6) {
        level = 9;
    } else if (stakingPower >= 1E6) {
        level = 10;
    }
    return level
}

export async function getWeaponInfo(weaponNft: Contract, tokenId: BigNumber): Promise<{
    weaponType: BigNumber;
    stakingPower: BigNumber;
    level: BigNumber;
    petToGet: BigNumber;
    bakeToGet: BigNumber;
    increaseBattlePowerPercent: BigNumber;
    tokenId: BigNumber;
}> {
    const weaponNftInfo: {
        weaponType: BigNumber;
        stakingPower: BigNumber;
        level: BigNumber;
        petToGet: BigNumber;
        bakeToGet: BigNumber;
    } = await weaponNft.weaponInfoMap(tokenId)
    const increaseBattlePowerPercent = levelIncreaseBattlePowerPercentMap[weaponNftInfo.level.toNumber()]
    return {
        ...weaponNftInfo,
        increaseBattlePowerPercent,
        tokenId
    }
}

export async function getBalanceWeaponNfts(weaponNft: Contract, account: string): Promise<Array<{
    stakingPower: BigNumber;
    level: BigNumber;
    petToGet: BigNumber;
    bakeToGet: BigNumber;
    increaseBattlePowerPercent: BigNumber;
    tokenId: BigNumber;
    weaponType: BigNumber;
    staking?: boolean;
}>> {
    const length = await weaponNft.balanceOf(account)
    return await Promise.all(
        Array.from(Array(Number(length)).keys()).map(async index => {
            const tokenId = await weaponNft.tokenOfOwnerByIndex(account, `${index}`)
            return await getWeaponInfo(weaponNft, tokenId)
        })
    )
}

export async function pendingWeapon(weaponMaster: Contract, poolAddress: string, account: string): Promise<BigNumber> {
    return await weaponMaster.pendingWeapon(poolAddress, account)
}

export async function stake(weaponMaster: Contract, poolAddress: string, amount: BigNumber): Promise<ContractTransaction> {
    return await weaponMaster.stake(poolAddress, amount)
}

export async function harvest(weaponMaster: Contract, poolAddress: string): Promise<ContractTransaction> {
    return await stake(weaponMaster, poolAddress, Zero)
}

export async function unstake(weaponMaster: Contract, poolAddress: string, amount: BigNumber): Promise<ContractTransaction> {
    return await weaponMaster.unstake(poolAddress, amount)
}

export async function getStaked(weaponMaster: Contract, poolAddress: string, account: string): Promise<{
    amount: BigNumber;
    rewardDebt: BigNumber;
}> {
    return await weaponMaster.poolUserInfoMap(poolAddress, account)
}

export async function stakeWeaponNFT(weaponMaster: Contract, tokenId: BigNumber): Promise<ContractTransaction> {
    return await weaponMaster.stakeWeaponNFT(tokenId)
}

export async function harvestWeaponNFT(weaponMaster: Contract): Promise<ContractTransaction> {
    return await stakeWeaponNFT(weaponMaster, Zero)
}

export async function unstakeWeaponNFT(weaponMaster: Contract, tokenId: BigNumber): Promise<ContractTransaction> {
    return await weaponMaster.unstakeWeaponNFT(tokenId)
}

export async function unstakeAllWeaponNFT(weaponMaster: Contract): Promise<ContractTransaction> {
    return await weaponMaster.unstakeAllWeaponNFT()
}

export async function getStakingWeaponNfts(weaponMaster: Contract, weaponNft: Contract, account: string): Promise<Array<{
    stakingPower: BigNumber;
    level: BigNumber;
    petToGet: BigNumber;
    bakeToGet: BigNumber;
    increaseBattlePowerPercent: BigNumber;
    tokenId: BigNumber;
    weaponType: BigNumber;
    staking?: boolean;
}>> {
    const length = await weaponMaster.getStakingWeaponNftLength(account)
    return await Promise.all(
        Array.from(Array(Number(length)).keys()).map(async index => {
            const tokenId = await weaponMaster.tokenOfWeaponNftStakerByIndex(account, `${index}`)
            return await getWeaponInfo(weaponNft, tokenId)
        })
    )
}

export interface PetNftInfo {
    petType: BigNumber
    name: string
    battlePower: BigNumber
    experience: BigNumber
    stamina: BigNumber
    petToGet: BigNumber
    bakeToGet: BigNumber
    weapon: BigNumber
    shield: BigNumber
    helmet: BigNumber
    armour: BigNumber
    extraUintA: BigNumber
    extraUintB: BigNumber
    extraUintC: BigNumber
    extraUintD: BigNumber
    extraUintE: BigNumber
    extraUintF: BigNumber
    extraUintG: BigNumber
    extraUintH: BigNumber
    extraStringA: string
    extraStringB: string
    extraStringC: string
    tokenId: BigNumber
    staking?: boolean
}

export async function getPetInfo(petNft: Contract, tokenId: BigNumber): Promise<PetNftInfo> {
    const petInfo: {
        0: {
            petType: BigNumber
            name: string
            battlePower: BigNumber
            experience: BigNumber
            stamina: BigNumber
            petToGet: BigNumber
            bakeToGet: BigNumber
        };
        1: {
            weapon: BigNumber
            shield: BigNumber
            helmet: BigNumber
            armour: BigNumber
        };
        2: {
            extraUintA: BigNumber
            extraUintB: BigNumber
            extraUintC: BigNumber
            extraUintD: BigNumber
            extraUintE: BigNumber
            extraUintF: BigNumber
            extraUintG: BigNumber
            extraUintH: BigNumber
            extraStringA: string
            extraStringB: string
            extraStringC: string
        };
    } = await petNft.getPetInfo(tokenId)
    return {
        ...petInfo[0],
        ...petInfo[1],
        ...petInfo[2],
        tokenId
    }
}

export async function getBalancePetNfts(petNft: Contract, account: string): Promise<Array<PetNftInfo>> {
    const length = await petNft.balanceOf(account)
    return await Promise.all(
        Array.from(Array(Number(length)).keys()).map(async index => {
            const tokenId = await petNft.tokenOfOwnerByIndex(account, `${index}`)
            return await getPetInfo(petNft, tokenId)
        })
    )
}

export async function hatchPet(petNftMaster: Contract, petEggTokenId: BigNumber, name = ''): Promise<ContractTransaction> {
    return await petNftMaster.hatch(petEggTokenId, name)
}

export async function buyPet(petNftMaster: Contract, token: string, petType: BigNumber, name = '', amount: BigNumber): Promise<ContractTransaction> {
    return await petNftMaster.buy(token, petType, name, amount)
}

export async function buyPetByBnb(petNftMaster: Contract, petType: BigNumber, name = '', amount: BigNumber): Promise<ContractTransaction> {
    return await petNftMaster.buyByBnb(petType, name, {
        value: amount
    })
}

export async function feedPet(petNftMaster: Contract, token: string, petTokenId: BigNumber, amount: BigNumber): Promise<ContractTransaction> {
    return await petNftMaster.feed(token, petTokenId, amount)
}

export async function feedPetByBnb(petNftMaster: Contract, petTokenId: BigNumber, amount: BigNumber): Promise<ContractTransaction> {
    return await petNftMaster.feedByBnb(petTokenId, {
        value: amount
    })
}

/**
 * install / uninstall weapon
 * @param equippedWeapon
 * @param petNftTokenId
 * @param weaponNftTokenId == 0? uninstall : install
 */
export async function equippedWeapon(equippedWeapon: Contract, petNftTokenId: BigNumber, weaponNftTokenId: BigNumber): Promise<ContractTransaction> {
    return await equippedWeapon.equipped(petNftTokenId, weaponNftTokenId)
}

export async function getWillBuyAmount(bakerySwapRouterContract: Contract, amountIn: BigNumber, currentAddress: string, targetAddress: string): Promise<BigNumber> {
    const [, willGetAmount]: [BigNumber, BigNumber] = await bakerySwapRouterContract.getAmountsOut(amountIn, [currentAddress, targetAddress])
    return willGetAmount
}

export async function getNeedAmountToBuy(bakerySwapRouterContract: Contract, amountOut: BigNumber, currentAddress: string, targetAddress: string): Promise<BigNumber> {
    const [needAmountToBuy,]: [BigNumber, BigNumber] = await bakerySwapRouterContract.getAmountsIn(amountOut, [currentAddress, targetAddress])
    return needAmountToBuy
}


// siege
export async function siegeBattle(siege: Contract, caveId: BigNumber, tokenId: BigNumber): Promise<ContractTransaction> {
    return await siege.battle(caveId, tokenId)
}

export async function siegePetIsWinCaveBoss(siege: Contract, caveId: BigNumber, tokenId: BigNumber): Promise<{ times: BigNumber; isWin: boolean; }> {
    return await siege.petBattleInfoMap(tokenId, caveId)
}

export async function siegePetWins(siege: Contract, tokenId: BigNumber): Promise<{ caveId: BigNumber; times: BigNumber; isWin: boolean; }[]> {
    const battleInfos = await siege.petBattleInfos(tokenId)
    return battleInfos.battleInfos.map((battleInfo: any, index: number) => ({
        caveId: battleInfos.cavesView[index],
        ...battleInfo,
    }))
}

export async function siegeStake(siege: Contract, caveId: BigNumber, tokenId: BigNumber): Promise<ContractTransaction> {
    return await siege.stake(caveId, tokenId)
}

export async function siegeHarvest(siege: Contract, caveId: BigNumber): Promise<ContractTransaction> {
    return await siegeStake(siege, caveId, Zero)
}

export async function siegePendingFruit(siege: Contract, caveId: BigNumber, userAddress: string): Promise<BigNumber> {
    return await siege.pendingFruit(caveId, userAddress)
}

export async function siegeUnstake(siege: Contract, caveId: BigNumber, tokenId: BigNumber): Promise<ContractTransaction> {
    return await siege.unstake(caveId, tokenId)
}

export async function siegeUnstakeAll(siege: Contract, caveId: BigNumber): Promise<ContractTransaction> {
    return await siege.unstakeAll(caveId)
}

export async function siegeGetStakingPetNfts(siege: Contract, petNft: Contract, caveId: BigNumber, account: string): Promise<{
    amount: BigNumber;
    rewardDebt: BigNumber;
    stakingPetNFTs: Array<PetNftInfo>;
}> {
    const caveUserInfo: {
        amount: BigNumber;
        rewardDebt: BigNumber;
        stakingPetNFTs: BigNumber[];
    } = await siege.getCaveUserInfo(caveId, account)
    const stakingPetNFTs = await Promise.all(
        caveUserInfo.stakingPetNFTs.map(async tokenId => {
            const petInfo = await getPetInfo(petNft, tokenId)
            petInfo.staking = true
            return petInfo
        })
    )
    return {
        amount: caveUserInfo.amount,
        rewardDebt: caveUserInfo.rewardDebt,
        stakingPetNFTs: stakingPetNFTs
    }
}

export async function getHelmetInfo(helmetNft: Contract, tokenId: BigNumber): Promise<{
    helmetType: BigNumber;
    stakingPower: BigNumber;
    level: BigNumber;
    petToGet: BigNumber;
    bakeToGet: BigNumber;
    tokenId: BigNumber;
}> {
    const helmetNftInfo: {
        helmetType: BigNumber;
        stakingPower: BigNumber;
        level: BigNumber;
        petToGet: BigNumber;
        bakeToGet: BigNumber;
    } = await helmetNft.helmetInfoMap(tokenId)
    return {
        ...helmetNftInfo,
        tokenId
    }
}

export async function getBalanceHelmetNfts(helmetNft: Contract, account: string): Promise<Array<{
    stakingPower: BigNumber;
    level: BigNumber;
    petToGet: BigNumber;
    bakeToGet: BigNumber;
    tokenId: BigNumber;
    helmetType: BigNumber;
    staking?: boolean;
}>> {
    const length = await helmetNft.balanceOf(account)
    return await Promise.all(
        Array.from(Array(Number(length)).keys()).map(async index => {
            const tokenId = await helmetNft.tokenOfOwnerByIndex(account, `${index}`)
            return await getHelmetInfo(helmetNft, tokenId)
        })
    )
}

/**
 * install / uninstall weapon
 * @param equippedHelmet
 * @param petNftTokenId
 * @param helmetNftTokenId == 0? uninstall : install
 */
export async function equippedHelmet(equippedHelmet: Contract, petNftTokenId: BigNumber, helmetNftTokenId: BigNumber): Promise<ContractTransaction> {
    return await equippedHelmet.equipped(petNftTokenId, helmetNftTokenId)
}

// arenaChallenge
export async function arenaChallengeGetArenaInfos(arenaChallenge: Contract): Promise<{
    arenaId: BigNumber
    pendingFruit: BigNumber
    allocPoint: BigNumber
    lastRewardBlock: BigNumber
    totalRewardDebt: BigNumber
    challenger: BigNumber
    challengeTimes: BigNumber
    challengeRewardDebt: BigNumber
    challengeTotalRewardDebt: BigNumber
    challengerOwner: string;
}[]> {
    const arenaInfos: {
        arenas: BigNumber[]
        arenaInfos: {
            allocPoint: BigNumber
            lastRewardBlock: BigNumber
            totalRewardDebt: BigNumber
            challenger: BigNumber
            challengeTimes: BigNumber
            challengeRewardDebt: BigNumber
            challengeTotalRewardDebt: BigNumber
            challengerOwner: string
        }[]
        pendingFruits: BigNumber[]
    } = await arenaChallenge.getArenaInfos()
    const infos = arenaInfos.arenaInfos.map((arenaInfo, index) => ({
        arenaId: arenaInfos.arenas[index],
        pendingFruit: arenaInfos.pendingFruits[index],
        ...arenaInfo,
    }))
    // const info = infos[8]
    // console.log(
    //     `getArenaInfos ${Date.now()} arenaId: ${info.arenaId}, challenger: ${info.challenger}, challengeTimes: ${info.challengeTimes}, challengeRewardDebt: ${info.challengeRewardDebt}, challengeTotalRewardDebt: ${info.challengeTotalRewardDebt}, challengerOwner: ${info.challengerOwner}`
    // )
    return infos
}

export async function arenaFruitPerBlock(arenaChallenge: Contract): Promise<BigNumber> {
    return await arenaChallenge.fruitPerBlock()
}

export async function arenaChallengeStake(arenaChallenge: Contract, arenaId: BigNumber, tokenId: BigNumber): Promise<ContractTransaction> {
    const estimatedGas = await arenaChallenge.estimateGas.stake(arenaId, tokenId)
    return await arenaChallenge.stake(arenaId, tokenId, {
        gasLimit: calculateGasMargin(estimatedGas)
    })
}

export async function arenaChallengeHarvest(arenaChallenge: Contract, arenaId: BigNumber): Promise<ContractTransaction> {
    const estimatedGas = await arenaChallenge.estimateGas.harvest(arenaId)
    return await arenaChallenge.harvest(arenaId, {
        gasLimit: calculateGasMargin(estimatedGas)
    })
}

export async function arenaChallengePendingFruit(arenaChallenge: Contract, arenaId: BigNumber): Promise<BigNumber> {
    return await arenaChallenge.pendingFruit(arenaId)
}

export async function arenaChallengeUnstake(arenaChallenge: Contract, arenaId: BigNumber): Promise<ContractTransaction> {
    const estimatedGas = await arenaChallenge.estimateGas.unstake(arenaId)
    return await arenaChallenge.unstake(arenaId, {
        gasLimit: calculateGasMargin(estimatedGas)
    })
}
