import React from 'react'
import styled from 'styled-components'
// import SectionOne from './components/SectionOne'
// import SectionTwo from './components/SectionTwo'
// import SectionThree from './components/SectionThree'
// import SectionFour from './components/SectionFour'
// import SectionFive from './components/SectionFive'
// import SectionSix from './components/SectionSix'
import HelloPets from './components/HelloPets'

const HomeWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export default function Home () {
  return (
    <HomeWrapper>
      {/* <SectionOne></SectionOne>
      <SectionTwo></SectionTwo>
      <SectionThree></SectionThree>
      <SectionFour></SectionFour>
      <SectionFive></SectionFive>
      <SectionSix></SectionSix> */}
      <HelloPets></HelloPets>
    </HomeWrapper> 
  )
}
