import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { BigNumber } from 'bignumber.js'
import { Text } from 'rebass'

// utils
import { TOKENSLIST, SWAPTOKENS, NONESWAPTOKENS, PETS, RATIO, MINSTAKINGPOWER, DECOMPOSERATIO } from '../../constants/pets'
import { randomColor } from '../../utils/func'
import { BIGNUMBER_FMT, CONTRACT_ADDRESSES, petMap, BNB } from '../../constants'

// components
import { PageBody, PageTitle, PageDes } from '../../components/views'
import InputSwap, { OptionProps } from '../../components/views/InputSwap'
import SelectPet, { PetProps } from '../../components/views/SelectPet'
import { ButtonPrimary } from '../../components/Button'
import { RowCenter, RowBetween } from '../../components/Row'
import ProgressBar from '../../components/views/ProgressBar'
import InlinePending from '../../components/Loader/InlinePending'

// hooks
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import useRate from '../../hooks/useRate'
import usePET from '../../hooks/usePET'
import { useTokenContract } from '../../hooks/useContract'
import useAllowance from '../../hooks/useAllowance'
import useApprove from '../../hooks/useApprove'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'
import { useBuyPets } from '../../hooks/pets'

const Box = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 40px;
  width: 636px;
  padding: 40px 64px 32px;
  margin: 40px 0 110px;
  box-shadow: ${({ theme }) => theme.darkMode ? 'none' : '0px 2px 10px rgba(168, 121, 0, 0.08)' };

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 20px;
    width: 100%;
    margin: 24px auto;
    padding: 16px;
  `}
`
const InputTitle = styled.div`
  color: ${({ theme }) => theme.text4};
  font-size: 14px;
  font-weight: 700;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `}
`
const PetsDetail = styled.div<{background: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) =>  background};
  border-radius: 40px;
  color: ${({ theme }) =>  theme.text4};
  padding: 32px 76px 28px;
  margin: 40px 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    border-radius: 20px;
    padding: 24px;
  `}
`
const DetailLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 116px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `}
`
const PetWrapper = styled.div`
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 110px auto;
`
const PetImg = styled.img`
  width: 110px;
  height: auto;
`
const DetailRight = styled.div`
  width: 210px;
`
const TokenDecompose = styled.div`
  font-size: 14px;
  margin-top: 28px;
  & > div {
    div:first-child {
      color: ${({ theme }) =>  theme.text1};
    }
  }
`

export default function BuyPets() {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const addTransaction = useTransactionAdder()
  const [petsDetailBg] = useState(randomColor())

  // Input Box
  const [baseAsset, setBaseAsset] = useState(TOKENSLIST[0])
  const updateBaseAsset = useCallback((value: OptionProps) => {
    setBaseAsset(value)
  }, [setBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.name)

  const [value, setValue] = useState<string | number>('')
  const updateValue = useCallback((value) => {
    const decimal = value && /\./.test(value) ? value.split('.')[1] : ''
    setValue(balance.lt(value) ? balance.toFixed(3, BigNumber.ROUND_FLOOR) : decimal && decimal.length > 3 ? new BigNumber(value).toFixed(3, BigNumber.ROUND_FLOOR) : value)
  }, [setValue, balance])

  // pet
  const [pet, setPet] = useState<PetProps>(PETS.filter(d => d.defaultChecked)[0])
  const onSelectPet = useCallback((e) => {
    setPet(PETS.filter(d => d.name === e.target.id)[0])
  }, [setPet])

  // Rate
  const [rate, setRate] = useState(new BigNumber(1))
  const tokenRate = useRate({
    baseAsset: baseAsset.name,
    quoteAsset: 'pet',
    exclude: [...SWAPTOKENS]
  })
  useEffect(() => {
    setRate(tokenRate.gt(0) ? tokenRate : new BigNumber(1))
  }, [setRate, tokenRate])

  // swap
  const petAmount = usePET({
    amount: new BigNumber(value).times(new BigNumber(10).pow(8)).toString(),
    baseAsset: baseAsset.name,
    exclude: [...NONESWAPTOKENS]
  })
  const minAmount = usePET({
    amount: new BigNumber(MINSTAKINGPOWER).times(new BigNumber(10).pow(8)).toString(),
    baseAsset: baseAsset.name,
    exclude: [...NONESWAPTOKENS],
    isExactOut: true
  })

  const onMin = useCallback(() => {
    try {
      const min = SWAPTOKENS.includes(baseAsset.name)
        ? minAmount.times(RATIO).toFixed(3, BigNumber.ROUND_CEIL)
        : new BigNumber(MINSTAKINGPOWER).times(RATIO).div(rate).toFixed(0, BigNumber.ROUND_CEIL)
      setValue(min)
    } catch(e) {
      console.log('------>>')
    }
  }, [baseAsset, rate, minAmount, setValue])

  // Staking Power
  const [stakingPower, setStakingPower] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    const power = SWAPTOKENS.includes(baseAsset.name)
      ? petAmount.div(RATIO).integerValue(BigNumber.ROUND_FLOOR)
      : new BigNumber(value).times(rate).div(RATIO).integerValue(BigNumber.ROUND_FLOOR)
    setStakingPower(value ? power : new BigNumber(0))
  }, [baseAsset, value, rate, petAmount, setStakingPower])

  // Allowance
  const [requestedApproval, setRequestedApproval] = useState(false)
  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[baseAsset.name][chainId] : undefined)
  const allowance = useAllowance({
    erc20,
    masterContractAddress: chainId ? CONTRACT_ADDRESSES['petNftMaster'][chainId] : undefined,
    requested: requestedApproval
  })
  const { onApprove } = useApprove(erc20, chainId ? CONTRACT_ADDRESSES['petNftMaster'][chainId] : undefined)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])
  useEffect(() => {
    setRequestedApproval(false)
  }, [baseAsset])

  // buy pets
  const [buyPetsHash, setBuyPetsHash] = useState('')
  const buyPetsPending = useIsTransactionPending(buyPetsHash)
  const [requestedBuyPets, setRequestedBuyPets] = useState(false)
  // reset request
  useEffect(() => {
    if (buyPetsHash && !buyPetsPending) {
      setRequestedBuyPets(false)
      setBuyPetsHash('')
    }
  }, [buyPetsHash, buyPetsPending])
  // Order
  const { buyPets } = useBuyPets()
  const handleBuyPets = useCallback(async () => {
    try {
      setRequestedBuyPets(true)
      const response = await buyPets(
        baseAsset.name,
        pet.petType,
        petMap.name[pet.petType],
        new BigNumber(value).times(new BigNumber(10).pow(10)).integerValue(BigNumber.ROUND_FLOOR).toString()
      )
      if (response) {
        addTransaction(response, {
          summary: 'Buy pets'
        })
        setBuyPetsHash(response ? response.hash : '')
      } else {
        setRequestedBuyPets(false)
      }
    } catch (e) {
      console.log('handleBuyPets', e)
      setRequestedBuyPets(false)
    }
  }, [baseAsset, pet, value, buyPets, addTransaction])

  return (
    <PageBody>
      <PageTitle>Pet Shop</PageTitle>
      <PageDes>Choose a cute pet to battle for you!</PageDes>
      <Box>
        <InputTitle>Enter the amount you want to spend</InputTitle>
        <InputSwap
          options={TOKENSLIST.filter((d) => !d.disable)}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          marginTop={16}
          marginBottom={40}
          hideMax={true}
          onMin={onMin}
          disableMin={SWAPTOKENS.includes(baseAsset.name) && minAmount.eq(0)}
        />
        <SelectPet
          title="Select the battle pet you love"
          pets={PETS}
          onSelectPet={onSelectPet}
        />
        <PetsDetail
          background={petsDetailBg}
        >
          <DetailLeft>
            <PetWrapper>
              <PetImg src={'/images/pets/' + pet.name.toLowerCase() + '.svg'} alt="pet"/>
            </PetWrapper>
          <Text fontSize={18} fontWeight={700} marginTop={16}>{pet.name}</Text>
          </DetailLeft>
          <DetailRight>
            <ProgressBar
              color="yellow"
              title="Battle Power"
              value={stakingPower.toNumber()}
              progress={new BigNumber(Math.pow(stakingPower.toNumber(), 1 / 3)).div(100)}
              marginBottom={10}
            />
            <ProgressBar
              color="green"
              title="Experience"
              value={'0/100'}
              marginBottom={10}
              progress={new BigNumber(0)}
            />
            <ProgressBar
              color="blue"
              title="Stamina"
              value={'100/100'}
              progress={new BigNumber(1)}
            />
            <TokenDecompose>
              <RowBetween>
                <Text>PET to get after abandon:</Text>
                <Text fontWeight={700}>{stakingPower.gt(0) && baseAsset.name !== 'bakery' ? stakingPower.times(DECOMPOSERATIO).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Text>
              </RowBetween>
              <RowBetween>
                <Text>Bake to get after abandon:</Text>
                <Text fontWeight={700}>{value && baseAsset.name === 'bakery' ? new BigNumber(value).times(DECOMPOSERATIO).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Text>
              </RowBetween>
            </TokenDecompose>
          </DetailRight>
        </PetsDetail>
        {
          false && <RowCenter>
            {
              !account ? (
                <ButtonPrimary
                  onClick={toggleWalletModal}
                  width={'240px'}
                  height={48}
                  padding={'0px'}
                >
                  Connect Wallet
                </ButtonPrimary>
              ) : (
                baseAsset.name !== BNB && !allowance.toNumber() ? (
                  value && +value > 0 ? (
                    <ButtonPrimary
                      onClick={handleApprove}
                      disabled={requestedApproval}
                      width={'240px'}
                      height={48}
                      padding={'0px'}
                    >
                      {
                        requestedApproval ? (
                          <InlinePending text="Approving" />
                        ) : 'Approve ' + baseAsset.symbol
                      }
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      disabled={true}
                      width={'240px'}
                      height={48}
                      padding={'0px'}
                    >
                      Approve {baseAsset.symbol}
                    </ButtonPrimary>
                  )
                ) : (
                  <ButtonPrimary
                    onClick={handleBuyPets}
                    disabled={
                      requestedBuyPets ||
                      !(value && +value > 0) ||
                      balance.lt(value) ||
                      stakingPower.lt(MINSTAKINGPOWER) ||
                      (new BigNumber(value).gt(0) && stakingPower.lte(0))
                    }
                    width={'240px'}
                    height={48}
                    padding={'0px'}
                  >
                    {
                      requestedBuyPets ? (
                        <InlinePending text="Pending" />
                      ) : balance.lt(value)
                        ? 'Insufficient Balance'
                        : stakingPower.lt(MINSTAKINGPOWER)
                          ? 'Min Battle Power 500'
                          : new BigNumber(value).gt(0) && stakingPower.lte(0)
                            ? 'Unavailable'
                            : 'Buy'
                    }
                  </ButtonPrimary>
                )
              )
            }
          </RowCenter>
        }
      </Box>
    </PageBody>
  )
}
