import { OptionProps } from '../components/views/InputSwap'
import { PetProps } from '../components/views/SelectPet'
import { PetType } from './'
import { WeaponProps } from './weapon'

export const ALLTOKENS: OptionProps[] = [{
  symbol: 'PET',
  name: 'pet'
}, {
  symbol: 'BAKE',
  name: 'bakery'
}, {
  symbol: 'BNB',
  name: 'wbnb',
  swap: true
}, {
  symbol: 'USDT',
  name: 'usdt',
  swap: true
}, {
  symbol: 'BUSD',
  name: 'busd',
  swap: true
}, {
  symbol: 'FRUIT',
  name: 'fruitToken',
  swap: true,
  feed: true
}]

export const TOKENSLIST: OptionProps[] = ALLTOKENS.filter((d) => !d.feed)

export const SWAPTOKENS: string[] = ALLTOKENS.filter((d) => !d.disable).filter((d) => d.swap).map((d) => d.name)
export const NONESWAPTOKENS: string[] = ALLTOKENS.filter((d) => !d.disable).filter((d) => !d.swap).map((d) => d.name)

export const PETS: PetProps[] = [{
  name: 'Kitten',
  icon: '/images/pets/avatar/kitten.svg',
  petType: PetType.kitten,
  defaultChecked: true
}, {
  name: 'Puppy',
  icon: '/images/pets/avatar/puppy.svg',
  petType: PetType.puppy
}, {
  name: 'Bunny',
  icon: '/images/pets/avatar/bunny.svg',
  petType: PetType.bunny
}]

export const PET_BASE_URL = '/api/pets/bzemouam/'
export const MINSTAKINGPOWER = 500
export const MAXSTAKINGPOWER = 1000000
export const RATIO = 1
export const DECOMPOSERATIO = 0.9

export interface MyEggProps {
  type: string
  id: string
  petType: number
  battlePower: number
}

export interface MyPetProps {
  type: string
  id: string
  petType: number
  battlePower: number
  name: string
  petUrl: string
  petToGet: string
  bakeToGet: string
  experience: string
  stamina: string
  weapon: undefined | WeaponProps
  shield: string
  helmet: undefined | WeaponProps
  armour: string
  staking: boolean
  wins: number[]
  extraUintA: string
  extraUintB: string
  extraUintC: string
  extraUintD: string
  extraUintE: string
  extraUintF: string
  extraUintG: string
  extraUintH: string
  extraStringA: string
  extraStringB: string
  extraStringC: string
}
