import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
  color: ${({ theme }) => theme.text4};
  font-weight: 700;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    align-items: flex-start;
  `}
`
const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text4};
  cursor: pointer;
  font-weight: 700;
  & > i {
    display: block;
    border: 2px solid ${({ theme }) => theme.border3};
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0px 32px 12px;
    span {
      margin-left: 5px;
    }
  `}
`
const WeaponIcon = styled.img`
  width: auto;
  height: 46px;
  margin: 0 4px 0 0px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 40px;
    height: auto;
  `}
`
const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ i {
    position: relative;
    border-color: ${({ theme }) => theme.border2};
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      background: ${({ theme }) => theme.border2};
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
  &:checked ~ span {
    color: ${({ theme }) => theme.text2};
  }
`
export interface WeaponProps {
  name: string
  icon: string
  weaponType: number
  defaultChecked?: boolean
}
export interface SelectWeaponProps {
  list: WeaponProps[]
  onSelect: (name: React.FormEvent) => void
}

export default function SelectWeapon({list, onSelect}: SelectWeaponProps) {
  return (
    <>
      <Title>Select a weapon to forge</Title>
      <Wrapper>
        {
          list.map((d) => (
            <Label key={d.name} htmlFor={d.name}>
              <Radio
                type="radio"
                id={d.name}
                defaultChecked={d.defaultChecked}
                name='weapons'
                onChange={onSelect}
              />
              <i></i>
              <WeaponIcon src={d.icon} alt="weapon"/>
              <span>{d.name}</span>
            </Label>
          ))
        }
      </Wrapper>
    </>
  )
}
