import React from 'react'

import styled from 'styled-components'
import Column from '../../../components/Column'
import { RowCenter } from '../../../components/Row'

import broadcastSvg from '../../../assets/svg/home/broadcast.svg'
import helloPetsBtn from '../../../assets/svg/home/helloPetsBtn.svg'

const HelloPetsWrapper = styled(Column)`
  align-items: center;
  justify-content: flex-start;
`
const BannerWrapper = styled.div`
  margin: 21px auto 24px;
  width: 85%;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 auto 24px;
    width: 100%;
  `};
`
const Banner = styled.div`
  width: 100%;
  height: 0;
  padding-top: 30%;
  transform-origin: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/images/home/banner.png);
`
const Rebranding = styled(Column)`
  width: 735px;
  height: auto;
  font-family: 'Nunito', 'Inter var', sans-serif;
  font-style: normal;
  color: ${({ theme }) => theme.text11};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `};
`
const Broadcast = styled(RowCenter)`
  width: 100%;
  grid-column-gap: 22px;

  span {
    font-weight: 800;
    font-size: 40px;
    letter-spacing: 0.01em;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-row-gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 30px;
    }
  `};
`
const Introduce = styled.div`
  margin: 40px auto 80px;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  opacity: 0.9;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     margin: 20px auto 40px;
  `};
`
const Link = styled.a`
  display: flex;
  margin: 0 auto;
  width: 286px;
  height: 64px;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  background: ${({ theme }) => theme.bg10};
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     margin: 0 auto 30px;
     height: 50px;
  `};
`

const HelloPets = () => {
  return (
    <>
      <HelloPetsWrapper>
        <BannerWrapper>
          <Banner></Banner>
        </BannerWrapper>
        <Rebranding>
          <Broadcast>
            <img src={broadcastSvg} alt="" />
            <span>Rebranding to Hello Pets</span>
          </Broadcast>
          <Introduce>Battlepets has been rebranded to Hello Pets, which means battlepets.finance is no longer in operation. Welcome to visit the new website hellopets.world.</Introduce>
          <Link href="https://www.hellopets.world/" target="_blank" rel="noreferrer">
            <img src={helloPetsBtn} alt="" />
          </Link>
        </Rebranding>
      </HelloPetsWrapper>
    </>
  )
}
export default HelloPets