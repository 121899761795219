import {useCallback} from 'react'
import {BigNumber} from '@ethersproject/bignumber'

import {decomposition} from '../../utils/lib/utils'

// hooks
import {useWeaponNftContract} from '../useContract'

const useDecomposeNFT = (tokenId: string) => {
  const weaponNft = useWeaponNftContract()

  const handleDecomposeNFT = useCallback(async () => {
    try {
      if (weaponNft && tokenId) {
        return await decomposition(
            weaponNft,
            BigNumber.from(tokenId)
        )
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  },
  [tokenId, weaponNft])

  return { onDecomposeNFT: handleDecomposeNFT }
}

export default useDecomposeNFT
