import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { animated, useSprings } from 'react-spring'
import { isMobile } from 'react-device-detect'

// utils
import { BossProps } from '../../../constants/zootopia'
import { MyPetProps } from '../../../constants/pets'
import { CONTRACT_ADDRESSES } from '../../../constants'

// components
import Modal from '../../../components/Modal'
import Boss from './Boss'
import Stake from './Stake'

// hooks
import { useActiveWeb3React } from '../../../hooks'
import useAllowanceForAll from '../../../hooks/useAllowanceForAll'
import useApproveForAll from '../../../hooks/useApproveForAll'
import { usePetNftContract } from '../../../hooks/useContract'

const TitleImg = styled.img`
  position: absolute;
  top: -5px;
  left: 50%;
  width: auto;
  height: 38px;
  transform: translate(-50%, -50%);
  z-index: 1;
`
const Wrapper = styled.div`
  position: relative;
  border-radius: 27px;
  width: 100%;
  height: 490px;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`
const AnimatedItem = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`
const defaultX: {[key: number]: any} = {
  0: [{x: 0}, {x: 100}],
  1: [{x: -100}, {x: 0}]
}
interface CaveModalProps {
  boss?: BossProps
  pets: MyPetProps[]
  onDismiss?: () => void
  selectVs: (boss: BossProps, pet: MyPetProps) => void
  updatePets: () => void
}
export default function CaveModal({
  boss,
  pets,
  onDismiss = () => {},
  selectVs,
  updatePets
}: CaveModalProps) {
  const { chainId } = useActiveWeb3React()

  const [items, setItems] = useState(defaultX[0])
  const springs = useSprings(items.length, items.map((item: any) => ({ transform: `translate3d(${item.x}%,0,0)` })))
  
  const toggleView = useCallback((i: number) => {
    const index = (i + 1) % 2
    setItems(defaultX[index])
  }, [setItems])

  // Allowance
  const petNftContract = usePetNftContract()
  const siegeAddr = chainId ? CONTRACT_ADDRESSES.siege[chainId] : ''
  const allowance = useAllowanceForAll({
    erc721: petNftContract,
    to: siegeAddr,
    requested: true
  })
  const [requestedApproval, setRequestedApproval] = useState(false)
  const approve = useApproveForAll(petNftContract, siegeAddr)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await approve.onApproveAll()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [approve, setRequestedApproval])

  const onClose = useCallback(() => {
    if (requestedApproval) return
    onDismiss()
  }, [requestedApproval, onDismiss])

  return (
    <Modal
      isOpen={!!boss}
      onDismiss={onClose}
      maxWidth={430}
      maxHeight={90}
      borderRadius={30}
      borderSize={isMobile ? 0 : 2}
      borderColor="#EBA900"
      overflow="initial"
    >
      <TitleImg src={`/images/zootopia/cave_title/cave${boss?.cave}.png`} alt='name' />
      <Wrapper>
        {
          springs.map((props, i) => (
            <AnimatedItem key={i} style={props}>
              {
                i === 0 ? (
                  <Boss
                    boss={boss}
                    pets={pets}
                    allowance={allowance}
                    requestedApproval={requestedApproval}
                    handleApprove={handleApprove}
                    selectVs={selectVs}
                    toggleView={() => {
                      toggleView(i)
                    }}
                  />
                ) : (
                <Stake
                  boss={boss}
                  pets={pets}
                  toggleView={() => {
                    toggleView(i)
                  }}
                  updatePets={updatePets}
                />
              )
              }
            </AnimatedItem>
          ))
        }
      </Wrapper>
    </Modal>
  )
}