import { useCallback, useEffect, useState } from "react"
import { BigNumber } from 'bignumber.js'

// utils
import { pendingWeapon } from '../../utils/lib/utils'
import { CONTRACT_ADDRESSES } from '../../constants'

// hooks
import { useActiveWeb3React } from '../'
import { useWeaponMasterContract } from '../useContract'
import { useBlockNumber } from '../../state/application/hooks'

const useWeaponEarned = () => {
  const [weaponEarned, setWeaponEarned] = useState<BigNumber>(new BigNumber(0))
  const { account, chainId } = useActiveWeb3React()
  const weaponMaster = useWeaponMasterContract()
  const poolAddress = chainId ? CONTRACT_ADDRESSES.weaponNft[chainId] : ''
  const latestBlockNumber = useBlockNumber()

  const fetchWeaponEarned = useCallback(async () => {
    if (account && poolAddress && weaponMaster) {
      try {
        const earned = await pendingWeapon(weaponMaster, poolAddress, account)
        setWeaponEarned(new BigNumber(earned.toString()).div(new BigNumber(10).pow(18)))
      } catch (e) {
        console.log('fetchWeaponEarned', e)
      }
    }
  }, [account, poolAddress, weaponMaster])

  useEffect(() => {
    fetchWeaponEarned()
  }, [latestBlockNumber, fetchWeaponEarned])

  return weaponEarned
}

export default useWeaponEarned