import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { RouteComponentProps } from 'react-router-dom'
import { BigNumber } from 'bignumber.js'

// utils
import { CONTRACT_ADDRESSES, BIGNUMBER_FMT } from '../../constants/index'
import { TOKENSLIST, MAXSTAKINGPOWER, RATIO, DECOMPOSERATIO } from '../../constants/weapon'
import { getLevel } from '../../utils/lib/utils'

// components
import { PageBody } from '../../components/views'
import Loading from '../../components/Loader/Loading'
import { RowBetween, RowCenter } from '../../components/Row'
import WeaponCard from '../../components/views/WeaponCard'
import InputSwap, { OptionProps } from '../../components/views/InputSwap'
import { ButtonPrimary } from '../../components/Button'
import { WeaponProps } from './components/Weapon'
import InlinePending from '../../components/Loader/InlinePending'

// hooks
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import useWeaponInfo from '../../hooks/weapons/useWeaponInfo'
import useRate from '../../hooks/useRate'
import { useTokenContract } from '../../hooks/useContract'
import useAllowance from '../../hooks/useAllowance'
import useApprove from '../../hooks/useApprove'
import useWeaponUpgrade from '../../hooks/weapons/useWeaponUpgrade'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'

const Box = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 40px;
  width: 774px;
  padding: 32px 185px;
  margin: 88px 0 136px;
  box-shadow: ${({ theme }) => theme.darkMode ? 'none' : '0px 2px 10px rgba(168, 121, 0, 0.08)' };

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 20px;
    width: 100%;
    margin: 24px auto;
    padding: 16px;
  `}
`
const Title = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
`
const WeaponWrapper = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `}
`
const Arrow = styled.img`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 16px 0;
    transform: rotate(90deg);
  `}
`
const WeaponLevel = styled.div`
  display: flex;
  justify-content: center;
  justify: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) =>  theme.border1};
  color: ${({ theme }) => theme.text2};
  font-weight: 700;
  height: 40px;
`
const Highlight = styled(WeaponLevel)`
  background: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.text3};
  border: none;
`
const WeaponDetail = styled.div`
  margin: 32px 0 24px;
`
const WeaponPorps = styled(RowBetween)`
  color: ${({ theme }) => theme.text1};
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
const PropsValue = styled(RowBetween)`
  width: auto;
`
const IconArrow = styled.img`
  margin: 0 5px;
`
const UpgradedValue = styled(Text)`
  color: ${({ theme }) => theme.text2};
  text-align: right;
  font-weight: 700;
`

export default function UpgradeWeapon({
  match: {
    params: { weaponId }
  },
  history
}: RouteComponentProps<{weaponId: string}>) {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const addTransaction = useTransactionAdder()
  const [loadingFlag, setLoadingFlag] = useState(false)
  // Fetch weapon info
  const [weapon, setWeapon] = useState<WeaponProps>()
  const fetchWeaponInfo = useWeaponInfo(weaponId)
  const getWeaponInfo = useCallback(async (loading?: boolean) => {
    if (loading) {
      setLoadingFlag(true)
    }
    const info = await fetchWeaponInfo()
    const weaponInfo = info ? {
      tokenId: info.tokenId.toString(),
      weaponType: info.weaponType.toNumber(),
      level: info.level.toNumber(),
      stakingPower: new BigNumber(info.stakingPower.toString()),
      increaseBattlePowerPercent: new BigNumber(info.increaseBattlePowerPercent.toString()),
      petToGet: new BigNumber(info.petToGet.toString()).div(new BigNumber(10).pow(18)),
      bakeToGet: new BigNumber(info.bakeToGet.toString()).div(new BigNumber(10).pow(18))
    } : undefined
    if (weaponInfo && weaponInfo.stakingPower.gt(0)) {
      setWeapon(weaponInfo)
      setLoadingFlag(false)
    } else {
      history.push(`/my-weapons`)
    }
  }, [fetchWeaponInfo, history])
  useEffect(() => {
    getWeaponInfo(true)
  }, [getWeaponInfo])

  // Input Box
  const [baseAsset, setBaseAsset] = useState(TOKENSLIST[0])
  const updateBaseAsset = useCallback((value: OptionProps) => {
    setBaseAsset(value)
  }, [setBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.name)

  const [value, setValue] = useState<string | number>('')
  const updateValue = useCallback((value) => {
    setValue(balance.lt(value) ? balance.toFixed(0, BigNumber.ROUND_FLOOR) : value ? new BigNumber(value).toFixed(0, BigNumber.ROUND_FLOOR) : '')
  }, [setValue, balance])

  // Rate
  const [rate, setRate] = useState(new BigNumber(1))
  const tokenRate = useRate({
    baseAsset: baseAsset.name,
    quoteAsset: 'pet',
    exclude: ['weapon']
  })
  useEffect(() => {
    setRate(tokenRate.gt(0) ? tokenRate : new BigNumber(1))
  }, [setRate, tokenRate])

  const onMax = useCallback(() => {
    const max = new BigNumber(MAXSTAKINGPOWER)
      .minus(weapon ? weapon.stakingPower : 0)
      .times(RATIO)
      .div(rate)
      .integerValue(BigNumber.ROUND_CEIL)
    setValue(BigNumber.min(max, balance).toFixed(0, BigNumber.ROUND_FLOOR))
  }, [setValue, rate, balance, weapon])

  // updated stakingPower
  const [stakingPower, setStakingPower] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    const power = new BigNumber(value).times(rate).div(RATIO).integerValue(BigNumber.ROUND_FLOOR)
    setStakingPower(value && weapon ? weapon.stakingPower.plus(power) : new BigNumber(0))
  }, [value, rate, setStakingPower, weapon])

  // Allowance
  const [requestedApproval, setRequestedApproval] = useState(false)
  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[baseAsset.name][chainId] : undefined)
  const allowance = useAllowance({
    erc20,
    masterContractAddress: chainId ? CONTRACT_ADDRESSES['weaponNftMaster'][chainId] : undefined,
    requested: requestedApproval
  })
  const { onApprove } = useApprove(erc20, chainId ? CONTRACT_ADDRESSES['weaponNftMaster'][chainId] : undefined)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])
  useEffect(() => {
    setRequestedApproval(false)
  }, [baseAsset])

  // Upgrade
  const [upgradeHash, setUpgradeHash] = useState('')
  const upgradePending = useIsTransactionPending(upgradeHash)
  const [requestedUpgrade, setRequestedUpgrade] = useState(false)
  // reset request
  useEffect(() => {
    if (upgradeHash && !upgradePending) {
      setRequestedUpgrade(false)
      setUpgradeHash('')
      setValue('')
      getWeaponInfo()
    }
  }, [upgradeHash, upgradePending, getWeaponInfo])
  // Order
  const { upgrade } = useWeaponUpgrade()
  const handleUpgrade = useCallback(async () => {
    try {
      setRequestedUpgrade(true)
      const response = await upgrade(
        baseAsset.name,
        weaponId,
        new BigNumber(value).times(new BigNumber(10).pow(10)).integerValue(BigNumber.ROUND_FLOOR).toString()
      )
      if (response) {
        addTransaction(response, {
          summary: 'Upgrade weapon'
        })
        setUpgradeHash(response ? response.hash : '')
      } else {
        setRequestedUpgrade(false)
      }
    } catch (e) {
      console.log('handleUpgrade', e)
      setRequestedUpgrade(false)
    }
  }, [baseAsset, value, upgrade, addTransaction, weaponId])

  return (
    <PageBody>
      <Loading open={loadingFlag} />
      <Box>
        <Title>Upgrade</Title>
        <WeaponWrapper>
          <WeaponCard
            width={174}
            unknownLevel={!weapon}
            weaponType={weapon ? weapon.weaponType : 1}
            level={weapon ? weapon.level : 1}
          >
            <WeaponLevel>
              Lv {weapon ? weapon.level : '?'}
            </WeaponLevel>
          </WeaponCard>
          <Arrow src="/images/exchange_arrow.svg" alt=""/>
          <WeaponCard
            width={174}
            weaponType={weapon ? weapon.weaponType : 1}
            unknownLevel={!(weapon && new BigNumber(value).gt(0))}
            level={getLevel(stakingPower.toNumber())}
            borderWidth={3}
            borderColor="#EBA900"
            boxShadow="0px 0px 8px #EBA900"
          >
            <Highlight>
              Lv {!(weapon && new BigNumber(value).gt(0)) ? '?' : getLevel(stakingPower.toNumber())}
            </Highlight>
          </WeaponCard>
        </WeaponWrapper>
        <WeaponDetail>
          <WeaponPorps>
            <Text>Staking Power:</Text>
            <PropsValue>
              <Text minWidth={60} maxWidth={100}>{weapon ? weapon.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
              <UpgradedValue minWidth={60} maxWidth={100}>{ value ? stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</UpgradedValue>
            </PropsValue>
          </WeaponPorps>
          <WeaponPorps>
            <Text>PET to get when melt:</Text>
            <PropsValue>
              <Text minWidth={60} maxWidth={100}>{weapon ? weapon.petToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
              <UpgradedValue minWidth={60} maxWidth={100}>{
                value && baseAsset.name === 'pet'
                  ? new BigNumber(value)
                    .times(DECOMPOSERATIO)
                    .plus(weapon ? weapon.petToGet : 0)
                    .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  : value && weapon ? weapon.petToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'
              }</UpgradedValue>
            </PropsValue>
          </WeaponPorps>
          <WeaponPorps>
            <Text>Bake to get when melt:</Text>
            <PropsValue>
              <Text minWidth={60} maxWidth={100}>{weapon ? weapon.bakeToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
              <UpgradedValue minWidth={60} maxWidth={100}>{
                value && baseAsset.name === 'bakery'
                  ? new BigNumber(value)
                    .times(DECOMPOSERATIO)
                    .plus(weapon ? weapon.bakeToGet : 0)
                    .toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT)
                  : value && weapon ? weapon.bakeToGet.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'
              }</UpgradedValue>
            </PropsValue>
          </WeaponPorps>
          <WeaponPorps>
            <Text>Battle power increase:</Text>
            <PropsValue>
              <Text minWidth={60} maxWidth={100}>{weapon ? weapon.stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</Text>
              <IconArrow src="/images/decoration/upgrade-arrow.svg" alt="icon-arrow" />
              <UpgradedValue minWidth={60} maxWidth={100}>{ value ? stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : '?'}</UpgradedValue>
            </PropsValue>
          </WeaponPorps>
        </WeaponDetail>
        <InputSwap
          options={TOKENSLIST}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          marginTop={56}
          marginBottom={42}
          hideMin={true}
          onMax={onMax}
        />
        <RowCenter>
          {
            !account ? (
              <ButtonPrimary
                onClick={toggleWalletModal}
                width={'240px'}
                height={48}
                padding={'0px'}
              >
                Connect Wallet
              </ButtonPrimary>
            ) : !allowance.toNumber() ? (
              value && +value > 0 ? (
                <ButtonPrimary
                  onClick={handleApprove}
                  disabled={requestedApproval}
                  width={'240px'}
                  height={48}
                  padding={'0px'}
                >
                  {
                    requestedApproval ? (
                      <InlinePending text="Approving" />
                    ) : 'Approve ' + baseAsset.symbol
                  }
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  disabled={true}
                  width={'240px'}
                  height={48}
                  padding={'0px'}
                >
                  Approve {baseAsset.symbol}
                </ButtonPrimary>
              )
            ) : (
              <ButtonPrimary
                onClick={handleUpgrade}
                disabled={
                  requestedUpgrade ||
                  !(value && +value > 0) ||
                  balance.lt(value) ||
                  stakingPower.gt(MAXSTAKINGPOWER)
                }
                width={'240px'}
                height={48}
                padding={'0px'}
              >
                {
                  requestedUpgrade ? (
                    <InlinePending text="Pending" />
                  ) : balance.lt(value)
                    ? 'Insufficient Balance'
                    : stakingPower.gt(MAXSTAKINGPOWER)
                     ? 'Max staking power 1M'
                     : 'Upgrade'
                }
              </ButtonPrimary>
            )
          }
        </RowCenter>
      </Box>
    </PageBody>
  )
}
