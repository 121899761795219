import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import BigNumber from 'bignumber.js'

// utils
import { PetType, CONTRACT_ADDRESSES, BIGNUMBER_FMT } from '../../constants/index'
import { TOKENSLIST, MINSTAKINGPOWER, MAXSTAKINGPOWER, RATIO, DECOMPOSERATIO } from '../../constants/weapon'
import { getLevel } from '../../utils/lib/utils'

// components
import { PageBody, PageTitle, PageDes } from '../../components/views'
import SelectWeapon, { WeaponProps } from './components/SelectWeapon'
import InputSwap, { OptionProps } from '../../components/views/InputSwap'
import WeaponCard from '../../components/views/WeaponCard'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, AutoRow } from '../../components/Row'
import Loader from '../../components/Loader'

// hooks
import { useActiveWeb3React } from '../../hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useTokenContract } from '../../hooks/useContract'
import useAllowance from '../../hooks/useAllowance'
import useApprove from '../../hooks/useApprove'
import useRate from '../../hooks/useRate'
import useWeaponSynthesis from '../../hooks/weapons/useWeaponSynthesis'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'

const Box = styled.div`
  background: ${({ theme }) => theme.bg3};
  border-radius: 40px;
  width: 774px;
  padding: 40px 126px 48px;
  margin: 32px 0 110px;
  box-shadow: ${({ theme }) => theme.darkMode ? 'none' : '0px 2px 10px rgba(168, 121, 0, 0.08)' };

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 20px;
    width: 100%;
    margin: 24px auto;
    padding: 16px;
  `}
`

const BoxFooter = styled.div`
  display: flex;
  height: 174px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: auto;
    flex-direction: column;
    align-items: center;
  `}
`
const WeaponLevel = styled.div`
  display: flex;
  justify-content: center;
  justify: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) =>  theme.border1};
  color: ${({ theme }) => theme.text2};
  font-weight: 700;
  height: 40px;
`
const BoxFooterRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 32px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin: 32px 0 0;
  `}
`
const DetailList = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `}
`
const Value = styled(Text)`
  font-weight: 700
`

const weaponsList = [{
  name: 'Lion Heart Sword',
  icon: '/images/weapons/kitten/0.svg',
  weaponType: PetType.kitten,
  defaultChecked: true
}, {
  name: 'Chewy Bone',
  icon: '/images/weapons/puppy/0.svg',
  weaponType: PetType.puppy
}, {
  name: 'Excalibur',
  icon: '/images/weapons/bunny/0.svg',
  weaponType: PetType.bunny
}]

export default function () {
  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const addTransaction = useTransactionAdder()

  // weapon
  const [weapon, setWeapon] = useState<WeaponProps>(weaponsList.filter(d => d.defaultChecked)[0])
  const onSelectWeapon = useCallback((e: any) => {
    setWeapon(weaponsList.filter(d => d.name === e.target.id)[0])
  }, [setWeapon])

  // Input Box
  const [baseAsset, setBaseAsset] = useState(TOKENSLIST[0])
  const updateBaseAsset = useCallback((value: OptionProps) => {
    setBaseAsset(value)
  }, [setBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.name)

  const [value, setValue] = useState<string | number>('')
  const updateValue = useCallback((value) => {
    setValue(balance.lt(value) ? balance.toFixed(0, BigNumber.ROUND_FLOOR) : value ? new BigNumber(value).toFixed(0, BigNumber.ROUND_FLOOR) : '')
  }, [setValue, balance])

  // Rate
  const [rate, setRate] = useState(new BigNumber(1))
  const tokenRate = useRate({
    baseAsset: baseAsset.name,
    quoteAsset: 'pet',
    exclude: ['weapon']
  })
  useEffect(() => {
    setRate(tokenRate.gt(0) ? tokenRate : new BigNumber(1))
  }, [setRate, tokenRate])

  const onMin = useCallback(() => {
    const min = new BigNumber(MINSTAKINGPOWER).times(RATIO).div(rate).toFixed(0, BigNumber.ROUND_CEIL)
    setValue(min)
  }, [rate, setValue])
  const onMax = useCallback(() => {
    const max = new BigNumber(MAXSTAKINGPOWER).times(RATIO).div(rate).integerValue(BigNumber.ROUND_CEIL)
    setValue(BigNumber.min(max, BigNumber.max(balance, 0)).toFixed(0, BigNumber.ROUND_FLOOR))
  }, [setValue, rate, balance])

  // weapon level
  const [weaponLevel, setWeaponLevel] = useState(1)
  const [stakingPower, setStakingPower] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    const power = new BigNumber(value).times(rate).div(RATIO).integerValue(BigNumber.ROUND_FLOOR)
    const level = getLevel(new BigNumber(power).toNumber())
    setWeaponLevel(level)
    setStakingPower(value ? power : new BigNumber(0))
  }, [value, rate, setWeaponLevel, setStakingPower])

  // Allowance
  const [requestedApproval, setRequestedApproval] = useState(false)
  const erc20 = useTokenContract(chainId ? CONTRACT_ADDRESSES[baseAsset.name][chainId] : undefined)
  const allowance = useAllowance({
    erc20,
    masterContractAddress: chainId ? CONTRACT_ADDRESSES['weaponNftMaster'][chainId] : undefined,
    requested: requestedApproval
  })
  const { onApprove } = useApprove(erc20, chainId ? CONTRACT_ADDRESSES['weaponNftMaster'][chainId] : undefined)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      // user rejected tx or didn't go through
      if (!txHash) {
        setRequestedApproval(false)
      } else {
        setTimeout(() => {
          setRequestedApproval(false)
        }, 30000)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])
  useEffect(() => {
    setRequestedApproval(false)
  }, [baseAsset])

  // Synthesis weapon
  const [synthesisHash, setSynthesisHash] = useState('')
  const synthesisPending = useIsTransactionPending(synthesisHash)
  const [requestedSynthesis, setRequestedSynthesis] = useState(false)
  // reset request
  useEffect(() => {
    if (synthesisHash && !synthesisPending) {
      setRequestedSynthesis(false)
      setSynthesisHash('')
    }
  }, [synthesisHash, synthesisPending])
  // Order
  const { synthesis } = useWeaponSynthesis()
  const handleSynthesis = useCallback(async () => {
    try {
      setRequestedSynthesis(true)
      const response = await synthesis(
        baseAsset.name,
        weapon.weaponType,
        new BigNumber(value).times(new BigNumber(10).pow(10)).integerValue(BigNumber.ROUND_FLOOR).toString()
      )
      if (response) {
        addTransaction(response, {
          summary: 'Forge weapons'
        })
        setSynthesisHash(response ? response.hash : '')
      } else {
        setRequestedSynthesis(false)
      }
    } catch (e) {
      console.log('handleSynthesis', e)
      setRequestedSynthesis(false)
    }
  }, [baseAsset, weapon, value, synthesis, addTransaction])

  return (
    <PageBody>
      <PageTitle>Forge a Weapon</PageTitle>
      <PageDes>Forge your weapons here!</PageDes>
      <Box>
        <SelectWeapon
          list={weaponsList}
          onSelect={onSelectWeapon}
        />
        <InputSwap
          options={TOKENSLIST}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          marginTop={50}
          marginBottom={40}
          onMin={onMin}
          onMax={onMax}
        />
        <BoxFooter>
          <WeaponCard
            weaponType={weapon.weaponType}
            level={weaponLevel}
          >
            <WeaponLevel>
              Lv {weaponLevel}
            </WeaponLevel>
          </WeaponCard>
          <BoxFooterRight>
            <DetailList>
              <RowBetween padding={'0 0 8px 0'}>
                <Text>Staking Power:</Text>
                <Value>{value ? stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Value>
              </RowBetween>
              <RowBetween padding={'0 0 8px 0'}>
                <Text>$PET left after melting the weapon:</Text>
                <Value>{value && baseAsset.name === 'pet' ? new BigNumber(value).times(DECOMPOSERATIO).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Value>
              </RowBetween>
              <RowBetween padding={'0 0 8px 0'}>
                <Text>$BAKE left after melting the weapon:</Text>
                <Value>{value && baseAsset.name === 'bakery' ? new BigNumber(value).times(DECOMPOSERATIO).toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Value>
              </RowBetween>
              <RowBetween padding={'0 0 8px 0'}>
                <Text>Battle power increase:</Text>
                <Value>{value ? stakingPower.toFormat(0, BigNumber.ROUND_FLOOR, BIGNUMBER_FMT) : 0}</Value>
              </RowBetween>
            </DetailList>
            {
              false && (!account ? (
                <ButtonPrimary
                  onClick={toggleWalletModal}
                  height={48}
                  padding={'0px'}
                >
                  Connect Wallet
                </ButtonPrimary>
              ) : (
                !allowance.toNumber() ? (
                  value && +value > 0 ? (
                    <ButtonPrimary
                      onClick={handleApprove}
                      disabled={requestedApproval}
                      height={48}
                      padding={'0px'}
                    >
                      {
                        requestedApproval ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : 'Approve ' + baseAsset.symbol
                      }
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      disabled={true}
                      height={48}
                      padding={'0px'}
                    >
                      Approve {baseAsset.symbol}
                    </ButtonPrimary>
                  )
                ) : (
                  <ButtonPrimary
                    onClick={handleSynthesis}
                    disabled={
                      requestedSynthesis ||
                      !(value && +value > 0) ||
                      balance.lt(value) ||
                      stakingPower.lt(MINSTAKINGPOWER) ||
                      stakingPower.gt(MAXSTAKINGPOWER)
                    }
                    height={48}
                    padding={'0px'}
                  >
                    {
                      requestedSynthesis ? (
                        <AutoRow gap="6px" justify="center">
                          Pending <Loader stroke="white" />
                        </AutoRow>
                      ) : balance.lt(value)
                        ? 'Insufficient Balance'
                        : stakingPower.lt(MINSTAKINGPOWER)
                          ? 'Min staking power 500'
                          : stakingPower.gt(MAXSTAKINGPOWER)
                            ? 'Max staking power 1M'
                            : 'Buy'
                    }
                  </ButtonPrimary>
                )
              )
            )}
          </BoxFooterRight>
        </BoxFooter>
      </Box>
    </PageBody>
  )
}
