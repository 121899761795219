import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

import { unstakeWeaponNFT } from '../../utils/lib/utils'

// hooks
import { useWeaponMasterContract } from '../useContract'

const useUnstakeWeaponNFT = (tokenId: string) => {
  const weaponMaster = useWeaponMasterContract()

  const handleUnstake = useCallback(async () => {
    try {
      if (weaponMaster && tokenId) {
        const txHash = await unstakeWeaponNFT(
          weaponMaster,
          BigNumber.from(tokenId)
        )
        return txHash
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  },
  [tokenId, weaponMaster])

  return { onUnstakeWeaponNFT: handleUnstake }
}

export default useUnstakeWeaponNFT
