import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'rebass'
import { animated, useSprings } from 'react-spring'
import { Button as RebassButton } from 'rebass/styled-components'

// utils
import { randomColor } from '../../../utils/func'
import { MyPetProps } from '../../../constants/pets'

// components
import { RowCenter, RowBetween } from '../../../components/Row'
import { ColumnCenter } from '../../../components/Column'
import { Dots } from '../../../components/views'

// hooks
import { ChallengerProps } from '../../../hooks/arena'

export type bossVsPetProps = [ChallengerProps , MyPetProps] | []

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 27px;
  width: 100%;
  height: 490px;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`
const Top = styled(RowCenter)`
  position: relative;
  background: ${({ theme }) => theme.bg4};
  width: 100%;
  height: 100px;
  & > div {
    position: relative;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.text3};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`
const BgImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`
const Bottom = styled.div`
  position: relative;
  flex: 1;
`
const AnimatedItem = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

interface CardProps {
  width?: number
  height?: number
  transform?: string
  transformOrigin?: string
  succeed?: boolean
  failure?: boolean
  themeColor?: string
}
const Card = styled.div<CardProps>`
  position: relative;
  background: ${({ theme }) => theme.bg3};
  border: 1px solid ${({ theme, failure, themeColor }) => themeColor ? themeColor : failure ? theme.border6 : theme.border2};
  border-radius: 25px;
  width: ${({ width }) => width ? width : 184}px;
  height: ${({ height }) => height ? height : 230}px;
  transform: ${({ transform }) => transform ? transform : 'initial'};
  transform-origin: ${({ transformOrigin }) => transformOrigin ? transformOrigin : 'initial'};
`
interface CardDash extends CardProps {
  background: string
}
const CardDash = styled(ColumnCenter)<CardDash>`
  position: absolute;
  top: 9px;
  left: 9px;
  background: ${({ theme, failure, background }) => failure ? theme.bg9 : background};
  border: 1px dashed ${({ theme, failure, themeColor }) => themeColor ? themeColor : failure ? theme.border6 : theme.border2};
  border-radius: 20px;
  width: ${({ width }) => (width ? width : 184) - 20}px;
  height: ${({ height }) => (height ? height : 230) - 20}px;
`
const PetBox = styled(RowCenter)<{width?: number}>`
  flex: 1;
  background-image: url(/images/decoration/ellipse_shadow.svg);
  background-repeat: no-repeat;
  background-position: bottom 5px center;
  background-size: ${({ width }) => width ? 73 : 86}px;
  width: 100%;
  padding-top: ${({ width }) => width ? '0px' : '12px'};
`
const PetImg = styled.img<{height?: number}>`
  width: auto;
  height: ${({ height }) => height ? 126 : 148}px;
`
const PetName = styled.div<{width?: number, succeed?: boolean, failure?: boolean, themeColor?: string}>`
  color: ${({ theme, failure, themeColor }) => themeColor ? themeColor :failure ? theme.text10 : theme.text2};
  font-weight: 700;
  padding: ${({ width }) => width ? '6px 0' : '12px 0'};
  opacity: ${({ succeed }) => succeed ? 0 : 1};
  text-align: center;
  line-height: 1.0;
`
const CrownImg = styled.img`
  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 1;
`
const VecteryPet = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50px);
  z-index: 1;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`
const RibbonImg = styled.img`
  width: 260px;
  heigth: auto;
`
interface PetCardProps extends CardProps {
  petUrl: string
  name?: string
}
function PetCard ({
  petUrl,
  width,
  height,
  transform,
  transformOrigin,
  succeed,
  failure,
  themeColor,
  name
}: PetCardProps) {
  const [cardBg] = useState(randomColor())

  return (
    <Card
      width={width}
      height={height}
      transform={transform}
      transformOrigin={transformOrigin}
      failure={failure}
      themeColor={themeColor}
    >
      {succeed && (
        <>
          <CrownImg src="/images/zootopia/crown.svg" alt="crown" />
          <VecteryPet>
            <RibbonImg src="/images/zootopia/ribbon.svg" alt="ribbon"/>
            <svg width="260" height="86" viewBox="0 0 260 86">
              <path id="zPath" fill="none" d="M43 54.3787C63.5 27.8787 194.5 21.3787 217 54.3787" stroke="none"/>
              <text style={{fontSize: 26, fontWeight: 900, fill: '#ffffff', stroke: '#B96F00', strokeWidth: '1px', textAnchor: 'middle'}}>
                <textPath href="#zPath" startOffset="50%">
                  {name}
                </textPath>
              </text>
            </svg>
          </VecteryPet>
        </>
      )}
      <CardDash
        background={cardBg}
        width={width}
        height={height}
        failure={failure}
        themeColor={themeColor}
      >
        <PetBox width={width}>
          <PetImg src={petUrl} height={height} alt="pet"/>
        </PetBox>
        <PetName width={width} succeed={succeed} failure={failure} themeColor={themeColor}>{name}</PetName>
      </CardDash>
    </Card>
  )
}

const FightingWrapper = styled(RowBetween)`
  align-items: flex-start;
  position: relative;
  background: bottom/cover no-repeat url(/images/zootopia/arena.svg);
  height: 100%;
  padding: 60px 45px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 60px 20px 0;
  `}
`
const VsImg = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`
const FightingText = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  color: ${({ theme }) => theme.text9};
  font-weight: bold;
  width: 100%;
  text-align: center;
`

function Fighting ({
  bossVsPet
}: {
  bossVsPet: bossVsPetProps
}) {
  return (
    <FightingWrapper>
      <VsImg src="/images/decoration/vs.svg" alt="VS"/>
      <PetCard
        petUrl={`${bossVsPet[0]?.pet?.petUrl}`}
        width={154}
        height={192}
        transform="rotate(-10deg)"
        transformOrigin="top right"
        themeColor="#AFC71C"
        name={`${bossVsPet[0]?.pet?.name}`}
      />
      <PetCard
        petUrl={bossVsPet[1] ? bossVsPet[1].petUrl : ''}
        width={154}
        height={192}
        transform="rotate(10deg)"
        transformOrigin="top left"
        name={`#${bossVsPet[1]?.id} ${bossVsPet[1]?.name}`}
      />
      <FightingText>Fighting <Dots /></FightingText>
    </FightingWrapper>
  )
}

const VictoryWrapper = styled(ColumnCenter)`
  background: bottom/contain no-repeat url(/images/zootopia/arena_vectery.svg);
  height: 100%;
`
const VictoryText = styled(Text)`
  color: ${({ theme }) => theme.text2};
  font-size: 18px;
  font-weight: 700;
  padding: 24px 0;
`
const Button = styled(RebassButton)<{
  marginTop?: number
  altDisabledStyle?: boolean
}>`
  position: relative;
  background: ${({ theme }) => theme.bg4};
  border-radius: 10px;
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  font-weight: 700;
  width: 126px;
  height: 32px;
  margin-top: ${({ marginTop }) => marginTop ? marginTop : 30}px;
  padding: 0;
  z-index: 1;
  outline: none;

  &:disabled {
    background-color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.btnBg1 : theme.btnBg1)};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.btnText2 : theme.btnText1)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '1' : '1')};
  }
`

function Victory ({
  bossVsPet,
  onDismiss
}: {
  bossVsPet: bossVsPetProps
  onDismiss: () => void
}) {

  return (
    <VictoryWrapper>
      <VictoryText>Victory</VictoryText>
      <PetCard
        petUrl={bossVsPet[1] ? bossVsPet[1].petUrl : ''}
        succeed={true}
        name={`#${bossVsPet[1]?.id} ${bossVsPet[1]?.name}`}
      />
      <Button
        onClick={onDismiss}
      >
        Close
      </Button>
    </VictoryWrapper>
  )
}

const FailureWrapper = styled(ColumnCenter)`
  background: bottom/contain no-repeat url(/images/zootopia/arena_failure.svg);
  color: ${({ theme }) => theme.text1};
  height: 100%;
`
const FailureTitle = styled(Text)`
  color: ${({ theme }) => theme.text4};
  font-size: 18px;
  font-weight: 700;
`
function Failure ({
  bossVsPet,
  tryAgain,
  onDismiss
}: {
  bossVsPet: bossVsPetProps
  tryAgain: () => void
  onDismiss: () => void
}) {
  return (
    <FailureWrapper>
      <FailureTitle marginTop={'24px'}>Challenge Failed!</FailureTitle>
      <Text fontSize={14} marginBottom={'12px'}>Good luck next time!</Text>
      <PetCard
        petUrl={bossVsPet[1] ? bossVsPet[1].petUrl : ''}
        failure={true}
        name={`#${bossVsPet[1]?.id} ${bossVsPet[1]?.name}`}
      />
      <Button
        marginTop={24}
        onClick={tryAgain}
      >Try again</Button>
    </FailureWrapper>
  )
}

const defaultX: {[key: number]: any} = {
  0: [{x: -100}, {x: 0}, {x: 100}],
  1: [{x: -200}, {x: -100}, {x: 0}],
  2: [{x: 0}, {x: 100}, {x: 200}]
}
interface BattleProps {
  battleStatus: number
  bossVsPet: bossVsPetProps
  onDismiss?: () => void
  requestedBattle: boolean
  tryAgain: () => void
}
export default function Battle ({
  battleStatus,
  bossVsPet,
  onDismiss = () => {},
  requestedBattle,
  tryAgain
}: BattleProps) {
  const [items, setItems] = useState(defaultX[battleStatus])
  const springs = useSprings(items.length, items.map((item: any) => ({ transform: `translate3d(${item.x}%,0,0)` })))

  const toggleView = useCallback((i: number) => {
    setItems(defaultX[i])
  }, [setItems])

  useEffect(() => {
    toggleView(battleStatus)
  }, [battleStatus, toggleView])

  const onClose = useCallback(() => {
    if (requestedBattle) return
    onDismiss()
  }, [onDismiss, requestedBattle])

  return (
    <Wrapper>
      <Top>
        <BgImg src="/images/decoration/green_box.svg" alt=""/>
        <div>
          <div>
            <Text>#{bossVsPet[0]?.pet?.id}</Text>
            <Text>{bossVsPet[0]?.pet?.name}</Text>
          </div>
        </div>
        <div>
          <div>
            <Text>#{bossVsPet[1]?.id}</Text>
            <Text>{bossVsPet[1]?.name}</Text>
          </div>
        </div>
      </Top>
      <Bottom>
        {
          springs.map((props, i) => (
            <AnimatedItem key={i} style={props}>
              {
                i === 0 ? (
                  <Failure
                    bossVsPet={bossVsPet}
                    tryAgain={tryAgain}
                    onDismiss={onClose}
                  />
                ) : i === 1 ? (
                  <Fighting
                    bossVsPet={bossVsPet}
                  />
                ) : (
                  <Victory
                    bossVsPet={bossVsPet}
                    onDismiss={onClose}
                  />
                )
              }
            </AnimatedItem>
          ))
        }
      </Bottom>
    </Wrapper>
  )
}
